import { request } from "@/functions";

export default class IndividualsApi {
  getIndividuals(firstName, lastName, address, city, zip, match, successCallback) {
    const urlParams = new URLSearchParams(window.location.search);

    const getParam = function (s) {
      return urlParams.get(s) || "";
    };

    let url =
      "/individuals?firstName=" +
      (firstName || "") +
      "&lastName=" +
      (lastName || "") +
      "&address=" +
      (address || "") +
      "&city=" +
      (city || "") +
      "&zip=" +
      (zip || "") +
      "&match=" +
      (match || "all");

    request("get", url, null, successCallback);
  }
  getIndividualAssignments(id, callback) {
    request("get", "/individuals/" + id + "/assignments", null, callback);
  }
  getIndividualAttendance(id, callback) {
    request("get", "/individuals/" + id + "/attendance", null, callback);
  }
  getIndividual(id, callback) {
    request("get", "/individuals/" + id, null, callback);
  }

  updateIndividual(individual, successCallback) {
    request("PUT", "/individuals/" + individual.id, individual, successCallback);
  }

  deleteIndividual(id, successCallback) {
    request("DELETE", "/individuals/" + id, null, successCallback);
  }

  addIndividual(individual, successCallback) {
    request("POST", "/individuals/", individual, successCallback);
  }

  addIndividualNote(note, successCallback) {
    request("POST", "/individuals/" + note.individualId + "/notes", note, successCallback);
  }

  getIndividualNotes(id, successCallback) {
    request("GET", "/individuals/" + id + "/notes", null, successCallback);
  }

  deleteNote(individualId, noteId, successCallback) {
    request("DELETE", "/individuals/" + individualId + "/notes/" + noteId, null, successCallback);
  }
}
