<template>
  <span>
    <ProgressSpinner v-if="!pendingServices" />
    <DataTable v-if="pendingServices" :value="pendingServices" emptyMessage="No Services Found" style="width: 75%">
      <template #empty> No Services Found </template>
      <template #header>
        <div class="p-clearfix" style="line-height: 1.87em">Record Service Attendance</div>
      </template>

      <Column :sortable="true" field="service.name" header="Service Name" headerStyle="text-align: left" />
      <Column :sortable="false" header="Date" headerStyle="text-align: left">
        <template #body="slotProps">
          {{ fixDate(slotProps.data.calendar.eventDate) }}
        </template>
      </Column>
      <Column header="Actions" headerStyle="width: 8em; text-align: left" style="text-align: center">
        <template #body="slotProps">
          <Button
            v-tooltip.top="'Add Attendance'"
            class="p-button-rounded p-button-text"
            icon="fas fa-plus"
            @click="addAttendance(slotProps.data)"
          />
          <Button
            v-tooltip.top="'Mark Complete'"
            class="p-button-rounded p-button-text"
            icon="fas fa-check"
            @click="clickComplete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <service-attendance-dialog
      v-model="service"
      :action="formAction"
      :display="displayAttendanceDialog"
      @add-attendance="clickAddAttendance"
      @cancel-attendance="clickCancelAttendance"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />

    <confirmation-dialog
      v-bind:display="displayConfirmation"
      v-bind:message="confirmationMessage"
      v-bind:type="'Mark Attendance Complete'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmComplete"
    />
  </span>
</template>

<script>
import AttendanceApi from "@/apis/AttendanceApi";
import { AttendanceInputModel } from "@/models/AttendanceInputModel";
import { formatDate } from "@/functions";

export default {
  data() {
    return {
      pendingServices: null,
      dialogHeader: null,
      displayProgressDialog: false,
      attendanceRecord: null,
      displayAttendanceDialog: false,
      service: [],
      displayConfirmation: false,
      confirmationMessage: null,
      serviceToComplete: null,
      formAction: null,
    };
  },
  created() {
    this.attendanceApi = new AttendanceApi();
  },
  mounted() {
    this.getPendingServices();
  },

  methods: {
    sendComplete(service) {
      this.dialogHeader = "Marking Service Complete";
      this.displayProgressDialog = true;
      this.attendanceRecord = new AttendanceInputModel();
      this.attendanceRecord.attendanceId = service.id;
      this.attendanceRecord.completed = true;
      service.completed = true;
      this.attendanceApi.updateAttendance(this.attendanceRecord, () => {
        this.getPendingServices();
        this.dialogHeader = null;
        this.displayProgressDialog = false;
      });
    },
    addAttendance(data) {
      this.service = { ...data };
      this.service.totalAttendance = null;
      this.formAction = "add";
      this.displayAttendanceDialog = true;
    },
    clickAddAttendance(service) {
      this.dialogHeader = "Adding Attendance";
      this.displayProgressDialog = true;
      this.attendanceRecord = new AttendanceInputModel();
      this.attendanceRecord.attendanceId = service.id;
      this.attendanceRecord.totalAttendance = service.totalAttendance;
      this.attendanceRecord.completed = true;
      service.completed = true;
      this.attendanceApi.updateAttendance(this.attendanceRecord, () => {
        this.getPendingServices();
        this.dialogHeader = null;
        this.displayProgressDialog = false;
        this.displayAttendanceDialog = false;
      });
    },
    clickComplete(service) {
      this.serviceToComplete = service;
      this.confirmationMessage = "Are you sure you want to mark this service complete without adding attendance numbers?";
      this.displayConfirmation = true;
    },
    clickCancelAttendance() {
      this.displayAttendanceDialog = false;
    },
    fixDate(date) {
      return formatDate(date);
    },
    getPendingServices() {
      this.attendanceApi.getPendingServices((data) => {
        this.pendingServices = data.sort((a, b) => (a.calendar.eventDate > b.calendar.eventDate ? 1 : -1));
      });
    },
    confirmCancel() {
      this.displayConfirmation = false;
      this.serviceToComplete = null;
    },
    confirmComplete() {
      this.sendComplete(this.serviceToComplete);
      this.displayConfirmation = false;
      this.confirmationMessage = null;
    },
  },
};
</script>

<style scoped></style>
