<template>
  <span>
    <ProgressSpinner v-if="!household.id" />
    <Card v-if="household.id">
      <template #title>
        <div class="grid">
          <div class="col-7" style="text-align: left">{{ household.lastName }} Household</div>
          <div class="col-5" style="text-align: right">
            <Button v-tooltip.top="'Edit ' + household.lastName + ' Household'" icon="fas fa-pencil" @click="clickEditHousehold" />

            &nbsp;&nbsp;

            <router-view></router-view>

            <Button v-tooltip.top="'Delete ' + household.lastName + ' Household'" icon="fas fa-trash" @click="clickDeleteHousehold" />
          </div>
        </div>
      </template>
      <template #content>
        <div v-if="household" class="grid">
          <div class="col-6" style="padding: 0.5em; position: relative">
            <label style="font-weight: bold">Address</label>
            <div class="grid" style="padding: 2%; display: flex">
              <div class="col-12">
                <ViewAddress :value="household" />
              </div>

              <div class="col-12">
                <GMapMap :key="household.longitude"
                         :center="{lat:Number(household.latitude), lng:Number(household.longitude)}"
                         :zoom="16"
                         map-type-id="terrain"
                         style="width: 100%; minHeight: 320px;">
                  <GMapMarker v-for="(m, index) in markers"
                          :key="index"
                          :position="m.position"
                          :clickable="true"
                          :draggable="true"
                          @click="center=m.position">

                  </GMapMarker>
                </GMapMap>
              </div>
            </div>
          </div>
          <div class="col-6" style="justify-content: space-between; flex-direction: column; display: flex" style1="font-weight: bold; padding: 2%; display: flex">
            <Card>
              <template #title>
                <div class="grid">
                  <div class="col-7" style="text-align: left">Household Members</div>
                  <div class="col-5" style="text-align: right">
                    <Button
                      v-tooltip.top="'Add Member to ' + household.lastName + ' Household'"
                      class="p-button-rounded p-button-text"
                      icon="fas fa-plus"
                      @click="addMemberClicked()"
                    />
                  </div>
                </div>
              </template>
              <template #content>
                <HouseholdMemberList :value="household.members" @click-member="clickHouseholdMember" />
              </template>
            </Card>
            <div style="justify-content: flex; display: flex"></div>
          </div>
        </div>
      </template>
    </Card>

    <ProgressDialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />

    <ConfirmationDialog
      v-bind:display="displayDelConfirmation"
      v-bind:message="deleteMessage"
      v-bind:type="'Delete Household'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmDelete"
    />
  </span>
</template>

<script>
import HouseholdApi from "@/apis/HouseholdApi";
import IndividualsApi from "@/apis/IndividualsApi";
import {HouseholdModel} from "@/models/HouseholdModel";

export default {
  data() {
    return {
      deleteMessage: null,
      dialogHeader: null,
      displayDelConfirmation: false,
      displayProgressDialog: false,
      filters: {},
      gmap: null,
      household: new HouseholdModel(),
      householdApi: null,
      householdId: null,
      householdToDelete: new HouseholdModel(),
      individualsApi: null,
      mapOptions: null,
      markers: null,
    };
  },
  computed: {
    // google: gmapApi,
  },
  created() {
    this.householdId = this.$route.query.id;
    this.householdApi = new HouseholdApi();
    this.individualsApi = new IndividualsApi();
  },
  mounted() {
    this.householdApi.getHousehold(this.householdId, (household) => this.setHousehold(household));
  },
  methods: {
    clickEditHousehold() {
      this.$router.push("/household/edit?id=" + this.household.id);
    },
    clickHouseholdMember(individual) {
      this.$router.push("/individual?id=" + individual.id);
    },
    clickDeleteHousehold() {
      this.householdToDelete = this.household;
      this.deleteMessage = "Are you sure you want to delete the " + this.household.lastName + " household?";
      this.displayDelConfirmation = true;
    },
    confirmCancel() {
      this.displayDelConfirmation = false;
    },
    confirmDelete() {
      this.displayDelConfirmation = false;
      this.deleteHousehold(this.householdToDelete);
      this.householdToDelete = [];
    },
    deleteHousehold(household) {
      this.dialogHeader = "Deleting Household";
      this.displayProgressDialog = true;
      this.householdApi.deleteHousehold(household.id, () => {
        this.displayProgressDialog = false;
        this.dialogHeader = null;
        this.$router.push("/households/");
      });
    },
    clickCancelIndividual() {
      this.displayIndividualDialog = false;
    },
    addMemberClicked: function () {
      this.$router.push("/individual/add?householdId=" + this.household.id);
    },
    setHousehold: function (household) {
      this.household = household;
      if (this.household.latitude && this.household.longitude) {
        this.markers = [
          {
            position: {
              lat: Number(this.household.latitude),
              lng: Number(this.household.longitude),
            },
            title:
              this.household.lastName +
              " Household\n" +
              this.household.address1 +
              "\n" +
              this.household.city +
              ", " +
              this.household.state,
          },
        ];
      }
    },
  },
};
</script>
