import "@/assets/css/main.css";
import "@/assets/css/all.min.css";

import { createApp } from "vue";
import Tooltip from "primevue/tooltip";
import ToastService from "primevue/toastservice";
import PrimeVue from "primevue/config";
import VueLogger from "vuejs3-logger";

import App from "@/App";
import router from "@/routes";
import { store } from "@/store";

import ConfirmationService from "primevue/confirmationservice";
import { request } from "@/functions";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProgressSpinner from "primevue/progressspinner";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Divider from "primevue/divider";
import ProgressDialog from "@/components/general/ProgressDialog";
import ConfirmationDialog from "@/components/general/ConfirmationDialog";
import AddToGroupDialog from "@/components/general/AddToGroupDialog";
import Card from "primevue/card";

import IndividualNameDisplay from "@/components/general/IndividualNameDisplay";
import IndividualPhoneDisplay from "@/components/general/IndividualPhoneDisplay";
import HouseholdMemberList from "@/components/general/HouseholdMemberList";
import AddressList from "@/components/general/AddressList";
import AssignmentsList from "@/components/general/AssignmentsList";
import IndividualAdditionalInfoDisplay from "@/components/general/IndividualAdditionalInfoDisplay";
import IndividualNotes from "@/components/general/IndividualNotes";

import PhoneNumberList from "@/components/general/PhoneNumberList";
import SocialMediaList from "@/components/general/SocialMediaList";
import ActivityAttendanceDialog from "@/components/activities/ActivityAttendanceDialog";
import BillingInfoForm from "@/components/signup/BillingInfoForm";
import Calendar from "primevue/calendar";
import Chart from "primevue/chart";
import Checkbox from "primevue/checkbox";
import ChurchInfoForm from "@/components/signup/ChurchInfoForm";
import ConfirmDialog from "primevue/confirmdialog";
import CustomLabel from "@/components/general/CustomLabel";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Editor from "primevue/editor";
import InputMask from "primevue/inputmask";
import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
import PhoneNumber from "@/components/general/PhoneNumber";
import PickList from "primevue/picklist";
import RadioButton from "primevue/radiobutton";
import ServiceAttendanceDialog from "@/components/services/ServiceAttendanceDialog";
import DisplayMessageDialog from "@/components/groups/DisplayMessageDialog";
import TimeSelector from "@/components/general/TimeSelector";
import Toast from "primevue/toast";
import UserDialog from "@/components/users/UserDialog";
import ViewAddress from "@/components/general/ViewAddress";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faUser } from '@fortawesome/free-solid-svg-icons'
library.add(faUser)

const functions = {
  request: request
};


export const Vue = createApp(App)
  .directive("tooltip", Tooltip)
  .use(store)
  .use(functions)
  .use(PrimeVue, { ripple: true })
  .use(ToastService)
  .use(router)
  .use(ConfirmationService)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GMAP_API_KEY //'YOUR_API_KEY_COMES_HERE',
    }
  })
  .use(VueLogger, {
    isEnabled: true,
    // logLevel: Vue.config.productionTip ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: "|",
    showConsoleColors: true
  })
  .component("ActivityAttendanceDialog", ActivityAttendanceDialog)
  .component("BillingInfoForm", BillingInfoForm)
  .component("Button", Button)
  .component("Calendar", Calendar)
  .component("Card", Card)
  .component("Chart", Chart)
  .component("Checkbox", Checkbox)
  .component("ChurchInfoForm", ChurchInfoForm)
  .component("Column", Column)
  .component("ConfirmDialog", ConfirmDialog)
  .component("ConfirmationDialog", ConfirmationDialog)
  .component("CustomLabel", CustomLabel)
  .component("DataTable", DataTable)
  .component("Dialog", Dialog)
  .component("Divider", Divider)
  .component("Dropdown", Dropdown)
  .component("Editor", Editor)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component("HouseholdMemberList", HouseholdMemberList)
  .component("SocialMediaList", SocialMediaList)
  .component("PhoneNumberList", PhoneNumberList)
  .component("IndividualAdditionalInfoDisplay", IndividualAdditionalInfoDisplay)
  .component("IndividualNotes", IndividualNotes)
  .component("AssignmentsList", AssignmentsList)
  .component("AddressList", AddressList)
  .component("IndividualNameDisplay", IndividualNameDisplay)
  .component("IndividualPhoneDisplay", IndividualPhoneDisplay)
  .component("InputMask", InputMask)
  .component("InputText", InputText)
  .component("PanelMenu", PanelMenu)
  .component("Password", Password)
  .component("PhoneNumber", PhoneNumber)
  .component("PickList", PickList)
  .component("ProgressDialog", ProgressDialog)
  .component("AddToGroupDialog", AddToGroupDialog)
  .component("ProgressSpinner", ProgressSpinner)
  .component("RadioButton", RadioButton)
  .component("ServiceAttendanceDialog", ServiceAttendanceDialog)
  .component("DisplayMessageDialog", DisplayMessageDialog)
  .component("TimeSelector", TimeSelector)
  .component("Toast", Toast)
  .component("UserDialog", UserDialog)
  .component("ViewAddress", ViewAddress)
  .mount("#app");
