<template>
  <div>
    <ProgressSpinner v-if="!ministryInfo" />
    <span v-if="ministryInfo">
      <Card style="width: 35rem; margin-bottom: 2em">
        <template #title>
          <div v-if="!rosterId" v-once>Add New Roster</div>
          <div v-else v-once>Edit {{ this.roster.name }}</div>
        </template>
        <template #content>
          <div class="grid p-fluid">
            <div class="col-6" style="padding: 0.5em">
              <custom-label v-bind:label="'Ministry'" v-bind:required="true" />
              <Dropdown
                id="ministryId"
                v-model="ministryId"
                :contentStyle="{ overflow: 'visible' }"
                :options="ministryInfo"
                optionLabel="name"
                optionValue="id"
                placeholder="Select a Ministry"
                @change="ministryChanged"
              />
            </div>
            <div class="col-6" style="padding: 0.5em">
              <custom-label v-bind:label="'Activity'" v-bind:required="true" />
              <Dropdown
                id="activityId"
                v-model="activityId"
                :contentStyle="{ overflow: 'visible' }"
                :options="activityOptions"
                defaultValue="NEVER"
                optionLabel="name"
                optionValue="id"
                placeholder="Select a Ministry"
              />
            </div>

            <div class="col-12" style="padding: 0.5em">
              <custom-label v-bind:label="'Roster Name'" v-bind:required="true" />
              <InputText id="name" v-model="roster.name" type="text" />
            </div>

            <div class="col-12" style="padding: 0.5em">
              <custom-label v-bind:label="'Location'" v-bind:required="false" />
              <Dropdown
                id="location"
                v-model="roster.location.id"
                :options="locationOptions"
                optionLabel="name"
                optionValue="code"
              />
            </div>
          </div>
        </template>
        <template #footer>
          <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="clickCancel" />
          <Button autofocus icon="fas fa-check" label="Save" @click="clickSave(roster)" />
        </template>
      </Card>
      <Toast />

      <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
    </span>
  </div>
</template>

<script>
import RostersApi from "@/apis/RostersApi";
import MinistriesApi from "@/apis/MinistriesApi";
import {validate, validations} from "@/validation";
import {RosterModel} from "@/models/RosterModel";
import {recurrenceOptions} from "@/constants";
import LocationsApi from "@/apis/LocationsApi";
import {responseToOptions} from "@/functions";

export default {
  name: "ManageRoster",
  data() {
    return {
      roster: null,
      rosterId: null,
      activityId: null,
      ministryId: null,
      rostersApi: null,
      ministriesApi: null,
      dialogHeader: null,
      displayProgressDialog: false,
      editMode: true,
      recurs: recurrenceOptions,
      locationsApi: null,
      ministryInfo: null,
      activityOptions: null,
    };
  },
  created() {
    this.rostersApi = new RostersApi();
    this.locationsApi = new LocationsApi();
    this.ministriesApi = new MinistriesApi();
    this.locationsApi.getLocationsList((data) => {
      this.locations = data;
      this.locationOptions = responseToOptions(data);
    });
    this.rosterId = parseInt(this.$route.query.rosterId);
    if (!this.rosterId) {
      this.editMode = false;
      this.roster = new RosterModel();
    } else {
      this.loadRoster(this.rosterId);
    }
    this.ministriesApi.getFullMinistryInfo((data) => {
      this.ministryInfo = data;
      this.ministryId = parseInt(this.$route.query.ministryId);
      this.activityId = parseInt(this.$route.query.activityId);
      this.activityOptions = this.ministryInfo.find((n) => n.id == this.ministryId).children;
    });
  },
  mounted() {},
  // watch: {
  //   ministryId: function() {
  //     if (this.ministryId !== this.$route.params.ministryId) {
  //       this.activityId = null
  //     }
  //     if (this.ministryInfo) {
  //       this.activityOptions = this.ministryInfo.find(n => n.id == this.ministryId).children
  //     }
  //   }
  // },
  methods: {
    ministryChanged: function () {
      this.activityId = null;
      if (this.ministryInfo) {
        this.activityOptions = this.ministryInfo.find((n) => n.id == this.ministryId).children;
      }
    },
    loadRoster: function (rosterId) {
      this.rostersApi.getRoster(rosterId, (roster) => {
        this.roster = roster;
      });
    },

    clickCancel: function () {
      this.$router.push("/rosters?ministryId=" + this.ministryId + "&activityId=" + this.activityId);
    },
    clickSave: function (roster) {
      roster.ministryId = this.ministryId;
      roster.activityId = this.activityId;
      if (!this.editMode) {
        this.dialogHeader = "Adding Roster";
      } else {
        this.dialogHeader = "Updating Roster";
      }
      const errors = validate(roster, validations.roster);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.displayProgressDialog = true;

      if (!this.editMode) {
        this.rostersApi.addRoster(roster, (data) => {
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/rosters/view?activityId=" + this.activityId + "&ministryId=" + this.ministryId);
        });
      } else {
        roster.activityId = this.activityId;
        roster.ministryId = this.ministryId;
        this.rostersApi.updateRoster(roster, () => {
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/rosters/view?activityId=" + this.activityId + "&ministryId=" + this.ministryId);
        });
      }
    },
  },
};
</script>
