<template>
  <span>
    <ProgressSpinner v-if="!ministries" />

    <DataTable v-if="ministries" :value="ministries" style="width: 90%">
      <template #header>
        <!-- <div class="p-clearfix" style="line-height:1.87em"> -->
        <div class="p-fluid formgrid grid align-items-end vertical-container">
          <div class="field col-12 md:col-8">Ministries</div>
          <div class="field col-12 md:col-4">
            <Button icon="fas fa-plus" label="Add Ministry" @click="addNewMinistry" />
          </div>
        </div>
      </template>
      <template #empty> No Ministries found </template>
      <Column field="name" header="Ministry Name" headerStyle="textAlign: left" />
      <Column field="activities" header="Number of Activities" headerStyle="textAlign: left">
        <template #body="slotProps">
          <div v-if="slotProps.data.activities != null">
            {{ slotProps.data.activities.length }}
          </div>
        </template>
      </Column>
      <Column header="Actions" headerStyle="width: 25%; textAlign: left">
        <template #body="slotProps">
          <Button
            v-tooltip.top="'Edit ' + slotProps.data.name"
            class="p-button-rounded p-button-text"
            icon="fas fa-pencil"
            @click="onMinistrySelect(slotProps.data)"
          />
          <Button
            v-tooltip.top="'Delete ' + slotProps.data.name"
            class="p-button-rounded p-button-text"
            icon="fas fa-trash"
            @click="clickTrash(slotProps.data)"
          />
          <Button
            v-tooltip.top="'Add Activity to ' + slotProps.data.name"
            class="p-button-rounded p-button-text"
            icon="fas fa-plus"
            style="text-align: right"
            @click="addNewActivity(slotProps.data)"
          />
          <Button
            v-tooltip.top="'View Activities'"
            class="p-button-rounded p-button-text"
            icon="fas fa-list"
            @click="viewActivities(slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>

    <confirmation-dialog
      v-bind:display="displayDelConfirmation"
      v-bind:message="deleteMessage"
      v-bind:type="'Delete Ministry'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmDelete"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
  </span>
</template>

<script>
import ActivitiesApi from "@/apis/ActivitiesApi";
import MinistriesApi from "@/apis/MinistriesApi";
import { ActivityModel } from "@/models/ActivityModel";
import { MinistryModel } from "@/models/MinistryModel";

export default {
  data() {
    return {
      activitiesApi: null,
      activity: new ActivityModel(),
      activityAction: null,
      deleteMessage: null,
      dialogHeader: null,
      displayActivityDialog: false,
      displayDelConfirmation: false,
      displayProgressDialog: false,
      ministries: null,
      ministry: new MinistryModel(),
      ministryToDelete: [],
      ministriesApi: null,
    };
  },
  created() {
    this.ministriesApi = new MinistriesApi();
    this.activitiesApi = new ActivitiesApi();
  },
  mounted() {
    this.ministriesApi.getMinistriesList((data) => (this.ministries = data));
  },
  methods: {
    onMinistrySelect(data) {
      // this.$store.commit('selectMinistry', data.id);
      this.$router.push("/ministry/edit?ministryId=" + data.id);
    },
    addNewActivity(data) {
      this.$router.push("/activity/add?ministryId=" + data.id);
    },
    viewActivities(id) {
      this.$router.push("/activities?ministryId=" + id);
    },
    addNewMinistry() {
      this.$router.push("/ministry/add");
    },

    clickDeleteMinistry(ministry) {
      this.dialogHeader = "Deleting Ministry";
      this.displayProgressDialog = true;
      this.ministriesApi.deleteMinistry(ministry.id, () => {
        this.ministries = this.ministries.slice().filter((n) => n.id !== ministry.id);
        this.ministry = new MinistryModel();
        this.displayMinistryDialog = false;
        this.dialogHeader = null;
        this.displayProgressDialog = false;
      });
    },
    clickTrash(ministry) {
      this.ministryToDelete = ministry;
      this.deleteMessage = "Are you sure you want to delete " + ministry.name + "?";
      this.displayDelConfirmation = true;
    },
    confirmCancel() {
      this.displayDelConfirmation = false;
    },
    confirmDelete() {
      this.clickDeleteMinistry(this.ministryToDelete);
      this.displayDelConfirmation = false;
      this.ministryToDelete = [];
    },
  },
};
</script>
