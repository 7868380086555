import { request } from "@/functions";

export default class ServicesApi {
  getServicesList(successCallback) {
    request("get", "/services", null, successCallback);
  }

  getService(id, successCallback) {
    request("get", "/services/" + id, null, successCallback);
  }

  addService(service, successCallback) {
    request("POST", "/services/", service, successCallback);
  }

  updateService(service, successCallback) {
    request("PUT", "/services/" + service.id, service, successCallback);
  }

  deleteService(id, successCallback) {
    request("DELETE", "/services/" + id, null, successCallback);
  }

  getAttendanceData(successCallback) {
    request("GET", "/reports/services", null, successCallback);
  }

  //
  // getRoster(rosterId, successCallback) {
  //     request('get', 'rosters/' + rosterId + '/members', null, successCallback)
  // }
}
