<template>
  <div>
    <ProgressSpinner v-if="!ministry" />
    <span v-if="ministry">
      <Card style="width: 30rem; margin-bottom: 2em">
        <template #title>
          <div v-if="!ministryId" v-once>Add New Ministry</div>
          <div v-else v-once>Edit {{ this.ministry.name }}</div>
        </template>
        <template #content>
          <div class="grid p-fluid">
            <div class="col-12" style="padding: 0.5em">
              <custom-label v-bind:label="'Ministry Name'" v-bind:required="true" />
              <InputText id="name" v-model="ministry.name" type="text" @keyup.enter="clickSave(ministry)"/>
            </div>
          </div>
        </template>
        <template #footer>
          <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="clickCancel" />
          <Button autofocus icon="fas fa-check" label="Save" @click="clickSave(ministry)" />
        </template>
      </Card>
      <Toast />

      <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
    </span>
  </div>
</template>

<script>
import MinistriesApi from "@/apis/MinistriesApi";
import {validate, validations} from "@/validation";
import {MinistryModel} from "@/models/MinistryModel";

export default {
  name: "ManageMinistry",
  data() {
    return {
      ministry: null,
      ministryId: null,
      ministriesApi: null,
      dialogHeader: null,
      displayProgressDialog: false,
      editMode: true,
    };
  },
  created() {
    this.ministryId = this.$route.query.ministryId;
    this.ministriesApi = new MinistriesApi();
    if (!this.ministryId) {
      this.editMode = false;
      this.ministry = new MinistryModel();
    } else {
      this.loadMinistry(this.ministryId);
    }
  },
  methods: {
    loadMinistry: function (ministryId) {
      this.ministriesApi.getMinistry(ministryId, (ministry) => {
        this.ministry = ministry;
      });
    },

    clickCancel() {
      this.$router.push("/ministries/");
    },
    clickSave(ministry) {
      if (!this.editMode) {
        this.dialogHeader = "Adding Ministry";
      } else {
        this.dialogHeader = "Updating Ministry";
      }
      const errors = validate(ministry, validations.ministry);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.displayProgressDialog = true;

      if (!this.editMode) {
        this.ministriesApi.addMinistry(ministry, (data) => {
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          // this.$store.commit('selectMinistry', null);
          this.$router.push("/ministries");
        });
      } else {
        this.ministriesApi.updateMinistry(ministry, () => {
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/ministries/");
        });
      }
    },
  },
};
</script>
