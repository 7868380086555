class Validators {
  static required(value, label) {
    if (value === null || value === undefined || value.toString().trim() === "") {
      return label + " is required";
    }
  }
}

export const validations = {
  ministry: [
    {
      field: "name",
      label: "Ministry Name",
      validators: [Validators.required],
    },
  ],
  location: [
    {
      field: "name",
      label: "Location Name",
      validators: [Validators.required],
    },
  ],
  activity: [
    {
      field: "name",
      label: "Activity Name",
      validators: [Validators.required],
    },
    {
      field: "startDate",
      label: "Start Date",
      validators: [Validators.required],
    },
    {
      field: "ministryId",
      label: "Ministry",
      validators: [Validators.required],
    },
    {
      field: "recurrenceType",
      label: "Recurrence Type",
      validators: [Validators.required],
    },
    {
      field: "activityTime",
      label: "Activity Time",
      validators: [Validators.required],
    },
  ],
  household: [
    {
      field: "lastName",
      label: "Last Name",
      validators: [Validators.required],
    },
    {
      field: "address1",
      label: "Address 1",
      validators: [Validators.required],
    },
    { field: "city", label: "City", validators: [Validators.required] },
    { field: "state", label: "State", validators: [Validators.required] },
    { field: "zipcode", label: "Zip Code", validators: [Validators.required] },
  ],
  individual: [
    {
      field: "firstName",
      label: "First Name",
      validators: [Validators.required],
    },
    {
      field: "lastName",
      label: "Last Name",
      validators: [Validators.required],
    },
    {
      field: "householdId",
      label: "Household",
      validators: [Validators.required],
    },
    { field: "gender", label: "Gender", validators: [Validators.required] },
  ],
  roster: [
    { field: "name", label: "Roster Name", validators: [Validators.required] },
    // {field: "ministryId", label: "Ministry", validators: [Validators.required]},
    {
      field: "activityId",
      label: "Activity",
      validators: [Validators.required],
    },
  ],
  service: [
    { field: "name", label: "Service Name", validators: [Validators.required] },
    {
      field: "serviceTime",
      label: "Service Time",
      validators: [Validators.required],
    },
    {
      field: "recurrenceType",
      label: "Recurrence Type",
      validators: [Validators.required],
    },
    {
      field: "locationId",
      label: "Location",
      validators: [Validators.required],
    },
  ],
  serviceAttendance: [
    {
      field: "totalAttendance",
      label: "Number of Attendees",
      validators: [Validators.required],
    },
  ],
  group: [{ field: "name", label: "Group Name", validators: [Validators.required] }],
  groupEmailMessage: [
    { field: "groupId", label: "Group", validators: [Validators.required] },
    { field: "subject", label: "Subject", validators: [Validators.required] },
    { field: "body", label: "Body", validators: [Validators.required] },
  ],
  groupTextMessage: [
    { field: "groupId", label: "Group", validators: [Validators.required] },
    { field: "body", label: "Body", validators: [Validators.required] },
  ],
  rosterMessage: [
    { field: "subject", label: "Subject", validators: [Validators.required] },
    { field: "body", label: "Body", validators: [Validators.required] },
  ],
  testEmail: [
    { field: "testEmail", label: "Test Email Address", validators: [Validators.required] },
    { field: "subject", label: "Subject", validators: [Validators.required] },
    { field: "body", label: "Body", validators: [Validators.required] },
  ],
  testText: [
    { field: "testPhoneNumber", label: "Test Phone Number", validators: [Validators.required] },
    { field: "subject", label: "Subject", validators: [Validators.required] },
    { field: "body", label: "Body", validators: [Validators.required] },
  ],
  searchGroupMessages: [{ field: "groupId", label: "Group", validators: [Validators.required] }],
  searchActivityAttendance: [
    {
      field: "ministryId",
      label: "Ministry",
      validators: [Validators.required],
    },
    {
      field: "activityId",
      label: "Activity",
      validators: [Validators.required],
    },
    {
      field: "startDate",
      label: "Start Date",
      validators: [Validators.required],
    },
  ],
  searchServiceAttendance: [
    {
      field: "startDate",
      label: "Start Date",
      validators: [Validators.required],
    },
  ],
  addToGroupDialog: [
    {
      field: "id",
      label: "Group",
      validators: [Validators.required],
    },
  ],
  user: [
    {
      field: "firstName",
      label: "First Name",
      validators: [Validators.required],
    },
    {
      field: "lastName",
      label: "Last Name",
      validators: [Validators.required],
    },
    {
      field: "emailAddress",
      label: "Email",
      validators: [Validators.required],
    },
    // {field: "password", label: "Password", validators: [Validators.required]},
    { field: "role", label: "Role", validators: [Validators.required] },
  ],
  signup: [
    { field: "name", label: "Church Name", validators: [Validators.required] },
    { field: "address1", label: "Church Address 1", validators: [Validators.required] },
    { field: "city", label: "Church City", validators: [Validators.required] },
    { field: "state", label: "Church State", validators: [Validators.required] },
    { field: "zipcode", label: "Church ZIP", validators: [Validators.required] },
    { field: "subscriptionType", label: "Subscription Type", validators: [Validators.required] },
    { field: "adminUserFirstName", label: "Admin User First Name", validators: [Validators.required] },
    { field: "adminUserLastName", label: "Admin User Last Name", validators: [Validators.required] },
    { field: "adminUserEmail", label: "Admin User Email", validators: [Validators.required] },
    { field: "adminUserPassword", label: "Admin User Password", validators: [Validators.required] },
    { field: "creditCardNumber", label: "Credit Card Number", validators: [Validators.required] },
    { field: "cvv2", label: "Security Code (CVV2)", validators: [Validators.required] },
    { field: "expirationDate", label: "CC Expiration Date", validators: [Validators.required] },
    { field: "billingName", label: "Billing Name", validators: [Validators.required] },
    { field: "billingAddress1", label: "Billing Address 1", validators: [Validators.required] },
    { field: "billingCity", label: "Billing City", validators: [Validators.required] },
    { field: "billingState", label: "Billing State", validators: [Validators.required] },
    { field: "billingZipcode", label: "Billing ZIP", validators: [Validators.required] },
  ],
};

export function setMessages() {
  //messages = m
}

export function validate(data, rules) {
  let results = [];
  rules.forEach((rule) => {
    const value = data[rule.field];
    rule.validators.forEach((validator) => {
      const result = validator(value, rule.label);
      if (result !== undefined) {
        results.push(result);
      }
    });
  });
  if (results.length > 0) {
    let errors = [];
    results.forEach((result) => {
      errors.push({ severity: "error", content: result });
    });

    return errors;
  } else {
    return null;
  }
}
