<template>
  <span>
    <ProgressSpinner v-if="!ministryInfo" />

    <DataTable v-if="ministryInfo" ref="dt" :value="rosters" style="width: 90%">
      <template #empty> No Rosters Found </template>

      <template #header>
        <div v-if="ministryInfo" class="p-fluid formgrid grid align-content-center align-items-end vertical-container">
          <div class="field col-12 md:col-3">
            Rosters
            <p></p>
            <custom-label v-bind:label="'Ministry'" v-bind:required="false" />
            <Dropdown
              id="ministryId"
              v-model="ministryId"
              :contentStyle="{ overflow: 'visible' }"
              :options="ministryInfo"
              optionLabel="name"
              optionValue="id"
              placeholder="Select a Ministry"
              @change="ministryChanged"
            />
          </div>
          <div class="field col-12 md:col-4">
            <custom-label v-bind:label="'Activity'" v-bind:required="false" />
            <Dropdown
              id="activityId"
              v-model="activityId"
              :contentStyle="{ overflow: 'visible' }"
              :options="activityOptions"
              optionLabel="name"
              optionValue="id"
              placeholder="Select an Activity"
              @change="activityChanged"
            />
          </div>
          <div v-if="rosters" class="field col-3 md:col-3">
            <Button icon="fas fa-plus" label="Add Roster" @click="addNewRoster" />
          </div>
          <div v-if="activity && activity.supportsPromotion" class="field col-3 md:col-2">
            <Button icon="fas fa-arrow-circle-up" label="Promote" @click="promoteRosters" />
          </div>
        </div>
      </template>
      <Column field="name" header="Roster Name" headerStyle="text-align: left" />
      <Column header="Location" headerStyle="text-align: left">
        <template #body="slotProps">
          <div v-if="slotProps.data.location">
            {{ slotProps.data.location.name }}
          </div>
        </template>
      </Column>
      <Column header="Actions" headerStyle="width: 20%; text-align: left">
        <template #body="slotProps">
          <Button
            v-tooltip.top="'Edit Roster'"
            class="p-button-rounded p-button-text"
            icon="fas fa-pencil"
            type="button"
            @click="onRosterSelect(slotProps.data)"
          />
          <Button
            v-tooltip.top="'Delete Roster'"
            class="p-button-rounded p-button-text"
            icon="fas fa-trash"
            @click="clickTrash(slotProps.data)"
          />
          <Button
            v-tooltip.top="'View Roster Members'"
            class="p-button-rounded p-button-text"
            icon="fas fa-list"
            @click="viewRoster(slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>

    <confirmation-dialog
      v-bind:display="displayDelConfirmation"
      v-bind:message="deleteMessage"
      v-bind:type="'Delete Roster'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmDelete"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />

    <ConfirmDialog></ConfirmDialog>
  </span>
</template>

<script>
import ActivitiesApi from "@/apis/ActivitiesApi";
import LocationsApi from "@/apis/LocationsApi";
import MinistriesApi from "@/apis/MinistriesApi";
import RostersApi from "@/apis/RostersApi";
import { RosterModel } from "@/models/RosterModel";
import { responseToOptions } from "@/functions";

export default {
  data() {
    return {
      action: null,
      activity: null,
      activitiesApi: null,
      activityId: null,
      activityOptions: null,
      deleteMessage: null,
      dialogHeader: null,
      displayDelConfirmation: false,
      displayProgressDialog: false,
      // displayRosterDialog: false,
      loading: false,
      loadingRosters: false,
      locationOptions: null,
      locations: null,
      locationsApi: null,
      ministriesApi: null,
      ministryId: null,
      ministryInfo: null,
      roster: new RosterModel(),
      rosterToDelete: [],
      rosters: null,
      selectedMember: null,
      selectedRoster: null,
    };
  },
  created() {
    this.ministriesApi = new MinistriesApi();
    this.activitiesApi = new ActivitiesApi();
    this.rostersApi = new RostersApi();
    this.locationsApi = new LocationsApi();

    this.ministriesApi.getFullMinistryInfo((data) => {
      this.ministryInfo = data;
      this.ministryId = parseInt(this.$route.query.ministryId);
      this.activityId = parseInt(this.$route.query.activityId);
      if (this.ministryId) {
        this.activityOptions = this.ministryInfo.find((n) => n.id == this.$route.query.ministryId).children;
      }
      if (this.activityId) {
        this.loadRosters();
      }
    });
  },
  mounted() {
    this.loading = true;
    this.locationsApi.getLocationsList((data) => {
      this.locations = data;
      this.locationOptions = responseToOptions(data);
    });
  },
  methods: {
    ministryChanged: function (event, value) {
      this.activityId = null;
      this.activity = null;
      this.rosters = null;
      if (this.ministryInfo) {
        this.activityOptions = this.ministryInfo.find((n) => n.id == this.ministryId).children;
      }
    },
    activityChanged: function () {
      if (this.activityId) {
        this.loadRosters();
      }
    },
    loadRosters: function () {
      this.loading = true;
      this.loadingRosters = true;

      this.activitiesApi.getActivity(this.activityId, (activity) => {
        this.activity = activity;
      });
      this.rostersApi.getRosters(this.activityId, (data) => {
        this.rosters = data;
        this.loadingRosters = false;
        this.loading = false;
      });
    },
    onRosterSelect(data) {
      this.$router.push("/roster/edit?rosterId=" + data.id + "&ministryId=" + this.ministryId + "&activityId=" + this.activityId);
    },
    addNewRoster() {
      this.$router.push("/roster/add?activityId=" + this.activityId + "&ministryId=" + this.ministryId);
    },
    promoteRosters() {
      this.$confirm.require({
        message: "Are you sure you want to promote the rosters in this activity?",
        header: "Confirmation",
        icon: "fas fa-exclamation-triangle",
        accept: () => {
          this.activitiesApi.promoteRosters(this.activityId);
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    viewRoster(id) {
      this.$router.push("/roster/view?rosterId=" + id + "&ministryId=" + this.ministryId + "&activityId=" + this.activityId);
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    clickDeleteRoster(roster) {
      this.dialogHeader = "Deleting Roster";
      this.displayProgressDialog = true;
      this.rostersApi.deleteRoster(roster.id, () => {
        this.rosters = this.rosters.slice().filter((n) => n.id !== roster.id);
        this.roster = new RosterModel();
        // this.displayRosterDialog = false;
        this.dialogHeader = null;
        this.displayProgressDialog = false;
      });
    },
    clickTrash(roster) {
      this.rosterToDelete = roster;
      this.deleteMessage = "Are you sure you want to delete " + roster.name + "?";
      this.displayDelConfirmation = true;
    },
    confirmCancel() {
      this.displayDelConfirmation = false;
    },
    confirmDelete() {
      this.clickDeleteRoster(this.rosterToDelete);
      this.displayDelConfirmation = false;
      this.rosterToDelete = [];
    },
  },
};
</script>

<style scoped>
.test {
  display: inline;
}
</style>
