import { request } from "@/functions";

export default class MinistriesApi {
  getFullMinistryInfo(successCallback) {
    request("get", "/ministries/full", null, successCallback);
  }

  getMinistriesList(successCallback) {
    request("get", "/ministries", null, successCallback);
  }

  getMinistry(id, successCallback) {
    request("get", "/ministries/" + id, null, successCallback);
  }

  addMinistry(ministry, successCallback) {
    request("POST", "/ministries/", ministry, successCallback);
  }

  updateMinistry(ministry, successCallback) {
    request("PUT", "/ministries/" + ministry.id, ministry, successCallback);
  }

  deleteMinistry(id, successCallback) {
    request("DELETE", "/ministries/" + id, null, successCallback);
  }

  //
  // getCarsSmall() {
  //     return axios.get('demo/data/cars-small.json').then(res => res.data.data);
  // }
  //
  // getCarsMedium() {
  //     return axios.get('demo/data/cars-medium.json').then(res => res.data.data);
  // }
  //
  // getCarsLarge() {
  //     return axios.get('demo/data/cars-large.json').then(res => res.data.data);
  // }
}
