<template>
  <span>
    <ProgressSpinner v-if="!ministryInfo" />

    <DataTable
      v-if="ministryInfo"
      :key="rosterId"
      ref="dt"
      :export-filename="rosterName + '-' + formatDate(new Date(), 'MM-dd-yyyy HH:mm:ss')"
      v-model:selection="selectedRosterMembers"
      :value="selectedRoster"
    >
      <template #empty>
        <div v-if="loadingRoster">
          <ProgressSpinner />
        </div>
        <div v-else-if="rosterId">No Roster Members Found</div>
        <div v-else>Please select a Roster</div>
      </template>
      <template #header>
        <div v-if="ministryInfo" class="p-fluid formgrid grid align-items-end vertical-container">
          <div class="field col-12 md:col-3">
            <custom-label v-bind:label="'Ministry'" v-bind:required="false" />
            <Dropdown
              id="ministries"
              v-model="ministryId"
              :disabled="loadingRoster"
              :options="ministryInfo"
              optionLabel="name"
              optionValue="id"
              placeholder="Select a Ministry"
              @change="ministryChanged"
            />
          </div>
          <div class="field col-12 md:col-3">
            <custom-label v-bind:label="'Activity'" v-bind:required="false" />
            <Dropdown
              id="activities"
              v-model="activityId"
              :disabled="loadingRoster"
              :options="activityOptions"
              optionLabel="name"
              optionValue="id"
              placeholder="Select an Activity"
              @change="activityChanged"
            />
          </div>
          <div class="field col-12 md:col-3">
            <custom-label v-bind:label="'Roster'" v-bind:required="false" />
            <Dropdown
              id="rosters"
              v-model="rosterId"
              :disabled="loadingRoster"
              :options="rosterOptions"
              optionLabel="name"
              optionValue="id"
              placeholder="Select a Roster"
              @change="rosterChanged"
            />
          </div>
          <div v-if="rosterId" class="field col-1 md:col-3">
            <Button icon="fas fa-plus" label="Add/Delete Roster Member" @click="addMember" />
          </div>
        </div>
      </template>
      <Column headerStyle="width: 3em" selectionMode="multiple" />
      <Column field="firstName" header="First Name" :hidden="true" />
      <Column field="middleName" header="Middle Name" :hidden="true" />
      <Column field="lastName" header="Last Name" :hidden="true" />
      <Column field="name" header="Name" headerStyle="white-space: nowrap" />
      <Column field="dateOfBirth" header="Birthdate" headerStyle="width: 10em;text-align: left" />
      <Column field="email" header="Email" headerStyle="width: 10em;text-align: left" />
      <Column header="Phone Number" field="phoneNumber" headerStyle="width: 10em;text-align: left" />
      <Column header="Address" field="address" headerStyle="width: 20%;text-align: left" />
      <Column field="address2" header="Address 2" :hidden="true" />
      <Column field="city" header="City" :hidden="true" headerStyle="width: 10%;text-align: left" />
      <Column field="state" header="State" :hidden="true" headerStyle="width: 10%;text-align: left" />
      <Column field="zipcode" header="Zip" :hidden="true" headerStyle="width: 10%;text-align: left" />
      <Column field="status" header="Status" headerStyle="width: 10%;text-align: left" />
      <Column bodyStyle="text-align: center; overflow: visible" header="Actions" headerStyle="width: 8%;text-align: center">
        <template #body="slotProps">
          <Button
            class="p-button-rounded p-button-text"
            icon="fas fa-trash"
            v-tooltip.top="'Delete Member'"
            @click="clickTrash(slotProps.data)"
          />
        </template>
      </Column>
      <template v-if="selectedRosterMembers.length != 0" #footer>
        <Button
          v-if="selectedRosterMembers.length != 0"
          label="Download CSV file"
          @click="exportTest($event, selectedRosterMembers)"
        />
      </template>
    </DataTable>

    <div v-if="rosterId" class="field col-1 md:col-3">
            <Button icon="fas fa-envelope" label="Send Message to Roster" @click="sendMessage" />
    </div>


    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />

    <confirmation-dialog
      v-bind:display="displayDelConfirmation"
      v-bind:message="deleteMessage"
      v-bind:type="'Delete Roster Member'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmDelete"
    />

    <confirmation-dialog
      v-bind:display="displayEmptyConfirmation"
      v-bind:message="confirmationMessage"
      v-bind:type="'Empty Roster'"
      @confirm-no="confirmEmptyCancel"
      @confirm-yes="confirmEmptyAdd"
    />
  </span>
</template>

<script>
import ActivitiesApi from "@/apis/ActivitiesApi";
import MinistriesApi from "@/apis/MinistriesApi";
import RostersApi from "@/apis/RostersApi";
import {formatDate, formatIndividualName, responseToOptions} from "@/functions";

export default {
  data() {
    return {
      ministryId: null,
      ministryInfo: null,
      activityId: null,
      activityOptions: null,
      rosterId: null,
      rosterOptions: null,
      selectedRoster: [],
      selectedMember: null,
      selectedRosterMembers: [],
      dialogHeader: null,
      displayProgressDialog: false,
      memberToDelete: [],
      displayDelConfirmation: false,
      loadingRoster: false,
      rosterName: null,
      deleteMessage: null,
      displayEmptyConfirmation: false,
      confirmationMessage: null,
      rosterMembersToSend: null,
      ministriesApi: null,
      activitiesApi: null,
      rostersApi: null,
      fRoster: null,
    };
  },
  created() {
    console.log("created");
  },
  updated() {
    console.log("updated");
  },
  activated() {
    console.log("activated");
  },
  mounted() {
    console.log("mounted");
    this.ministriesApi = new MinistriesApi();
    this.activitiesApi = new ActivitiesApi();
    this.rostersApi = new RostersApi();
    this.ministriesApi.getFullMinistryInfo((data) => {
      this.ministryInfo = data;
      this.ministryId = parseInt(this.$route.query.ministryId);
      if (this.ministryId) {
        this.activityOptions = this.ministryInfo.find((n) => n.id == this.$route.query.ministryId).children;
      }
      this.activityId = parseInt(this.$route.query.activityId);
      if (this.activityId && this.ministryId) {
        this.rosterOptions = this.activityOptions.find((n) => n.id == this.$route.query.activityId).children;
      }
      this.rosterId = parseInt(this.$route.query.rosterId);
      if (this.rosterId) {
        this.selectedRoster = [];
        this.loadingRoster = true;
        this.getRoster();
        if (!this.loadingRoster) {
          this.rosterName = this.rosterOptions.filter((n) => n.id === this.rosterId)[0].name;
        }
      }
    });
  },

  methods: {
    getData: function (data) {
      return data.data.individual;
    },
    ministryChanged: function () {
      this.activityId = null;
      this.rosterId = null;
      this.selectedRoster = null;
      if (this.ministryInfo) {
        this.activityOptions = this.ministryInfo.find((n) => n.id == this.ministryId).children;
      }
    },
    activityChanged: function () {
      this.rosterId = null;
      this.selectedRoster = null;
      if (this.activityId) {
        this.loadingRosters = true;
        if (this.ministryInfo) {
          this.rosterOptions = this.activityOptions.find((n) => n.id == this.activityId).children;
        }
      }
    },
    rosterChanged: function () {
      if (this.rosterId) {
        this.selectedRoster = [];
        this.loadingRoster = true;
        this.getRoster();
        //this.rosterName = this.rosterOptions.filter(n => n.code === this.rosterId)[0].name
        let item = this.rosterOptions.find((element) => element.id === this.rosterId);
        this.rosterName = item.name;
      }
    },
    showDeleteDialog: function (id) {
      alert("Delete " + id);
    },
    getActivities: function () {
      this.activitiesApi.getActivitiesList(this.ministryId, (data) => (this.activityOptions = responseToOptions(data)));
    },
    getRoster: function () {
      this.loadingRoster = true;
      this.rostersApi.getRosterMembers(this.rosterId, (data) => {
        console.log(JSON.stringify(data));
        this.selectedRoster = data.map((r) => {
          return {
            firstName: r.individual.firstName,
            middleName: r.individual.middleName || "",
            lastName: r.individual.lastName || r.individual.household.lastName,
            name: formatIndividualName(r),
            dateOfBirth: formatDate(r.individual.dateOfBirth, "MM-dd-yyyy"),
            email: r.individual.email,
            phoneNumber: r.individual.phoneNumbers.find((n) => n.preferred === true)?.number || "none",
            address: r.individual.household.address1,
            address2: r.individual.household.address2,
            city: r.individual.household.city,
            state: r.individual.household.state,
            zipcode: r.individual.household.zipcode,
            status: r.individual.status,
          };
        });

        this.loadingRoster = false;
      });
    },
    addMember: function () {
      this.$router.push("/rostermembers/manage?rosterId=" + this.rosterId);
    },
    clickSaveRosterMembers: function (rosterMembers) {
      if (rosterMembers.length != 0) {
        this.sendRosterMembers(rosterMembers);
      } else {
        this.confirmationMessage = "This roster has no members.  Do you wish to continue?";
        this.rosterMembersToSend = rosterMembers;
        this.displayEmptyConfirmation = true;
      }
    },
    sendMessage: function () {
      this.$router.push("/sendrostermessage?rosterId=" + this.rosterId);
    },
    sendRosterMembers: function (rosterMembers) {
      this.dialogHeader = "Updating Roster Members";
      this.displayProgressDialog = true;
      this.rostersApi.updateRosterMembers(this.rosterId, rosterMembers, () => {
        this.selectedRoster = rosterMembers;
        this.displayProgressDialog = false;
        this.dialogHeader = null;
      });
    },
    confirmEmptyCancel: function () {
      this.displayEmptyConfirmation = false;
    },
    confirmEmptyAdd: function () {
      this.sendRosterMembers(this.rosterMembersToSend);
      this.displayEmptyConfirmation = false;
    },
    clickTrash: function (data) {
      this.memberToDelete = data.individual;
      this.deleteMessage = "Are you sure you want to delete " + data.individual.firstName + " from " + this.rosterName + "?";
      //this.rosterName = this.rosterOptions.filter(n => n.code === this.rosterId)[0].name
      let index = this.rosterOptions.findIndex((a) => a.id === this.rosterId);
      this.roster = this.rosterOptions[index];
      this.displayDelConfirmation = true;
    },
    confirmCancel: function () {
      this.displayDelConfirmation = false;
    },
    confirmDelete: function () {
      //this.clickDeleteLocation(this.locationToDelete)
      this.dialogHeader = "Deleting Roster Member";
      this.displayProgressDialog = true;
      this.selectedRoster = this.selectedRoster.slice().filter((n) => n.individual.id !== this.memberToDelete.id);
      this.rostersApi.updateRosterMembers(this.rosterId, this.selectedRoster, () => {
        this.displayProgressDialog = false;
        this.dialogHeader = null;
      });
      this.displayDelConfirmation = false;
      this.memberToDelete = [];
    },
    exportTest: function (event, selectedRosterMembers) {
      this.$refs.dt.exportCSV({ selectionOnly: true });
    },
    formatDate: function (date, format) {
      return formatDate(date, format);
    },
  },
};
</script>

<style scoped></style>
