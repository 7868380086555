<template>
  <span>
    <Dialog
      :closable="false"
      :contentStyle="{ overflow: 'visible' }"
      :modal="true"
      :style="{ width: '50vw' }"
      :visible.sync="display"
    >
      <template #header>
        Service Attendance for {{ service.service.name }} on
        {{ fixDate(service.calendar.eventDate) }}
      </template>
      <div class="grid p-fluid">
        <div class="col-12" style="padding: 0.5em">
          <custom-label v-bind:label="'Number of Attendees'" v-bind:required="true" />
          <InputText id="attendance" v-model="service.totalAttendance" type="text" />
        </div>
      </div>
      <template #footer>
        <div>
          <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="click('cancel')" />
          <Button autofocus icon="fas fa-check" label="Save" @click="click('add')" />
        </div>
      </template>
    </Dialog>
    <Toast />
  </span>
</template>

<script>
import {formatDate} from "@/functions";
import {validate, validations} from "@/validation";

export default {
  name: "ServiceAttendanceDialog",
  props: ["display", "modelValue"],
  emits: ["cancel-attendance", "add-attendance"],
  data() {
    return {
      action: null,
      locationsApi: null,
      service: null,
    };
  },
  updated() {
    this.service = this.modelValue;
  },
  created() {},
  mounted() {},
  methods: {
    click(operation) {
      if (operation == "save" || operation == "add") {
        const errors = validate(this.service, validations.serviceAttendance);
        if (errors) {
          errors.forEach((element) => {
            this.$toast.add({
              severity: element.severity,
              summary: element.content,
              life: 3000,
            });
          });
          return;
        }
      }
      this.$emit(operation + "-attendance", this.service);
    },
    fixDate(date) {
      return formatDate(date);
    },
  },
};
</script>
