<template>
  <div class="p-fluid">
    <div class="field grid">
      <h1>Church Information</h1>
    </div>
    <div class="field grid">
      <div class="field col-12">
        <label for="church_name"> Church Name </label>
        <InputText id="church_name" type="text" v-model="signup.name" />
      </div>
    </div>
    <div class="field grid">
      <div class="field col-12">
        <label for="church_address1"> Address 1 </label>
        <InputText id="church_address1" type="text" v-model="signup.address1" />
      </div>
    </div>
    <div class="field grid">
      <div class="field col-12">
        <label for="church_address2">Address 2</label>
        <InputText id="church_address2" type="text" v-model="signup.address2" />
      </div>
    </div>
    <div class="field grid">
      <div class="field col-12 md:col-6">
        <label for="church_city">City</label>
        <InputText id="church_city" type="text" v-model="signup.city" />
      </div>
      <div class="field col-12 md:col-3">
        <label for="church_state">State</label>
        <Dropdown id="church_state" :options="states" optionLabel="label" optionValue="value" v-model="signup.state" />
      </div>
      <div class="field col-12 md:col-3">
        <label for="church_zip">Zip</label>
        <InputText id="church_zip" type="text" v-model="signup.zipcode" />
      </div>
    </div>
    <div class="field grid">
      <div class="field col-12 md:col-6">
        <label for="adminUserFirstName">Admin User First Name</label>
        <InputText id="adminUserFirstName" type="text" v-model="signup.adminUserFirstName" />
      </div>
      <div class="field col-12 md:col-6">
        <label for="adminUserLastName">Admin User Last Name</label>
        <InputText id="adminUserLastName" type="text" v-model="signup.adminUserLastName" />
      </div>
    </div>
    <div class="field grid">
      <div class="field col-12 md:col-6">
        <label for="adminUser">Admin User Email</label>
        <InputText id="adminUser" type="text" v-model="signup.adminUserEmail" />
      </div>
      <div class="field col-12 md:col-6">
        <label for="adminPassword">Admin User Password</label>
        <InputText id="adminPassword" type="password" v-model="signup.adminUserPassword" />
      </div>
    </div>
  </div>
</template>

<script>
import { states } from "@/constants";

export default {
  name: "ChurchInfoForm",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  data() {
    return {
      signup: null,
      states: states,
    };
  },
  mounted() {
    this.signup = this.modelValue;
  },
  created() {
    this.signup = this.modelValue;
  },
  updated() {
    this.signup = this.modelValue;
  },
};
</script>

<style scoped>
.field {
  margin-bottom: 0.25rem;
}
</style>
