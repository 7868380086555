export class RosterModel {
  constructor() {
    this.id = null;
    this.churchId = null;
    this.activityId = null;
    this.name = null;
    this.locationId = null;
    this.location = {};
  }
}
