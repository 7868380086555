export class ActivityModel {
  // id = null
  // churchId = null
  // ministryId = null
  // name = null
  // description = null
  // startDate = null
  // endDate = null
  // recurrenceType = null

  constructor() {
    this.id = null;
    this.churchId = null;
    this.ministryId = null;
    this.name = null;
    this.description = null;
    this.startDate = null;
    this.endDate = null;
    this.activityTime = null;
    this.recurrenceType = null;
    this.rosterCount = null;
  }
}
