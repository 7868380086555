<template>
  <span>
    <div class="grid">
      <div class="col-7">
        <Dropdown v-model="timeValue" :options="hours" optionLabel="name" optionValue="code" @change="update($event)"> </Dropdown>
      </div>
      <div class="col-5">
        <Dropdown v-model="periodValue" :options="periods" optionLabel="name" optionValue="code" @change="update($event)">
        </Dropdown>
      </div>
    </div>
  </span>
</template>

<script>
import { hourOptions, periodOptions } from "@/constants";
import { format, parse } from "date-fns";

export default {
  name: "TimeSelector",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  data() {
    return {
      timeValue: "8:00",
      periodValue: "AM",
      hours: hourOptions,
      periods: periodOptions,
    };
  },
  created() {
    this.parseTime();
  },
  methods: {
    parseTime() {
      if (this.modelValue !== null) {
        let time = parse(this.modelValue, "HH:mm:ss", new Date());
        this.timeValue = format(time, "h:mm");
        this.periodValue = format(time, "a");
      }
      this.update();
    },
    update(event) {
      let parts = this.timeValue.split(":");
      let hour = parseInt(parts[0]);
      let minutes = parts[1];
      if (this.periodValue === "PM") {
        hour += 12;
      }
      this.$emit("update:modelValue", ("0" + hour).slice(-2) + ":" + minutes + ":00");
      // this.$emit('update:modelValue',this.timeValue + " " + this.periodValue);
      //     format(parse(this.timeValue + " " + this.periodValue, "hh:mm a", new Date()),
      //         'HH:mm:ss'))
      return true;
    },
  },
};
</script>
