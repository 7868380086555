<template>
  <div>
    <ProgressSpinner v-if="!group" />
    <span v-if="group">
      <Card style="width: 40rem; margin-bottom: 2em">
        <template #title>
          <div v-if="!editMode">Add Group</div>
          <div v-else-if="editMode" v-once>Edit {{ group.name }} Group</div>
        </template>
        <template #content>
          <div class="grid p-fluid">
            <div class="col-12" style="padding: 0.5em">
              <custom-label v-bind:label="'Name'" v-bind:required="true" />
              <InputText id="name" v-model="group.name" type="text" />
            </div>
          </div>
          <PickList v-model="picklistValues">
            <template #sourceheader>
              <div class="col-12 md:col-12 p-inputgroup" style="text-align: right">
                <InputText v-model="searchText" placeholder="Search" @keyup.enter="sendSearch" />
                <Button class="p-inputgroup-addon" icon="fas fa-search" type="submit" @click="sendSearch()" />
              </div>
            </template>
            <template #targetheader>
              <div v-once>{{ group.name }}</div>
            </template>

            <template #item="slotProps">
              <div>
                <div>
                  <span>
                    <IndividualNameDisplay :value="slotProps.item" />
                  </span>
                </div>
              </div>
            </template>
          </PickList>
          <p></p>
          <Checkbox v-model="group.private" :binary="true" /> Make Group Private
        </template>
        <template #footer>
          <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="clickCancel" />
          <Button autofocus icon="fas fa-check" label="Save" @click="clickSave(group)" />
        </template>
      </Card>
      <Toast />

      <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
    </span>
  </div>
</template>

<script>
import GroupsApi from "@/apis/GroupsApi";
import IndividualsApi from "@/apis/IndividualsApi";
import { validate, validations } from "@/validation";
import { GroupModel } from "@/models/GroupModel";
import { request } from "@/functions";

export default {
  name: "ManageGroup",
  data() {
    return {
      group: null,
      action: null,
      groupId: null,
      groupApi: null,
      individualApi: null,
      dialogHeader: null,
      picklistValues: [[], []],
      displayProgressDialog: false,
      editMode: true,
      searchString: null,
      searchText: null,
    };
  },
  created() {
    this.groupApi = new GroupsApi();
    this.individualApi = new IndividualsApi();
    this.groupId = this.$route.query.groupId;
    if (!this.groupId) {
      this.editMode = false;
      this.group = new GroupModel();
    } else {
      this.loadGroup(this.groupId);
    }
  },
  methods: {
    loadGroup: function (groupId) {
      this.groupApi.getGroup(groupId, (group) => {
        this.group = group;
        this.picklistValues = [[], this.group.members];
      });
    },

    clickCancel() {
      this.$router.push("/groupList");
    },
    clickSave(group) {
      if (!this.editMode) {
        this.dialogHeader = "Adding Group";
      } else {
        this.dialogHeader = "Updating Group";
      }
      this.group.members = this.picklistValues[1].map((m) => m.individualId);
      const errors = validate(this.group, validations.group);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.displayProgressDialog = true;

      if (!this.editMode) {
        this.groupApi.addGroup(group, (data) => {
          this.group = new GroupModel();
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/groupList");
        });
      } else {
        this.groupApi.updateGroup(group, () => {
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/groupList");
        });
      }
    },
    keyPressed(e) {
      if (e.key === "Enter") {
        this.sendSearch();
      }
    },
    updateAvailable(list) {
      this.picklistValues = [list, this.picklistValues[1]];
    },
    sendSearch() {
      let s = this.searchText;
      let searchString;
      if (this.searchText) {
        let searchArray = this.searchText.split(" ");
        if (searchArray.length === 1) {
          searchString = "firstName=" + this.searchText + "&lastName=" + this.searchText + "&match=any";
        } else {
          searchString = "firstName=" + searchArray[0] + "&lastName=" + searchArray[1];
        }
        this.searchText = "";
      }
      request("get", "/individuals?" + searchString, null, (data) => {
        let newAvailable = [];
        let available = data.map((i) => {
          let exists = this.picklistValues[1].find((element) => {
            if (element.individualId === i.id) {
              return true;
            }
          });
          if (!exists) {
            let newIndividual = {
              individualId: i.id,
              firstName: i.firstName,
              lastName: i.lastName,
              email: i.email,
              phoneNumber: i.phoneNumber,
            };
            newAvailable.push(newIndividual);
          }
        });
        this.updateAvailable(newAvailable);
      });
    },
  },
};
</script>

<style scoped>
/*.p-picklist.p-picklist-target-controls {*/
/*  display: none;*/
/*}*/
/*.p-picklist {*/
/*  display: none;*/
/*}*/
.p-picklist-source-controls {
  display: none;
}
.p-picklist-target-controls {
  display: none;
}
</style>
