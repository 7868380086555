<template>
  <span>
    <h1>Service Attendance Reports</h1>

    <div class="grid">
      <div class="col-12">
        <div class="grid">
          <div class="col-1">Start Date</div>
          <div class="col-2">
            <Calendar v-model="startDate" :showIcon="true" dateFormat="yy-mm-dd" input-class="p-inputtext p-component p-filled" />
          </div>
          <div class="col-1">End Date</div>
          <div class="col-2">
            <Calendar v-model="endDate" :showIcon="true" dateFormat="yy-mm-dd" input-class="p-inputtext p-component p-filled" />
          </div>
          <div class="col-2">
            <Button autofocus icon="fas fa-check" label="OK" />
          </div>
        </div>
      </div>
    </div>

    <Chart :data="serviceData" :height="100" type="line" />
  </span>
</template>

<script>
import ServicesApi from "@/apis/ServicesApi";
import { formatDate } from "@/functions";
import sub from "date-fns/sub";

export default {
  data() {
    return {
      pendingServices: null,
      dialogHeader: null,
      displayProgressDialog: false,
      attendanceRecord: null,
      displayAttendanceDialog: false,
      service: null,
      serviceData: null,
      startDate: null,
      endDate: null,
    };
  },
  created() {
    this.servicesApi = new ServicesApi();
    this.startDate = formatDate(sub(new Date(), { years: 1 }), "yyyy-MM-dd");
    this.endDate = formatDate(new Date(), "yyyy-MM-dd");
  },
  mounted() {
    this.getServiceData();
  },

  methods: {
    getServiceData() {
      let colors = ["#ff0000", "#ffa500", "#ffff00", "#008000", "#0000ff", "#4b0082", "#ee82ee"];
      this.servicesApi.getAttendanceData((data) => {
        data.options = { beginAtZero: true };
        data.datasets.forEach((dataset, i) => {
          dataset.borderColor = colors[i];
        });
        this.serviceData = data;
      });
    },
  },
};
</script>

<style scoped></style>
