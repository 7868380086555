<template>
  <PanelMenu key="this.$store.state.authenticated" :model="items" />
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      // keycloak: { from: "keycloak" },
      items: [
        {
          label: "People",
          items: [
            {
              label: "Search",
              icon: "fas fa-search",
              command: () => {
                this.$router.push("/individuals/search");
              },
            },
            {
              label: "Households",
              icon: "fas fa-users",
              command: () => {
                this.$router.push("/households");
              },
            },

            {
              label: "Individuals",
              icon: "fas fa-user",
              command: () => {
                this.$router.push("/individuals");
              },
            },
          ],
        },
        {
          label: "Ministries",
          icon: "fas fa-sitemap",
          command: () => {
            this.$router.push("/ministries");
          },
        },
        {
          label: "Activities",
          icon: "fas fa-calendar",
          command: () => {
            this.$router.push("/activities");
          },
        },
        {
          label: "Rosters",
          items: [
            {
              label: "Roster List",
              icon: "fas fa-list",
              command: () => {
                this.$router.push("/rosters");
              },
            },
            {
              label: "View a Roster",
              icon: "fas fa-id-card",
              command: () => {
                this.$router.push("/roster");
              },
            },
          ],
        },

        {
          label: "Services",
          icon: "fas fa-globe",
          command: () => {
            this.$router.push("/services");
          },
        },
        {
          label: "Locations",
          icon: "fas fa-home",
          command: () => {
            this.$router.push("/locations");
          },
        },
        {
          label: "Attendance",
          command: () => {},
          items: [
            {
              label: "Activity Attendance",
              command: () => {},
              items: [
                {
                  label: "Record Attendance",
                  icon: "fas fa-list",
                  command: () => {
                    this.$router.push("/activityAttendance");
                  },
                },
                {
                  label: "Edit Attendance",
                  icon: "fas fa-pencil",
                  command: () => {
                    this.$router.push("/editActivityAttendance");
                  },
                },
              ],

              // command: () => {
              //   this.$router.push('/services')
              // },
            },
            {
              label: "Service Attendance",
              command: () => {},
              items: [
                {
                  label: "Record Attendance",
                  icon: "fas fa-globe",
                  command: () => {
                    this.$router.push("/serviceAttendance");
                  },
                },
                {
                  label: "Edit Attendance",
                  icon: "fas fa-pencil",
                  command: () => {
                    this.$router.push("/editServiceAttendance");
                  },
                },
              ],
              // command: () => {
              //   this.$router.push('/serviceAttendance')
              // },
            },
          ],
        },
        {
          label: "Communications",
          icon: "fas fa-envelope",
          items: [
            {
              label: "Send Group Message",
              icon: "fas fa-user-plus",
              command: () => {
                this.$router.push("/groupEmail");
              },
            },
            {
              label: "View Sent Messages",
              icon: "fas fa-envelope",
              command: () => {
                this.$router.push("/viewSentMessages");
              },
            },
            {
              label: "Manage Groups",
              icon: "fas fa-users",
              command: () => {
                this.$router.push("/groupList");
              },
            },
          ],
        },
        {
          label: "Reports",
          icon: "fas fa-chart-bar",
          items: [
            {
              label: "Service Attendance",
              icon: "fas fa-users",
              command: () => {
                this.$router.push("/reports/services");
              },
            },
            {
              label: "Upcoming Events",
              icon: "fas fa-calendar",
              command: () => {
                this.$router.push("/reports/events");
              },
            },
          ],
        },
        {
          label: "Manage Users",
          icon: "fas fa-chart-bar",
          items: [
            {
              label: "View All Users",
              icon: "fas fa-users",
              command: () => {
                this.$router.push("/users");
              },
            },
          ],
        },
      ],
    };
  },
};
</script>
