<template>
  <span v-if="location">
    <ProgressSpinner v-if="!location" />
    <Card style="width: 35rem; margin-bottom: 2em">
      <template #title>
        <div v-if="!locationId" v-once>Add New Location</div>
        <div v-else v-once>Edit {{ this.location.name }}</div>
      </template>
      <template #content>
        <div class="grid p-fluid">
          <div class="col-12" style="padding: 0.5em">
            <custom-label v-bind:label="'Location Name'" v-bind:required="true" />
            <InputText id="name" v-model="location.name" type="text" @keyup.enter="clickSave(location)"/>
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="clickCancel" />
        <Button autofocus icon="fas fa-check" label="Save" @click="clickSave(location)" />
      </template>
    </Card>

    <Toast />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
  </span>
</template>

<script>
import LocationsApi from "@/apis/LocationsApi";
import {validate, validations} from "@/validation";
import {LocationModel} from "@/models/LocationModel";

export default {
  name: "ManageLocation",
  data() {
    return {
      location: null,
      locationId: null,
      locationsApi: null,
      dialogHeader: null,
      displayProgressDialog: false,
      editMode: true,
    };
  },
  created() {
    this.locationId = this.$route.query.locationId;
    this.locationsApi = new LocationsApi();
    if (!this.locationId) {
      this.editMode = false;
      this.location = new LocationModel();
    } else {
      this.loadLocation(this.locationId);
    }
  },
  mounted() {},
  methods: {
    loadLocation: function (locationId) {
      this.locationsApi.getLocation(locationId, (location) => {
        this.location = location;
      });
    },

    clickCancel() {
      this.$router.push("/locations/");
    },
    clickSave(location) {
      if (!this.editMode) {
        this.dialogHeader = "Adding Location";
      } else {
        this.dialogHeader = "Updating Location";
      }
      const errors = validate(location, validations.location);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.displayProgressDialog = true;

      if (!this.editMode) {
        this.locationsApi.addLocation(location, (data) => {
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/locations/");
        });
      } else {
        this.locationsApi.updateLocation(location, () => {
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/locations/");
        });
      }
    },
  },
};
</script>
