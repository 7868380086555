<template>
  <span v-if="service">
    <ProgressSpinner v-if="!service" />
    <Card style="width: 40rem; margin-bottom: 2em">
      <template #title>
        <div v-if="!serviceId" v-once>Add New Service</div>
        <div v-else v-once>Edit {{ this.service.name }}</div>
      </template>
      <template #content>
        <div class="grid p-fluid">
          <div class="col-6" style="padding: 0.5em">
            <custom-label v-bind:label="'Name'" v-bind:required="true" />
            <InputText id="name" v-model="service.name" type="text" />
          </div>
          <div class="col-6" style="padding: 0.5em">
            <custom-label v-bind:label="'Location'" v-bind:required="true" />
            <Dropdown id="location"
                      v-model="service.locationId"
                      :options="locationOptions"
                      optionLabel="name"
                      optionValue="code"
                      @keyup.enter="clickSave(service)"
            />
          </div>
          <div class="col-6" style="padding: 0.5em">
            <custom-label v-bind:label="'Service Time'" v-bind:required="true" />

            <time-selector id="time" v-model="service.serviceTime" v-bind:mode="'edit'" @keyup.enter="clickSave(service)"/>
          </div>
          <div class="col-6" style="padding: 0.5em">
            <custom-label v-bind:label="'Recurs'" v-bind:required="true" />
            <Dropdown
              id="recurrenceType"
              v-model="service.recurrenceType"
              :options="recurs"
              defaultValue="NEVER"
              optionLabel="name"
              optionValue="code"
              @keyup.enter="clickSave(service)"
            />
          </div>
          <div class="col-6" style="padding: 0.5em">
            <custom-label v-bind:label="'Start Date'" v-bind:required="true" />
            <Calendar
              :key="service.id"
              v-model="service.startDate"
              format="yy-mm-dd"
              input-class="p-inputtext p-component p-filled"
              @keyup.enter="clickSave(service)"
            >
              <template #button-cancel>
                <Button class="p-button-text" icon="fas fa-times" label="Cancel" />
              </template>
              <template #button-confirm>
                <Button autofocus icon="fas fa-check" label="OK" />
              </template>
            </Calendar>
          </div>
          <div class="col-6" style="padding: 0.5em">
            <custom-label v-bind:label="'End Date'" />
            <Calendar :key="service.id" v-model="service.endDate" format="yy-mm-dd"
                      @keyup.enter="clickSave(service)"
                      input-class="p-inputtext p-component p-filled">
              <template #button-cancel>
                <Button class="p-button-text" icon="fas fa-times" label="Cancel" />
              </template>
              <template #button-confirm>
                <Button autofocus icon="fas fa-check" label="OK" />
              </template>
            </Calendar>
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="clickCancel" />
        <Button autofocus icon="fas fa-check" label="Save" @click="clickSave(service)" />
      </template>
    </Card>
    <Toast />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
  </span>
</template>

<script>
import {validate, validations} from "@/validation";
import {ServiceModel} from "@/models/ServiceModel";
import ServicesApi from "@/apis/ServicesApi";
import LocationsApi from "@/apis/LocationsApi";
import {recurrenceOptions} from "@/constants";
import {responseToOptions} from "@/functions";

export default {
  name: "ManageService",
  data() {
    return {
      service: null,
      serviceId: null,
      servicesApi: null,
      dialogHeader: null,
      displayProgressDialog: false,
      editMode: true,
      recurs: recurrenceOptions,
      locationOptions: null,
    };
  },
  created() {
    this.serviceId = this.$route.query.serviceId;
    this.servicesApi = new ServicesApi();
    this.locationsApi = new LocationsApi();
    this.locationsApi.getLocationsList((data) => {
      this.locations = data;
      this.locationOptions = responseToOptions(data);
    });
    if (!this.serviceId) {
      this.editMode = false;
      this.service = new ServiceModel();
    } else {
      this.loadService(this.serviceId);
    }
  },
  mounted() {},
  methods: {
    loadService: function (serviceId) {
      this.servicesApi.getService(serviceId, (service) => {
        this.onServiceLoaded(service);
        //self.service = service
      });
    },
    onServiceLoaded(service) {
      this.service = service;
    },

    clickCancel() {
      this.$router.push("/services/");
    },
    clickSave(service) {
      if (!this.editMode) {
        this.dialogHeader = "Adding Service";
      } else {
        this.dialogHeader = "Updating Service";
      }
      const errors = validate(service, validations.service);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.displayProgressDialog = true;

      if (!this.editMode) {
        this.servicesApi.addService(service, (data) => {
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/services/");
        });
      } else {
        this.servicesApi.updateService(service, () => {
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/services/");
        });
      }
    },
  },
};
</script>
