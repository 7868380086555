<template>
  <Card style="padding: 1%; text-align: left">
    <template #title> Address </template>
    <template #content>
      <div style="padding: 1%">
        {{ value.address1 }}<br />
        {{ value.address2 || "" }}
        <br v-if="value.address2" />
        {{ value.city }}, {{ value.state }} {{ value.zipcode }}
      </div>
      <div v-if="value.length === 0" style="padding: 1%">No Address Listed</div>
    </template>
  </Card>
</template>

<script>
export default {
  name: "AddressList",
  props: ["value"],
  methods: {},
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.p-card /deep/ .p-card-body {
  padding-bottom: 1px;
}

.p-card /deep/ .p-card-content {
  padding-bottom: 1rem;
  padding-top: 0px;
}
</style>
