<template>
  <span>
    <Dialog
      :closable="false"
      :contentStyle="{ overflow: 'visible' }"
      :modal="true"
      :style="{ width: '50vw' }"
      :visible.sync="display"
    >
      <template #header>
        <div v-if="action === 'add'">Add User</div>
        <div v-else-if="action === 'edit'" v-once>Edit {{ user.firstName }} {{ user.lastName }}</div>
      </template>
      <div class="grid p-fluid">
        <div class="col-3" style="padding: 0.5em">
          <custom-label v-bind:label="'First Name'" v-bind:required="true" />
          <InputText id="firstname" v-model="user.firstName" type="text" />
        </div>
        <div class="col-3" style="padding: 0.5em">
          <custom-label v-bind:label="'Last Name'" v-bind:required="true" />
          <InputText id="lastname" v-model="user.lastName" type="text" />
        </div>
        <div class="col-6" style="padding: 0.5em">
          <custom-label v-bind:label="'Email Address'" v-bind:required="true" />
          <InputText id="email" v-model="user.emailAddress" type="text" />
        </div>
        <div class="col-4" style="padding: 0.5em">
          <custom-label v-bind:label="'Password'" v-bind:required="action === 'add'" />
          <Password v-model="user.password" />
        </div>
        <div class="col-4" style="padding: 0.5em">
          <custom-label v-bind:label="'Re-Enter Password'" v-bind:required="action === 'add'" />
          <Password v-model="user.password2" />
        </div>
        <div class="col-4" style="padding: 0.5em">
          <custom-label v-bind:label="'Role'" v-bind:required="true" />
          <Dropdown v-model="user.role" :options="userRoles" optionLabel="label" optionValue="value" />
        </div>
      </div>
      <template #footer>
        <div v-if="action === 'add'">
          <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="click('cancel')" />
          <Button autofocus icon="fas fa-check" label="Add" @click="click('add')" />
        </div>
        <div v-else-if="action === 'edit'">
          <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="click('cancel')" />
          <Button class="p-button-text" icon="fas fa-trash" label="Delete" @click="click('delete')" />
          <Button autofocus icon="fas fa-check" label="Save" @click="click('save')" />
        </div>
      </template>
    </Dialog>
    <Toast />
  </span>
</template>

<script>
import { UserModel } from "@/models/UserModel";
import { validate, validations } from "@/validation";
import { userRoles } from "@/constants";

export default {
  name: "UserDialog",
  props: ["display", "action", "value"],
  emits: ["add-user", "cancel-user", "delete-user", "save-user"],
  data() {
    return {
      user: new UserModel(),
      userRoles: userRoles,
    };
  },
  updated() {
    this.user = this.value;
  },
  methods: {
    click(operation) {
      if (operation == "save" || operation == "add") {
        let errors = validate(this.user, validations.user);
        if (operation == "add" && !this.user.password) {
          errors = this.addError(errors, "You must specify a password");
        }
        if (this.user.password !== this.user.password2) {
          errors = this.addError(errors, "Passwords must match");
        }
        if (errors) {
          errors.forEach((element) => {
            this.$toast.add({
              severity: element.severity,
              summary: element.content,
              life: 3000,
            });
          });
          return;
        }
      }
      this.$emit(operation + "-user", this.user);
    },
    addError(errors, message) {
      if (!errors) {
        errors = [];
      }
      errors.push({ severity: "error", content: message });
      return errors;
    },
  },
};
</script>
