import { GroupModel } from "@/models/GroupModel";
import { request, stripTimeFromDate } from "@/functions";

export default class GroupsApi {
  getGroups(successCallback) {
    request("get", "/groups", null, successCallback);
  }

  // getGroups(userId, successCallback) {
  //     successCallback([new GroupModel("1", "2", "Test Group")])
  //     //request('get', '/groups/' + userId, null, successCallback)
  // }
  getGroup(groupId, successCallback) {
    request("get", "/groups/" + groupId, null, successCallback);
  }

  getGroupMembers(groupId, successCallback) {
    successCallback([new GroupModel("1", "2", "Test Group")]);
    //successCallback([new])
    //request('get', 'rosters/' + rosterId + '/members', null, successCallback)
  }

  addGroup(group, successCallback) {
    request("POST", "/groups/", group, successCallback);
  }

  updateGroup(group, successCallback) {
    request("PUT", "/groups/" + group.id, group, successCallback);
  }

  deleteGroup(id, successCallback) {
    request("DELETE", "/groups/" + id, null, successCallback);
  }

  sendGroupMessage(groupMessage, successCallback) {
    request("POST", "/groups/" + groupMessage.groupId + "/messages", groupMessage, successCallback);
  }

  sendTestMessage(groupMessage, successCallback) {
    request("POST", "/groups/testMessage", groupMessage, successCallback);
    //request("POST", "/groups/" + groupEmail.groupId + "/messages", groupEmail, successCallback);
    //successCallback();
  }

  getSentMessages(groupId, startDate, endDate, successCallback) {
    let url = "groups/" + groupId + "/messages";
    if (startDate) {
      url += "?startDate=" + stripTimeFromDate(startDate);
    }
    if (endDate) {
      url += "&endDate=" + stripTimeFromDate(endDate);
    }
    request("GET", url, null, successCallback);
    //request("POST", "/groups/" + groupEmail.groupId + "/messages", groupEmail, successCallback);
    //successCallback();
  }
}
