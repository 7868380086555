import { formatDate, request, stripTimeFromDate } from "@/functions";
//import { endOfDecadeWithOptions } from "date-fns/fp";

export default class AttendanceApi {
  getAttendance(attendanceId, successCallback) {
    // let url = "attendance/" + attendanceId;
    let url = "attendance/" + attendanceId;
    request("get", url, null, successCallback);
  }
  getPendingServices(successCallback) {
    request("get", "/attendance/services/pending", null, successCallback);
  }

  searchServices(startDate, endDate, successCallback) {
    let url = "attendance/services/completed?startDate=" + stripTimeFromDate(startDate);
    if (endDate) {
      url += "&endDate=" + stripTimeFromDate(endDate);
    }
    request("get", url, null, successCallback);
  }

  getPendingRosters(ministryId, activityId, successCallback) {
    if (ministryId) {
      let url = "/attendance/activities/pending?ministry=" + ministryId;
      if (activityId) {
        url += "&activity=" + activityId;
      }
      request("get", url, null, successCallback);
    } else {
      request("get", "/attendance/activities/pending", null, successCallback);
    }
  }

  searchRosters(ministryId, activityId, startDate, endDate, successCallback) {
    let url = "/attendance/activities/completed?ministry=" + ministryId + "&startDate=" + formatDate(startDate, 'yyyy-MM-dd');
    if (endDate) {
      url += "&endDate=" + formatDate(endDate, 'yyyy-MM-dd');
    }
    request("get", url, null, successCallback);
  }

  updateAttendance(attendanceRecord, successCallback) {
    request("POST", "/attendance", attendanceRecord, successCallback);
  }
}
