<template>
  <span>
    <ProgressSpinner v-if="!ministryOptions" />
    <DataTable v-if="ministryOptions" :value="displayRosters" emptyMessage="No Rosters Found" style="width1: 75%">
      <template #empty> No Rosters Found </template>
      <template #header>
        <div v-if="ministryOptions" class="p-fluid formgrid grid align-items-end vertical-container">
          <div class="field col-12 md:col-12">Edit Past Activity Attendance</div>
          <div class="field col-12 md:col-3">
            <custom-label v-bind:label="'Ministry'" v-bind:required="true" />
            <Dropdown
              v-if="ministryOptions"
              id="ministries"
              v-model="ministryId"
              :options="ministryOptions"
              optionLabel="name"
              optionValue="code"
              placeholder="Select a Ministry"
              @change="ministryChanged"
            />
          </div>
          <div class="field col-12 md:col-3">
            <custom-label v-bind:label="'Activity'" v-bind:required="true" />
            <Dropdown
              id="activities"
              v-model="activityId"
              :options="activityOptions"
              optionLabel="name"
              optionValue="code"
              placeholder="Select an Activity"
            />
          </div>
          <div class="field col-12 md:col-2">
            <custom-label v-bind:label="'Start Date'" v-bind:required="true" />
            <Calendar v-model="startDate" dateFormat="yy-mm-dd" input-class="p-inputtext p-component p-filled">
              <template #button-cancel>
                <Button class="p-button-text" icon="fas fa-times" label="Cancel" />
              </template>
              <template #button-confirm>
                <Button autofocus icon="fas fa-check" label="OK" />
              </template>
            </Calendar>
          </div>
          <div class="field col-12 md:col-2">
            <custom-label v-bind:label="'End Date'" v-bind:required="false" />
            <Calendar v-model="endDate" dateFormat="yy-mm-dd" input-class="p-inputtext p-component p-filled">
              <template #button-cancel>
                <Button class="p-button-text" icon="fas fa-times" label="Cancel" />
              </template>
              <template #button-confirm>
                <Button autofocus icon="fas fa-check" label="OK" />
              </template>
            </Calendar>
          </div>
          <div class="field col-12 md:col-2">
            <Button label="Search" @click="searchAttendance" />
          </div>
        </div>
      </template>

      <Column :sortable="true" field="roster.name" header="Roster Name" headerStyle="text-align: left" />
      <Column :sortable="true" header="Date" headerStyle="text-align: left">
        <template #body="slotProps">
          {{ fixDate(slotProps.data.calendar.eventDate) }}
        </template>
      </Column>
      <Column header="Attendance" headerStyle="text-align: left">
        <template #body="slotProps">
          {{ slotProps.data.totalAttendance }}
        </template>
      </Column>
      <Column header="Actions" headerStyle="width: 8em; text-align: left" style="text-align: center">
        <template #body="slotProps">
          <Button
            v-tooltip.top="'Edit Attendance'"
            class="p-button-rounded p-button-text"
            icon="fas fa-pencil"
            @click="editAttendance(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <activity-attendance-dialog
      v-bind:value="rosterAttendance"
      v-bind:display="displayAttendanceDialog"
      @save-attendance="clickEditAttendance"
      @cancel-attendance="clickCancelAttendance"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />

    <confirmation-dialog
      v-bind:display="displayConfirmation"
      v-bind:message="confirmationMessage"
      v-bind:type="'Empty Attendance'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmAdd"
    />

    <Toast />
  </span>
</template>

<script>
// import ProgressSpinner from "primevue/progressspinner";
//import Calendar from "primevue/calendar";
import ActivitiesApi from "@/apis/ActivitiesApi";
import AttendanceApi from "@/apis/AttendanceApi";
import MinistriesApi from "@/apis/MinistriesApi";
import {AttendanceInputModel} from "@/models/AttendanceInputModel";
import {formatDate, responseToOptions} from "@/functions";
import {validate, validations} from "@/validation";

export default {
  data() {
    return {
      displayRosters: null,
      displayProgressDialog: false,
      dialogHeader: null,
      rosterAttendance: null,
      displayAttendanceDialog: false,
      ministryOptions: null,
      activityOptions: null,
      ministryId: null,
      activityId: null,
      displayConfirmation: false,
      confirmationMessage: null,
      sendRosterAttendance: null,
      sendAttendees: null,
      allRosters: null,
      searchStartDate: null,
      searchValues: [],
      startDate: null,
      endDate: null,
    };
  },
  created() {
    this.attendanceApi = new AttendanceApi();
    this.ministriesApi = new MinistriesApi();
    this.activitiesApi = new ActivitiesApi();
  },
  mounted() {
    this.ministriesApi.getMinistriesList((data) => {
      this.ministryOptions = responseToOptions(data);
    });
  },
  watch: {
    // ministryId() {
    //   //this.getRosters()
    //   if (this.ministryId) {
    //     this.activityId = null;
    //     this.activitiesApi.getActivitiesList(this.ministryId, (data) => (this.activityOptions = responseToOptions(data)));
    //   }
    // },
  },

  methods: {
    ministryChanged() {
      if (this.ministryId) {
        this.activityId = null;
        this.activitiesApi.getActivitiesList(this.ministryId, (data) => (this.activityOptions = responseToOptions(data)));
      }
    },
    clickComplete(rosterAttendance) {
      this.dialogHeader = "Marking Activity Complete";
      this.displayProgressDialog = true;

      let attendanceRecord = new AttendanceInputModel();
      attendanceRecord.attendanceId = rosterAttendance.id;
      attendanceRecord.completed = true;
      this.attendanceApi.updateAttendance(attendanceRecord, () => {
        this.searchAttendance();
        this.dialogHeader = null;
        this.displayProgressDialog = false;
      });
    },
    editAttendance(data) {
      this.rosterAttendance = { ...data };
      this.displayAttendanceDialog = true;
    },
    clickEditAttendance(rosterAttendance, attendees) {
      if (attendees.length != 0) {
        this.sendAttendance(rosterAttendance, attendees);
      } else {
        this.confirmationMessage = "There are no members listed as present.  Do you wish to continue?";
        this.sendRosterAttendance = rosterAttendance;
        this.sendAttendees = attendees;
        this.displayConfirmation = true;
      }
    },
    confirmCancel() {
      this.displayConfirmation = false;
      this.sendRosterAttendance = null;
      this.sendAttendees = null;
    },
    confirmAdd() {
      this.sendAttendance(this.sendRosterAttendance, this.sendAttendees);
      this.displayConfirmation = false;
    },
    sendAttendance(rosterAttendance, attendees) {
      this.dialogHeader = "Adding Attendance";
      this.displayProgressDialog = true;

      let attendanceRecord = new AttendanceInputModel();
      attendanceRecord.attendanceId = rosterAttendance.id;
      attendanceRecord.rosterId = rosterAttendance.roster.id;
      attendanceRecord.attendees = attendees.map((it) => it.individual.id);
      attendanceRecord.completed = true;
      this.rosterAttendance.completed = true;

      this.attendanceApi.updateAttendance(attendanceRecord, () => {
        this.searchAttendance();
        this.dialogHeader = null;
        this.displayProgressDialog = false;
        this.displayAttendanceDialog = false;
      });
    },
    clickCancelAttendance() {
      this.displayAttendanceDialog = false;
    },
    fixDate(date) {
      return formatDate(date);
    },
    countAttendance(members) {
      return members.length
    },
    searchAttendance() {
      this.searchValues.ministryId = this.ministryId;
      this.searchValues.activityId = this.activityId;
      this.searchValues.startDate = this.startDate;
      this.searchStartDate = formatDate(this.startDate, "yyyy-MM-dd");
      const errors = validate(this.searchValues, validations.searchActivityAttendance);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.attendanceApi.searchRosters(this.ministryId, this.activityId, this.startDate, this.endDate, (data) => {
        this.allRosters = data;
        this.displayRosters = this.allRosters;
      });
    },
  },
};
</script>

<style scoped></style>
