<template>
  <div class="surface-section px-2 py-4 md:px-6 lg:px-8 text-center">
    <div class="mb-3 font-bold text-2xl">
      <span class="text-900">Welcome to Steeplesoft!</span>
    </div>
    <div class="text-500 text-sm mb-6"></div>
    <div class="text-700 text-sm mb-6"></div>
    <div class="grid">
      <div class="col-12 md:col-4 mb-4 px-5">
        <Button class="p-3 shadow-2 mb-3 inline-block surface-card outline-none" style="border-radius: 10px">
          <router-link :to="{name: 'individuals'}">
<!--            <i class="fas fa-user text-4xl blue-fg"/>-->
            <font-awesome-icon icon="fa-solid fa-user" class="text-4xl blue-fg" />
          </router-link>
        </Button>
        <div class="text-900 mb-3 font-medium">Individuals</div>
        <span class="text-700 text-sm line-height-3">View and edit church member info</span>
      </div>
      <div class="col-12 md:col-4 mb-4 px-5">
        <Button class="p-3 shadow-2 mb-3 inline-block surface-card outline-none" style="border-radius: 10px">
          <router-link :to="{name: 'households'}">
            <i class="fas fa-users text-4xl blue-fg"/>
          </router-link>
        </Button>
        <div class="text-900 mb-3 font-medium">Households</div>
        <span class="text-700 text-sm line-height-3">View and edit household info</span>
      </div>
      <div class="col-12 md:col-4 mb-4 px-5">
        <Button class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
          <router-link :to="{name: 'ministries'}">
            <i class="fas fa-sitemap text-4xl blue-fg"/>
          </router-link>
        </Button>
        <div class="text-900 mb-3 font-medium">Ministries</div>
        <span class="text-700 text-sm line-height-3">View ministries, activities & rosters</span>
      </div>
      <div class="col-12 md:col-4 mb-4 px-5">
        <Button class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
          <router-link :to="{name: 'activityAttendance'}">
            <i class="fas fa-calendar text-4xl blue-fg"/>
          </router-link>
        </Button>
        <div class="text-900 mb-3 font-medium">Activity Attendance</div>
        <span class="text-700 text-sm line-height-3">Record attendance for an activity</span>
      </div>
      <div class="col-12 md:col-4 mb-4 px-5">
        <Button class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
          <router-link :to="{name: 'serviceAttendance'}">
            <i class="fas fa-globe text-4xl blue-fg"/>
          </router-link>
        </Button>
        <div class="text-900 mb-3 font-medium">Service Attendance</div>
        <span class="text-700 text-sm line-height-3">Record attendance for a service</span>
      </div>
      <div class="col-12 md:col-4 mb-4 px-5">
        <Button class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
          <router-link :to="{name: 'groupEmail'}">
            <i class="fas fa-envelope text-4xl blue-fg"/>
          </router-link>
        </Button>
        <div class="text-900 mb-3 font-medium">Communication</div>
        <span class="text-700 text-sm line-height-3">Send an email to a group</span>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser } from '@fortawesome/free-solid-svg-icons'

library.add(faUser)
export default {
  name: "LandingPage"
};
</script>

<style scoped>

</style>
