<template>
  <span>
    <Dialog
      :closable="false"
      :contentStyle="{ overflow: 'visible' }"
      :modal="true"
      :style="{ width: '50vw' }"
      :visible.sync="display"
    >
      <template #header>
        Save Attendance for {{ attendance.roster.name }} on {{ fixDate(attendance.calendar.eventDate) }}
      </template>
      <div class="grid p-fluid">
        <div class="col-12" style="padding: 0.5em">
          <PickList v-model="picklistValues">
            <template #sourceheader> All Members </template>
            <template #targetheader> Members Present </template>
            <template #item="slotProps">
              <div>
                <div>
                  <span><IndividualNameDisplay :value="slotProps.item" /></span>
                </div>
              </div>
            </template>
          </PickList>
        </div>
      </div>
      <template #footer>
        <div>
          <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="click('cancel')" />
          <Button autofocus icon="fas fa-check" label="Save" @click="click('save')" />
        </div>
      </template>
    </Dialog>
    <Toast />
  </span>
</template>

<script>
import RostersApi from "@/apis/RostersApi";
import { formatDate } from "@/functions";
import AttendanceApi from "@/apis/AttendanceApi";

export default {
  name: "ActivityAttendanceDialog",
  props: ["display", "value"],
  emits: ["add-attendance", "cancel-attendance"],
  data() {
    return {
      attendance: null,
      action: null,
      picklistValues: [[], []],
      rostersApi: null,
      attendanceApi: null,
      attendanceValues: null,
    };
  },
  watch: {
    attendance: function () {
      const attendanceId = this.attendance.id;
      const self = this;
      this.rostersApi.getRosterMembers(this.attendance.roster.id, (rosterData) => {
        this.attendanceApi.getAttendance(this.attendance.id, (attendanceData) => {
          let attendees = attendanceData.map((individ) => {
            return { individual: individ };
          });

          let attendanceIds = attendanceData.map((i) => i.id);

          let members = rosterData.filter((r) => !attendanceIds.includes(r.individual.id));
          this.picklistValues = [members, attendees];
        });
      });
    },
  },
  updated() {
    this.attendance = this.value;
  },
  created() {
    this.rostersApi = new RostersApi();
    this.attendanceApi = new AttendanceApi();
  },
  mounted() {},
  methods: {
    click(operation) {
      //   if (operation == 'save' || operation == 'add') {
      //     const errors = validate(this.service, validations.activityAttendance)
      //     if (errors) {
      //       errors.forEach(element => {
      //          this.$toast.add({severity:element.severity, summary: element.content,  life: 3000});
      //      })
      //       return
      //     }
      //   }
      this.attendanceValues = this.picklistValues[1];
      this.picklistValues = [[], []];
      this.$emit(operation + "-attendance", this.attendance, this.attendanceValues);
      //this.attendance = null;
    },
    fixDate(date) {
      return formatDate(date);
    },
  },
};
</script>

<style>
.p-picklist-source-controls,
.p-picklist-target-controls {
  display: none;
}
</style>
