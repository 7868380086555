<template>
  <div>
    <ProgressSpinner v-if="!individual" />
    <span v-if="individual && household">
      <div>
        <h1 v-if="editMode" v-once>Edit {{ this.outputName() }}</h1>
        <h1 v-else v-once>Add Member to {{ household.lastName }} Household</h1>
      </div>

      <div class="grid p-fluid">
        <div class="col-12">
          <div class="grid">
            <div class="col-2">
              <div class="box box-stretched">
                <img
                  v-tooltip.top="'Click to upload a new image'"
                  :src="individual.photo"
                  alt="profile pic"
                  style="width: 100%"
                  @click="$refs.file.click()"
                />
                <input ref="file" name="name" style="display: none" type="file" @change="onFileChanged" />
              </div>
            </div>
            <div class="col-8">
              <div class="grid">
                <div class="col-2" style="padding: 0.5em">
                  <custom-label v-bind:label="'Prefix'" v-bind:required="false" />
                  <Dropdown v-model="individual.prefix" :options="prefixes" optionLabel="label" optionValue="value" />
                </div>
                <div class="col-4" style="padding: 0.5em">
                  <custom-label v-bind:label="'First Name'" v-bind:required="true" />
                  <InputText id="firstName" v-model="individual.firstName" type="text" />
                </div>
                <div class="col-2" style="padding: 0.5em">
                  <custom-label v-bind:label="'Middle Name'" v-bind:required="false" />
                  <InputText id="middleName" v-model="individual.middleName" type="text" />
                </div>
                <div class="col-4" style="padding: 0.5em">
                  <custom-label v-bind:label="'Last Name'" v-bind:required="true" />
                  <InputText id="lastName" v-model="individual.lastName" type="text" />
                </div>
                <div class="col-5" style="padding: 0.5em">
                  <custom-label v-bind:label="'Email'" v-bind:required="false" />
                  <InputText id="email" v-model="individual.email" type="text" />
                </div>
                <div class="col-4" style="padding: 0.5em">
                  <custom-label v-bind:label="'Birth Date'" v-bind:required="false" />
                  <Calendar
                    :key="individual.id"
                    v-model="individual.dateOfBirth"
                    format="yy-mm-dd"
                    input-class="p-inputtext p-component p-filled"
                    :yearNavigator="true"
                    :yearRange="yearRange"
                    zone="UTC"
                  >
                    <template #button-cancel>
                      <Button class="p-button-text" icon="fas fa-times" label="Cancel" />
                    </template>
                    <template #button-confirm>
                      <Button autofocus icon="fas fa-check" label="OK" />
                    </template>
                  </Calendar>
                </div>
                <div class="col-3" style="padding: 0.5em">
                  <custom-label v-bind:label="'Gender'" v-bind:required="true" />
                  <Dropdown v-model="individual.gender" :options="genders" optionLabel="label" optionValue="value"> </Dropdown>
                </div>
                <div class="col-3" style="padding: 0.5em">
                  <custom-label v-bind:label="'Marital Status'" v-bind:required="false" />
                  <Dropdown v-model="individual.maritalStatus" :options="maritalStatuses" optionLabel="label" optionValue="value">
                  </Dropdown>
                </div>
                <div class="col-3" style="padding: 0.5em">
                  <custom-label v-bind:label="'Membership Status'" v-bind:required="false" />
                  <Dropdown v-model="individual.status" :options="membershipStatuses" optionLabel="label" optionValue="value" />
                </div>
                <div class="col-6" style="padding: 0.5em">
                  <custom-label v-bind:label="'Job Title'" v-bind:required="false" />
                  <InputText id="jobTitle" v-model="individual.jobTitle" type="text" />
                </div>
                <div class="col-6" style="padding: 0.5em">
                  Phone Numbers
                  <Button
                    v-tooltip.top="'Add Phone Number'"
                    class="p-button-rounded p-button-text"
                    icon="fas fa-plus"
                    @click="clickAddNumber()"
                  />
                  <div v-for="number in individual.phoneNumbers" :key="number.id" class="grid">
                    <div class="col-5">
                      <PhoneNumber v-model="number.number" />
                    </div>
                    <div class="col-4">
                      <Dropdown v-model="number.type" :options="phoneTypes" optionLabel="label" optionValue="value" />
                    </div>
                    <div class="col-3" style="padding: 0.5em">
                      <Checkbox
                        v-model="number.preferred"
                        v-tooltip.top="'Preferred'"
                        :binary="true"
                        @change="clickPreferred(number)"
                      />
                      &nbsp;&nbsp;
                      <Button v-tooltip.top="'Delete'" icon="fas fa-trash" @click="clickDeletePhone(number)" />
                    </div>
                  </div>
                </div>
                <div class="col-6" style="padding: 0.5em">
                  Social Media
                  <Button
                    v-tooltip.top="'Add Social Media Account'"
                    class="p-button-rounded p-button-text"
                    icon="fas fa-plus"
                    @click="clickAddSocialMediaAccount()"
                  />
                  <div v-for="account in individual.socialMediaAccounts" :key="account.id" class="grid">
                    <div class="col-5">
                      <InputText v-model="account.description" placeholder="Description" />
                    </div>
                    <div class="col-5">
                      <InputText v-model="account.link" placeholder="Link" />
                    </div>
                    <div class="col-2">
                      <Button v-tooltip.top="'Add'" icon="fas fa-trash" @click="clickDeleteSocialMediaAccount(account)" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- here -->
            </div>
          </div>
        </div>
      </div>
      <!-- grid -->

      <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="clickCancel" />
      <Button autofocus icon="fas fa-check" label="Save" @click="clickSave(individual)" />
      <Toast />

      <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
    </span>
  </div>
</template>

<script>
import IndividualsApi from "@/apis/IndividualsApi";
import HouseholdApi from "@/apis/HouseholdApi";
import {validate, validations} from "@/validation";
import {PhoneNumberModel} from "@/models/PhoneNumberModel";
import {SocialMediaModel} from "@/models/SocialMediaModel";
import {IndividualModel} from "@/models/IndividualModel";
import {genders, maritalStatuses, membershipStatuses, personalPrefixes, phoneTypes} from "@/constants";

export default {
  name: "ManageIndividual",
  data() {
    return {
      individualId: null,
      individual: null,
      household: null,
      individualsApi: null,
      householdsApi: null,
      dialogHeader: null,
      displayProgressDialog: false,
      householdId: null,
      editMode: true,
      //newPhoneNumber: null,
      genders: genders,
      maritalStatuses: maritalStatuses,
      membershipStatuses: membershipStatuses,
      phoneTypes: phoneTypes,
      prefixes: personalPrefixes,
    };
  },
  created() {
    this.individualId = this.$route.query.id;
    this.householdId = this.$route.query.householdId;
    this.individualsApi = new IndividualsApi();
    this.householdsApi = new HouseholdApi();

    if (!this.individualId) {
      this.editMode = false;
      this.individual = new IndividualModel();
      this.loadHousehold(this.householdId);
      //this.individual.lastName = this.household.lastName
    } else {
      this.editMode = true;
      this.individualsApi.getIndividual(this.individualId, (data) => {
        this.individual = data;
        this.loadHousehold(data.householdId);
      });
    }
  },
  computed: {
    yearRange: function () {
      return "1900:" + new Date().getFullYear();
    },
  },
  mounted() {
    if (this.editMode) {
      this.individualsApi.getIndividual(this.individualId, (data) => {
        this.individual = data;
        this.loadHousehold(data.householdId);
      });
    } else {
      this.loadHousehold(this.householdId);
      //this.individual.lastName = this.household.lastName
    }
  },
  methods: {
    loadHousehold: function (householdId) {
      this.householdsApi.getHousehold(householdId, (household) => this.onHouseholdLoad(household));
    },
    onHouseholdLoad: function (household) {
      this.household = household;
      if (!this.editMode) {
        this.individual.lastName = this.household.lastName;
      }
    },
    // getLastName: function () {
    //   return this.individual.lastName !== null ? (
    //       this.individual.lastName
    //   ) : (
    //       this.individual.household.lastName
    //   )
    // },
    onFileChanged(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.individual.photo = e.target.result;
      };
    },
    clickCancel() {
      if (this.editMode) {
        this.$router.push("/individual?id=" + this.individual.id);
      } else {
        this.$router.push("/household?id=" + this.householdId);
      }
    },
    clickSave(individual) {
      if (!this.editMode) {
        individual.household = this.household;
        individual.householdId = this.householdId;
        this.dialogHeader = "Adding Individual";
      } else {
        this.dialogHeader = "Updating Individual";
      }
      const errors = validate(individual, validations.individual);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.displayProgressDialog = true;
      if (this.isLastNumberBlank()) {
        this.individual.phoneNumbers.pop();
      }
      if (this.isLastAccountBlank()) {
        this.individual.socialMediaAccounts.pop();
      }
      //this.newPhoneNumber = new PhoneNumberModel();
      this.newAccount = new SocialMediaModel();
      if (!this.editMode) {
        this.individualsApi.addIndividual(individual, (data) => {
          data.household = this.household;
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/household?id=" + this.householdId);
        });
      } else {
        this.individualsApi.updateIndividual(individual, () => {
          this.loadHousehold(individual.householdId);
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/individual?id=" + individual.id);
        });
      }
    },
    isLastNumberBlank() {
      if (this.individual.phoneNumbers.length === 0) {
        return false;
      }
      let lastNumber = this.individual.phoneNumbers[this.individual.phoneNumbers.length - 1];
      return lastNumber.number === "";
    },
    isLastAccountBlank() {
      if (this.individual.socialMediaAccounts.length === 0) {
        return false;
      }
      let lastAccount = this.individual.socialMediaAccounts[this.individual.socialMediaAccounts.length - 1];
      return lastAccount.description === "" || lastAccount.description == null;
    },
    clickPreferred(number) {
      this.individual.phoneNumbers.forEach((n) => (n.preferred = n.id === number.id));
      //this.newPhoneNumber.preferred = false;
    },
    clickAddNumber() {
      if (!this.isLastNumberBlank()) {
        this.individual.phoneNumbers.push(new PhoneNumberModel());
      }
    },
    clickDeletePhone(number) {
      this.individual.phoneNumbers = this.individual.phoneNumbers.slice().filter((n) => n.id !== number.id);
    },
    clickDeleteSocialMediaAccount(account) {
      this.individual.socialMediaAccounts = this.individual.socialMediaAccounts.slice().filter((n) => n.id !== account.id);
    },
    clickAddSocialMediaAccount(account) {
      if (!this.isLastAccountBlank()) {
        this.individual.socialMediaAccounts.push(new SocialMediaModel());
      }
    },
    outputName() {
      if (this.individual.firstName && this.individual.lastName) {
        return this.individual.firstName + " " + this.individual.lastName;
      }
    },
  },
};
</script>
