<template>
  <div>
    <Card style="width: 35rem; margin-bottom: 2em">
      <template #title> Individuals Search </template>
      <template #content>
        <div class="grid p-fluid">
          <div class="field col-12">
            <div class="grid p-fluid">
              <div class="field col-6">
                <custom-label v-bind:label="'First Name'" />
                <InputText id="firstName" v-model="firstName" type="text" />
              </div>
              <div class="field col-6">
                <custom-label v-bind:label="'Last Name'" />
                <InputText id="lastName" v-model="lastName" type="text" />
              </div>
              <div class="field col-6">
                <custom-label v-bind:label="'Address'" />
                <InputText id="address" v-model="address" type="text" />
              </div>
              <div class="field col-6">
                <custom-label v-bind:label="'City'" />
                <InputText id="city" v-model="city" type="text" />
              </div>
              <div class="field col-6">
                <custom-label v-bind:label="'Zip'" />
                <InputText id="zip" v-model="zip" type="text" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <Button label="Search" @click="searchIndividuals" />
        &nbsp;
        <Button label="View All Individuals" @click="viewAllIndividuals" />
        <!--    <router-link :to="{path: '/individuals' }" tag="button">View using Router Link</router-link>-->
      </template>
    </Card>
  </div>
</template>

<script>
export default {
  name: "IndividualSearch",
  data() {
    return {
      lastName: null,
      firstName: null,
      address: null,
      city: null,
      zip: null,
    };
  },
  methods: {
    searchIndividuals: function () {
      this.$router.push({
        name: "individuals",
        params: {
          firstName: this.firstName,
          lastName: this.lastName,
          address: this.address,
          city: this.city,
          zip: this.zip,
          match: "all",
        },
      });
    },
    viewAllIndividuals: function () {
      this.$router.push("/individuals");
    },
  },
};
</script>

<style scoped></style>
