<template>
  <div id="app">
    <nav-bar :key="$route.fullPath"/>
    <div style="display: flex">
      <div>
        <side-bar :key="$route.fullPath"/> <!-- v-if="$store.state.authenticated" / -->
      </div>
      <div style="flex-grow: 1">
        <main style="padding: 1pc">
          <router-view :key="$route.fullPath" />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import SideBar from "@/components/SideBar";
import {setMessages} from "@/validation";

import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/nova/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

export default {
  name: "app",
  components: {
    NavBar,
    SideBar,
  },
  data() {
    return {
      //messages: null
    };
  },
  computed: {
    // messages: function (){
    //   return globalStore.messages
    // }
  },
  updated() {
    setMessages();
  },
};
</script>

<style>

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

padded {
  padding: 2%;
}

nav {
  padding: 20px 20px 20px 0;
}

nav a {
  padding: 10px;
  text-decoration: none;
  background: #fff;
  border-radius: 3px;
  color: rgb(0, 110, 255);
  font-weight: bold;
  margin-right: 15px;
}

.p-button {
  margin-right: 5px;
}
</style>
