<template>
  <span>
    <ProgressSpinner v-if="!locations" />

    <DataTable v-if="locations" :value="locations" emptyMessage="No Locations Found" style="width: 90%">
      <template #header>
        <div class="p-fluid formgrid grid align-items-end vertical-container">
          <div class="field col-12 md:col-8">Locations</div>
          <div class="field col-12 md:col-4">
            <Button icon="fas fa-plus" label="Add Location" @click="addNewLocation" />
          </div>
        </div>
      </template>
      <Column field="name" header="Location Name" headerStyle="textAlign: left" />

      <Column header="Actions" headerStyle="width: 25%; textAlign: center" style="text-align: left">
        <template #body="slotProps">
          <Button
            v-tooltip.top="'Edit ' + slotProps.data.name"
            class="p-button-rounded p-button-text"
            icon="fas fa-pencil"
            @click="onLocationSelect(slotProps.data)"
          />
          <Button
            v-tooltip.top="'Delete ' + slotProps.data.name"
            class="p-button-rounded p-button-text"
            icon="fas fa-trash"
            @click="clickTrash(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <confirmation-dialog
      v-bind:display="displayDelConfirmation"
      v-bind:message="deleteMessage"
      v-bind:type="'Delete Location'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmDelete"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
  </span>
</template>

<script>
import LocationsApi from "@/apis/LocationsApi";
import { LocationModel } from "@/models/LocationModel";

export default {
  data() {
    return {
      locations: null,
      location: new LocationModel(),
      locationToDelete: [],
      displayDelConfirmation: false,
      displayProgressDialog: false,
      dialogHeader: null,
      deleteMessage: null,
    };
  },
  locationsApi: null,
  created() {
    this.locationsApi = new LocationsApi();
  },
  mounted() {
    this.locationsApi.getLocationsList((data) => (this.locations = data));
  },
  methods: {
    onLocationSelect(data) {
      this.$router.push("/location/edit?locationId=" + data.id);
    },
    addNewLocation() {
      this.$router.push("/location/add");
    },

    clickDeleteLocation(location) {
      this.dialogHeader = "Deleting Location";
      this.displayProgressDialog = true;
      this.locationsApi.deleteLocation(location.id, () => {
        this.locations = this.locations.slice().filter((n) => n.id !== location.id);
        this.location = new LocationModel();
        this.displayLocationDialog = false;
        this.displayProgressDialog = false;
        this.dialogHeader = null;
      });
    },
    clickTrash(location) {
      this.locationToDelete = location;
      this.deleteMessage = "Are you sure you want to delete " + location.name + "?";
      this.displayDelConfirmation = true;
    },
    confirmCancel() {
      this.displayDelConfirmation = false;
    },
    confirmDelete() {
      this.clickDeleteLocation(this.locationToDelete);
      this.displayDelConfirmation = false;
      this.locationToDelete = [];
    },
  },
};
</script>
