<!--suppress XmlInvalidId -->
<template>
  <div class="p-fluid">
    <div class="field grid">
      <h1>Billing Information</h1>
    </div>
    <div class="field grid">
      <div class="field col-12">
        <label for="billing_name"> Billing Name </label>
        <InputText id="billing_name" type="text" v-model="signup.billingName" />
      </div>
    </div>
    <div class="field grid">
      <div class="field col-12">
        <label for="billing_address1"> Address 1 </label>
        <InputText id="billing_address1" type="text" v-model="signup.billingAddress1" />
      </div>
    </div>
    <div class="field grid">
      <div class="field col-12">
        <label for="billing_address2"> Address 2 </label>
        <InputText id="billing_address2" type="text" v-model="signup.billingAddress2" />
      </div>
    </div>
    <div class="field grid">
      <div class="field col-12 md:col-6">
        <label for="billing_city">City</label>
        <InputText id="billing_city" type="text" v-model="signup.billingCity" />
      </div>
      <div class="field col-12 md:col-3">
        <label for="billing_state">State</label>
        <Dropdown id="billing_states" :options="states" optionLabel="label" optionValue="value" v-model="signup.billingState" />
      </div>
      <div class="field col-12 md:col-3">
        <label for="billing_zip">Zip</label>
        <InputText id="billing_zip" type="text" v-model="signup.billingZipcode" />
      </div>
    </div>
    <div class="field grid">
      <div class="field col-12 md:col-6">
        <label for="billing_cc">Credit Card Number</label>
        <InputText id="billing_cc" type="text" v-model="signup.creditCardNumber" />
      </div>
      <div class="field col-12 md:col-3">
        <label for="billing_cvv2">CVV2</label>
        <InputText id="billing_cvv2" type="text" v-model="signup.cvv2" />
      </div>
      <div class="field col-12 md:col-3">
        <label for="billing_expiration">Expiration</label>
        <InputText id="billing_expiration" type="text" v-model="signup.expirationDate" />
      </div>
    </div>
    <div class="field grid">
      <div class="field col-12 md:col-3">
        <label for="billing_subType">Subscription Type</label>
        <Dropdown id="billing_subType" :options="subTypes" optionLabel="label" optionValue="value" v-model="signup.subscriptionType" />
      </div>
    </div>
  </div>
</template>

<script>
import { states } from "@/constants";

export default {
  name: "BillingInfoForm",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  data() {
    return {
      signup: null,
      states: states,
      subTypes: [
        { label: "Monthly", value: "MONTHLY" },
        { label: "Yearly", value: "YEARLY" },
      ],
    };
  },
  mounted() {
    this.signup = this.modelValue;
  },
  created() {
    this.signup = this.modelValue;
  },
  updated() {
    this.signup = this.modelValue;
  },
};
</script>

<style scoped>
.field {
  margin-bottom: 0.25rem;
}
</style>
