<template>
  <span>
    <DataTable :value="displayServices" emptyMessage="No Services Found" style="width: 75%">
      <template #empty> No Services Found </template>
      <template #header>
        <div class="p-fluid formgrid grid align-items-end vertical-container">
          <div class="field col-12 md:col-12">Edit Past Service Attendance</div>
          <div class="field col-12 md:col-3">
            <custom-label v-bind:label="'Start Date'" v-bind:required="true" />
            <Calendar v-model="startDate" dateFormat="yy-mm-dd" input-class="p-inputtext p-component p-filled">
              <template #button-cancel>
                <Button class="p-button-text" icon="fas fa-times" label="Cancel" />
              </template>
              <template #button-confirm>
                <Button autofocus icon="fas fa-check" label="OK" />
              </template>
            </Calendar>
          </div>
          <div class="field col-12 md:col-3">
            <custom-label v-bind:label="'End Date'" v-bind:required="false" />
            <Calendar v-model="endDate" dateFormat="yy-mm-dd" input-class="p-inputtext p-component p-filled">
              <template #button-cancel>
                <Button class="p-button-text" icon="fas fa-times" label="Cancel" />
              </template>
              <template #button-confirm>
                <Button autofocus icon="fas fa-check" label="OK" />
              </template>
            </Calendar>
          </div>
          <div class="field col-12 md:col-3">
            <Button label="Search" @click="searchAttendance" />
          </div>
        </div>
      </template>

      <Column :sortable="true" field="service.name" header="Service Name" headerStyle="text-align: left" />
      <Column :sortable="true" header="Date" headerStyle="text-align: left">
        <template #body="slotProps">
          {{ fixDate(slotProps.data.calendar.eventDate) }}
        </template>
      </Column>
      <Column :sortable="true" header="Attendance" field="totalAttendance" headerStyle="text-align: left" />
      <Column header="Actions" headerStyle="width: 8em; text-align: left" style="text-align: center">
        <template #body="slotProps">
          <Button
            v-tooltip.top="'Edit Attendance'"
            class="p-button-rounded p-button-text"
            icon="fas fa-pencil"
            @click="editAttendance(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <service-attendance-dialog
      v-model="service"
      v-bind:display="displayAttendanceDialog"
      @add-attendance="clickEditAttendance"
      @cancel-attendance="clickCancelAttendance"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />

    <confirmation-dialog
      v-bind:display="displayConfirmation"
      v-bind:message="confirmationMessage"
      v-bind:type="'Mark Attendance Complete'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmComplete"
    />

    <Toast />
  </span>
</template>

<script>
import AttendanceApi from "@/apis/AttendanceApi";
import {AttendanceInputModel} from "@/models/AttendanceInputModel";
import {formatDate} from "@/functions";
import {validate, validations} from "@/validation";

export default {
  data() {
    return {
      pendingServices: null,
      dialogHeader: null,
      displayProgressDialog: false,
      attendanceRecord: null,
      displayAttendanceDialog: false,
      service: null,
      displayConfirmation: false,
      confirmationMessage: null,
      serviceToComplete: null,
      displayServices: null,
      allServices: null,
      searchValues: [],
      startDate: null,
      endDate: null,
    };
  },
  created() {
    this.attendanceApi = new AttendanceApi();
  },
  mounted() {
    this.getPendingServices();
  },

  methods: {
    sendComplete(service) {
      this.dialogHeader = "Marking Service Complete";
      this.displayProgressDialog = true;
      this.attendanceRecord = new AttendanceInputModel();
      this.attendanceRecord.attendanceId = service.id;
      this.attendanceRecord.completed = true;
      service.completed = true;
      this.attendanceApi.updateAttendance(this.attendanceRecord, () => {
        this.getPendingServices();
        this.dialogHeader = null;
        this.displayProgressDialog = false;
      });
    },
    editAttendance(data) {
      this.service = { ...data };
      //this.service.totalAttendance = null
      this.displayAttendanceDialog = true;
    },
    clickEditAttendance(service) {
      this.dialogHeader = "Updating Attendance";
      this.displayProgressDialog = true;
      this.attendanceRecord = new AttendanceInputModel();
      this.attendanceRecord.attendanceId = service.id;
      this.attendanceRecord.totalAttendance = service.totalAttendance;
      this.attendanceRecord.completed = true;
      service.completed = true;
      this.attendanceApi.updateAttendance(this.attendanceRecord, () => {
        this.searchAttendance();
        this.dialogHeader = null;
        this.displayProgressDialog = false;
        this.displayAttendanceDialog = false;
      });
    },

    clickCancelAttendance() {
      this.displayAttendanceDialog = false;
    },
    fixDate(date) {
      return formatDate(date);
    },
    getPendingServices() {
      this.attendanceApi.getPendingServices((data) => {
        this.pendingServices = data;
      });
    },
    confirmCancel() {
      this.displayConfirmation = false;
      this.serviceToComplete = null;
    },
    confirmComplete() {
      this.sendComplete(this.serviceToComplete);
      this.displayConfirmation = false;
      this.confirmationMessage = null;
    },
    searchAttendance() {
      this.searchValues.startDate = this.startDate;
      const errors = validate(this.searchValues, validations.searchServiceAttendance);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.attendanceApi.searchServices(this.startDate, this.endDate, (data) => {
        this.allServices = data;
        this.displayServices = this.allServices;
      });
    },
  },
};
</script>

<style scoped></style>
