<template>
  <div>
    <ProgressSpinner v-if="!individualAttendance" />
    <DataTable v-if="individualAttendance" sortfield="eventDate" :sortOrder="-1" :value="individualAttendance.attendance">
      <template #empty> No Attendance Records Found </template>
      <template #header>
        <div class="p-fluid formgrid grid align-content-center align-items-end vertical-container">
          <div class="field col-12 sm:col-9">
            Attendance Records for
            {{ individualAttendance.individual.firstName }}
            {{ individualAttendance.individual.lastName }}
          </div>
          <div class="field col-12 sm:col-3 align-content-center"></div>
        </div>
        <!-- <div class="p-clearfix" style="line-height:1.87em">Households</div> -->
      </template>
      <Column field="roster.name" filterMatchMode="contains" :sortable="true" header="Roster Name"> </Column>
      <Column field="eventDate" filterMatchMode="contains" dataType="date" :sortable="true" header="Date">
        <template #body="slotProps">
          {{ formatDate(slotProps.data.calendar.eventDate) }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import IndividualsApi from "@/apis/IndividualsApi";
import {formatDate} from "@/functions";

export default {
  name: "IndividualAttendance",
  data() {
    return {
      individualId: null,
      individualAttendance: null,
      individualsApi: null,
      dialogHeader: null,
      displayProgressDialog: false,
    };
  },
  created() {
    this.individualId = this.$route.query.id;
    // if (!this.individualId) {
    // }
    this.individualsApi = new IndividualsApi();
  },
  mounted() {
    this.individualsApi.getIndividualAttendance(this.individualId, (data) => {
      this.individualAttendance = data;
    });
  },
  methods: {
    formatDate: formatDate,
  },
};
</script>
