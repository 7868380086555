<template>
  <span>
    <ProgressSpinner v-if="!ministryInfo" />

    <DataTable v-if="ministryInfo" :value="activities" emptyMessage="No Services Found">
      <template #empty>
        <div v-if="loadingActivities">
          <ProgressSpinner />
        </div>
        <div v-else>No Activities found</div>
      </template>
      <template #header>
        <div class="p-fluid formgrid grid align-content-center align-items-end vertical-container">
          <div class="field col-12 md:col-4">
            Activities
            <p></p>
            <custom-label v-bind:label="'Ministry'" v-bind:required="false" />
            <Dropdown
              v-model="ministryId"
              :disabled="loadingActivities"
              :options="ministryInfo"
              optionLabel="name"
              optionValue="id"
              placeholder="Select a Ministry"
              @change="ministryChanged"
            />
          </div>
          <div class="field col-12 md:col-4" />
          <div v-if="activities" class="field col-12 md:col-4">
            <Button icon="fas fa-plus" label="Add Activity" @click="addNewActivity" />
          </div>
        </div>
      </template>
      <Column field="name" header="Activity Name" headerStyle="text-align: left" />
      <Column field="description" header="Description" headerStyle="text-align: left" />
      <Column header="Recurs" headerStyle="width: 12em; text-align: left">
        <template #body="slotProps">
          {{ formattedRecurs(slotProps.data) }}
        </template>
      </Column>
      <Column header="Time" headerStyle="width: 6em; text-align: left">
        <template #body="slotProps">
          <span style="white-space: nowrap">{{ formatTime(slotProps.data.activityTime) }}</span>
        </template>
      </Column>
      <Column header="Start Date" headerStyle="width: 8em; text-align: left">
        <template #body="slotProps">
          {{ formatDate(slotProps.data.startDate) }}
        </template>
      </Column>
      <!-- <Column field="endDate" header="End Date" headerStyle="width: 7em; text-align: left"/> -->
      <!--<Column field="rosterCount" header="# of Rosters" headerStyle="width: 5em; textAlign: left"> </Column> -->
      <Column header="Actions" headerStyle="width: 22%; text-align: left">
        <template #body="slotProps">
          <div class="grid">
            <Button
              v-tooltip.top="'Edit Activity'"
              class="p-button-rounded p-button-text"
              icon="fas fa-pencil"
              type="button"
              @click="onActivitySelect(slotProps.data)"
            />
            <Button
              v-tooltip.top="'Delete Activity'"
              class="p-button-rounded p-button-text"
              icon="fas fa-trash"
              @click="clickTrash(slotProps.data)"
            />
            <Button
              v-tooltip.top="'Add Roster to ' + slotProps.data.name"
              class="p-button-rounded p-button-text"
              icon="fas fa-plus"
              @click="addNewRoster(slotProps.data)"
            />
            <Button
              v-tooltip.top="'View Rosters'"
              class="p-button-rounded p-button-text"
              icon="fas fa-list"
              @click="viewRosters(slotProps.data.id)"
            />
            <Button
              v-if="slotProps.data.supportsPromotion == true"
              v-tooltip.top="'Manage Promotion Order'"
              class="p-button-rounded p-button-text"
              icon="fas fa-chart-line"
              type="button"
              @click="managePromotionChain(slotProps.data.id)"
            />
            <Button
              v-if="slotProps.data.supportsPromotion"
              v-tooltip.top="'Promote Rosters'"
              class="p-button-rounded p-button-text"
              icon="fas fa-arrow-circle-up"
              @click="promoteRosters(slotProps.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>

    <confirmation-dialog
      v-bind:display="displayDelConfirmation"
      v-bind:message="deleteMessage"
      v-bind:type="'Delete Activity'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmDelete"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />

    <ConfirmDialog></ConfirmDialog>
  </span>
</template>

<script>
import ActivitiesApi from "@/apis/ActivitiesApi";
import LocationsApi from "@/apis/LocationsApi";
import MinistriesApi from "@/apis/MinistriesApi"; // import RosterDialog from "@/components/rosters/RosterDialog";
import RostersApi from "@/apis/RostersApi";
import { ActivityModel } from "@/models/ActivityModel";
import { RosterModel } from "@/models/RosterModel";
import { formatDate, formatRecurs, formatTime, responseToOptions } from "@/functions";
import { recurrenceOptions } from "@/constants";

export default {
  data() {
    return {
      activities: null,
      activitiesApi: null,
      activity: new ActivityModel(),
      activityToDelete: [],
      deleteMessage: null,
      dialogHeader: null,
      displayActivityDialog: false,
      displayDelConfirmation: false,
      displayProgressDialog: false,
      loadingActivities: false,
      locationOptions: null,
      locationsApi: null,
      ministriesApi: null,
      ministryId: null,
      ministryInfo: null,
      ministryOptions: null,
      recurs: recurrenceOptions,
      roster: new RosterModel(),
      rosterAction: null,
      rostersApi: null,
    };
  },
  created() {
    this.activitiesApi = new ActivitiesApi();
    this.ministriesApi = new MinistriesApi();
    this.locationsApi = new LocationsApi();
    this.rostersApi = new RostersApi();

    this.ministriesApi.getFullMinistryInfo((data) => {
      this.ministryInfo = data;
    });

    if (this.$route.query.ministryId) {
      this.ministryId = parseInt(this.$route.query.ministryId);
      this.getActivities();
    }
    this.ministriesApi.getFullMinistryInfo((data) => {
      this.ministryInfo = data;
    });
  },
  mounted() {
    this.locationsApi.getLocationsList((data) => {
      this.locations = data;
      this.locationOptions = responseToOptions(data);
    });
    console.log("mounted location options");
  },
  watch: {
    // ministryId() {
    //   this.loadingActivities = true;
    //   this.activitiesApi.getActivitiesList(this.ministryId, (data) => {
    //     this.activities = data;
    //     this.loadingActivities = false;
    //   });
    // },
  },

  methods: {
    ministryChanged() {
      this.getActivities();
      // this.loadingActivities = true;
      // this.activitiesApi.getActivitiesList(this.ministryId, (data) => {
      //   this.activities = data;
      //   this.loadingActivities = false;
      // });
    },
    getActivities() {
      this.loadingActivities = true;
      this.activitiesApi.getActivitiesList(this.ministryId, (data) => {
        this.activities = data;
        this.dropdownId = this.ministryId;
        this.loadingActivities = false;
      });
    },
    formattedRecurs(data) {
      return formatRecurs(data);
    },
    onActivitySelect(data) {
      this.$router.push("/activity/edit?activityId=" + data.id + "&ministryId=" + this.ministryId);
    },
    addNewRoster(data) {
      this.$router.push("/roster/add?activityId=" + data.id + "&ministryId=" + this.ministryId);
    },
    addNewActivity() {
      this.$router.push("/activity/add?ministryId=" + this.ministryId);
    },
    viewRosters(id) {
      this.$router.push("/rosters/view?activityId=" + id + "&ministryId=" + this.ministryId);
      //this.$router.push("/rosters/" + id + "/" + this.ministryId + "/view");
    },
    managePromotionChain: function (id) {
      this.$router.push("/activity/promotionChain?activityId=" + id);
    },
    promoteRosters(id) {
      this.$confirm.require({
        message: "Are you sure you want to promote the rosters in this activity?",
        header: "Confirmation",
        icon: "fas fa-exclamation-triangle",
        accept: () => {
          this.activitiesApi.promoteRosters(id, (data) => {
            console.log(data);
          });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    clickDeleteActivity(activity) {
      this.dialogHeader = "Deleting Activity";
      this.displayProgressDialog = true;
      this.activitiesApi.deleteActivity(activity.id, () => {
        this.activities = this.activities.slice().filter((n) => n.id !== activity.id);
        this.activity = new ActivityModel();
        this.displayActivityDialog = false;
        this.dialogHeader = null;
        this.displayProgressDialog = false;
      });
    },
    clickTrash(activity) {
      this.activityToDelete = activity;
      this.deleteMessage = "Are you sure you want to delete " + activity.name + "?";
      this.displayDelConfirmation = true;
    },
    confirmCancel() {
      this.displayDelConfirmation = false;
    },
    confirmDelete() {
      this.clickDeleteActivity(this.activityToDelete);
      this.displayDelConfirmation = false;
      this.activityToDelete = [];
    },
    formatTime: formatTime,
    formatDate: formatDate,
  },
};
</script>

<style scoped>
.p-dropdown {
  width: 100%;
}
</style>
