import { request } from "@/functions";

export default class RostersApi {
  getRosters(activityId, successCallback) {
    request("get", "/activities/" + activityId + "/rosters", null, successCallback);
  }

  getRosterMembers(rosterId, successCallback) {
    request("get", "rosters/" + rosterId + "/members", null, successCallback);
  }

  getRoster(rosterId, successCallback) {
    request("get", "rosters/" + rosterId, null, successCallback);
  }

  addRoster(roster, successCallback) {
    request("POST", "/rosters/", roster, successCallback);
  }

  updateRoster(roster, successCallback) {
    request("PUT", "/rosters/" + roster.id, roster, successCallback);
  }

  updateRosterMembers(rosterId, rosterMembers, successCallback) {
    request("PUT", "/rosters/" + rosterId + "/members", rosterMembers, successCallback);
  }

  deleteRoster(id, successCallback) {
    request("DELETE", "/rosters/" + id, null, successCallback);
  }

  sendRosterMessage(id, message, successCallback) {
    request("POST", "/rosters/" + id + "/messages", message, successCallback);
  }

  getCheckinSheet(id) {
    request("get", "/reports/roster/checkin/" + id, null, () => {},
      true, true, "Check-in Sheet.pdf");
  }
}
