<template>
  <span>
    {{ formatName() }}
  </span>
</template>

<script>
export default {
  props: ["value", "prefix"],
  methods: {
    formatName: function () {
      let obj = this.value.individual ? this.value.individual : this.value;
      if (obj) {
        let name = obj.firstName;
        if (obj.prefix) {
          name = obj.prefix + " " + name;
        }
        if (obj.middleName) {
          name += " " + obj.middleName;
        }
        if (obj.lastName) {
          name += " " + obj.lastName;
        } else {
          name += " " + obj.household.lastName;
        }
        console.log(name);
        return name;
      } else {
        return "";
      }
    },
  },
};
</script>
