import {parseJwt, request} from "@/functions";
import {store} from "@/store";

export default class AuthenticationApi {
    login(email, password, successCallback) {
        let wrapped = (token) => {
            parseJwt(token);
            localStorage.setItem("vue-token", token);
            store.commit("authenticated", true);
            successCallback(token)
        }
        request("post", "/login", { emailAddress: email, password: password }, wrapped);
    }
    logout() {
        localStorage.removeItem("vue-token");
        store.commit("authenticated", false);
    }
}
