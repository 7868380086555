<template>
  <span>
    <h1>Upcoming Events</h1>
    <ProgressSpinner v-if="!events" />
    <DataTable v-if="events" :value="events" emptyMessage="No Events Found" style="width: 75%">
      <template #empty> No Events Found </template>

      <Column header="Event" headerStyle="text-align: left">
        <template #body="slotProps">
          <span style="white-space: nowrap;">{{ slotProps.data.name }}</span>
        </template>
      </Column>
      <Column header="Location" headerStyle="text-align: left">
        <template #body="slotProps">
          <div v-if="slotProps.data.location">
            {{ slotProps.data.location.name }}
          </div>
        </template>
      </Column>
      <Column header="Service Time" headerStyle="text-align: left">
        <template #body="slotProps">
          <span style="white-space: nowrap;">{{ formatDateTime(slotProps.data.eventDate) }}</span>
        </template>
      </Column>
      <Column header="Actions" headerStyle="width: 15%; text-align: center" style="text-align: left">
        <template #body="slotProps">
          <Button
            v-if="slotProps.data.activityId"
            v-tooltip.top="'Print check-in sheet'"
            class="p-button-rounded p-button-text"
            icon="fas fa-print"
            @click="openReport(slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>

  </span>
</template>

<script>
import CalendarApi from "@/apis/CalendarApi";
import RostersApi from "@/apis/RostersApi";
import { format, parseISO } from "date-fns";

export default {
  name: "UpcomingEventsReport",
  data() {
    return {
      events: null,
      calendarApi: null,
      rostersApi: null,
    }
  },
  created() {
    this.calendarApi = new CalendarApi();
    this.rostersApi = new RostersApi();
  },
  mounted() {
    this.calendarApi.getUpcomingEvents((data) => {
      this.events = data;
    })
  },
  methods: {
    formatDateTime(data) {
      return format(parseISO(data), "EEE, LLLL c, yyyy 'at' h:mm a");
    },
    openReport(id) {
      this.rostersApi.getCheckinSheet(id);
    }
  }
};
</script>

<style scoped>

</style>