<template>
  <div>
    <ProgressSpinner v-if="!chain" />
    <span v-if="chain">
      <Card style="width: 40rem; margin-bottom: 2em">
        <template #title>
          <div v-if="!editMode">Manage Promotion Order</div>
        </template>
        <template #content>
          <PickList v-model="picklistValues">

            <template #item="slotProps">
              {{ slotProps.item.name }}
            </template>
          </PickList>
        </template>
        <template #footer>
          <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="clickCancel" />
          <Button autofocus icon="fas fa-check" label="Save" @click="clickSave(group)" />
        </template>
      </Card>
      <Toast />

      <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
    </span>
  </div>
</template>

<script>
import ActivitiesApi from "@/apis/ActivitiesApi";
import RostersApi from "@/apis/RostersApi";

export default {
  name: "ManagePromotionOrder",
  data() {
    return {
      chain: null,
      activityId: null,
      activitiesApi: null,
      rostersApi: null,
      picklistValues: [[],[]]
    }
  },
  created() {
    this.activitiesApi = new ActivitiesApi();
    this.rostersApi = new RostersApi();
    this.activityId = this.$route.query.activityId;
    if (this.activityId) {
      this.loadRosters();
    }
  },
  methods: {
    loadRosters: function() {
      this.activitiesApi.getRosters(this.activityId, (rosters) => {
        this.rosters = rosters
        this.loadPromotionChain();
      })
    },
    loadPromotionChain() {
      let self = this;
      this.activitiesApi.getPromotionChain(this.activityId, (chain) => {
        this.chain = chain
        this.populateLists();
      })
    },
    populateLists() {
      // let available = this.rosters.filter(e => this.chain.includes(e))

      let available = new Array();
      this.rosters.forEach((i) => {
        if (!this.chain.find((element) => {return element.id === i.id})) {
          available.push(i)
        }
      });

      this.picklistValues = [available, this.chain];
      console.log("hi");
    },
    clickCancel() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>