<template>
  <div>
    <ProgressSpinner v-if="!individuals" />
    <DataTable
      v-if="individuals"
      :key="key"
      ref="dt"
      filterDisplay="menu"
      v-model:filters="filters1"
      :paginator="true"
      :rows="10"
      :rowsPerPageOptions="[5, 10, 20]"
      v-model:selection="selectedIndividuals"
      :value="individuals"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    >
      <template #empty> No Individuals Found </template>
      <template #header>
        <div class="p-fluid formgrid grid align-content-center align-items-end vertical-container">
          <div class="field col-12 sm:col-6">Individuals</div>
          <div v-if="individuals.length != 0" class="field col-12 sm:col-6 align-content-center">
<!--            <Button icon="pi pi-external-link" label="Export Selected as CSV" @click="exportCSV($event)" />-->
          </div>
        </div>
      </template>
      <Column headerStyle="width: 3em" selectionMode="multiple" />
      <Column
        :sortable="true"
        field="firstName"
        filterMatchMode="contains"
        filterPlaceholder="Name contains"
        header="First Name"
        headerStyle="text-align: left; width: 10%"
        showButons="true"
      >
        <template #filter="{filterModel}">
          <InputText v-model="filterModel.value" class="p-column-filter" placeholder="Search by Last Name" type="text" />
        </template>
      </Column>
      <Column
        :sortable="true"
        field="lastName"
        filterMatchMode="contains"
        filterPlaceholder="Name contains"
        header="Last Name"
        headerStyle="text-align: left; width: 10%"
        showButtons="true"
      >
        <!-- <template #body="slotProps">
          {{ displayName(slotProps.data) }}
        </template> -->
        <template #filter="{filterModel}">
          <InputText v-model="filterModel.value" class="p-column-filter" placeholder="Search by Last Name" type="text" />
        </template>
      </Column>
      <Column header="Birthdate" field="dateOfBirth" headerStyle="text-align: left; width: 11%">
        <template #body="slotProps">
          {{ fixDate(slotProps.data.dateOfBirth) }}
        </template>
      </Column>
      <Column header="Age" headerStyle="text-align: left; width: 9%">
        <template #body="slotProps">
          {{ outputAge(slotProps.data.dateOfBirth) }}
        </template>
      </Column>
      <Column header="Address" field="household.address1" headerStyle="text-align: left">
        <template #body="slotProps">
          {{ slotProps.data.household.address1 }}
          <div v-if="slotProps.data.household.address2">
            {{ slotProps.data.household.address2 }}
          </div>
        </template>
      </Column>
      <Column field="household.address2" header="Address 2" :hidden="true" />
      <Column field="household.city" header="City" headerStyle="text-align: left; width: 10%" />
      <Column field="household.state" header="State" headerStyle="text-align: middle; width: 6%" />
      <Column field="household.zipcode" header="Zip" headerStyle="text-align: left; width: 6%" />
      <Column header="Actions" headerStyle="width: 15%; text-align: left">
        <template #body="slotProps">
          <Button
            v-tooltip.top="'Edit ' + slotProps.data.firstName + ' ' + slotProps.data.lastName"
            class="p-button-rounded p-button-text"
            icon="fas fa-pencil"
            type="button"
            @click="onIndividualSelect(slotProps.data)"
          />
          <Button
            v-tooltip.top="'Delete ' + slotProps.data.firstName + ' ' + slotProps.data.lastName"
            class="p-button-rounded p-button-text"
            icon="fas fa-trash"
            @click="clickTrash(slotProps.data)"
          />
          <Button
            v-tooltip.top="'View ' + slotProps.data.firstName + ' ' + slotProps.data.lastName + ' Details'"
            class="p-button-rounded p-button-text"
            icon="fas fa-list"
            @click="viewIndividual(slotProps.data)"
          />
        </template>
      </Column>
      <template v-if="selectedIndividuals.length != 0" #footer>
        <Button v-if="selectedIndividuals.length != 0" label="Add Selected to Group" @click="addGroupMember($event)" #footer />
<!--        <Button v-if="selectedIndividuals.length != 0" label="Download CSV file" @click="exportCSV($event)" />-->
      </template>
    </DataTable>
    <confirmation-dialog
      v-bind:display="displayDelConfirmation"
      v-bind:message="deleteMessage"
      v-bind:type="'Delete Individual'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmDelete"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />

    <add-to-group-dialog
        v-bind:display="displayGroupDialog"
        v-bind:header="groupDialogHeader"
        v-bind:groups=this.groups
        @add-group="clickAddtoGroup"
        @cancel-group="clickCancelGroup"
    />
  </div>
</template>

<script>
import {differenceInCalendarYears} from "date-fns";
import IndividualsApi from "@/apis/IndividualsApi";
import GroupsApi from "@/apis/GroupsApi";
import {calculateAge, formatDate} from "@/functions";
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {GroupModel} from "@/models/GroupModel";

export default {
  data() {
    return {
      filters: {},
      key: 0,
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      zip: "",
      match: "all",
      selectedIndividuals: [],
      individuals: null,
      individualsApi: null,
      displayDelConfirmation: null,
      individualToDelete: [],
      deleteMessage: "",
      displayProgressDialog: false,
      displayGroupDialog: false,
      groupDialogHeader: null,
      dialogHeader: null,
      filters1: null,
      groups: null
    };
  },
  beforeRouteUpdate(to) {
    this.key++;
    this.getSearchParams();
    this.$forceUpdate();
  },
  created() {
    this.getSearchParams();
    this.individualsApi = new IndividualsApi();
    this.groupsApi = new GroupsApi();
    this.initFilters1();
  },
  mounted() {
    this.loadIndividuals();
  },
  methods: {
    initFilters1() {
      this.filters1 = {
        'lastName': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'firstName': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},

      }
    },
    getSearchParams: function () {
      this.firstName = this.$route.query.firstName;
      this.lastName = this.$route.query.lastName;
      this.address = this.$route.query.address;
      this.city = this.$route.query.city;
      this.zip = this.$route.query.zip;
      this.match = this.$route.query.match;
    },
    loadIndividuals: function () {
      this.individualsApi.getIndividuals(this.firstName, this.lastName, this.address, this.city, this.zip, this.match, (data) => {
        this.individuals = data;
      });
    },
    onIndividualSelect(data) {
      this.$router.push("/individual/edit?id=" + data.id);
    },
    displayName: function (individual) {
      return (
        (individual.prefix ? individual.prefix + " " : "") +
        individual.firstName +
        " " +
        (individual.middleName ? individual.middleName + " " : "") +
        (individual.lastName ? individual.lastName : individual.household.lastName)
      );
    },
    calculateAge: function (dateOfBirth) {
      if (dateOfBirth !== null) {
        return differenceInCalendarYears(new Date(), new Date(dateOfBirth));
      }
    },
    viewIndividual: function (data) {
      this.$router.push("/individual?id=" + data.id);
    },
    onIndividualRowSelect(event) {
      //this.$emit('click-member', event.data);
      this.$router.push("/individual?id=" + event.data.id);
    },
    // formatDate: function (dateOfBirth) {
    //   return stripTimeFromDate(dateOfBirth)
    // },
    fixDate(date) {
      return formatDate(date);
    },
    outputAge: function (date) {
      return calculateAge(date);
    },
    exportCSV() {
      // this.$refs.dt.exportCSV(selectionOnly);
      this.$refs.dt.exportCSV({ selectionOnly: true });
    },
    addGroupMember() {
      this.groupId = null
      this.groupsApi.getGroups((data) => {
        this.groups = data;
      });
      this.displayGroupDialog = true;
    },
    clickDeleteIndividual(individual) {
      this.dialogHeader = "Deleting Individual";
      this.displayProgressDialog = true;
      this.individualsApi.deleteIndividual(individual.id, () => {
        this.individuals = this.individuals.slice().filter((n) => n.id !== individual.id);
        this.displayIndividualDialog = false;
        this.dialogHeader = null;
        this.displayProgressDialog = false;
      });
    },
    clickCancelGroup() {
      this.displayGroupDialog = false;
    },
    clickAddtoGroup(event) {
      if (event.group.id){
        console.log("group")
        this.displayGroupDialog = false;
      }
      else {
        console.log("groupName")
        let group = new GroupModel()
        group.name = event.groupName;
        this.groupsApi.addGroup(group, (data) => {
          this.group = new GroupModel();
          this.displayGroupDialog = false;
        });
      }
      //add functionality to actually add the members to the group
      //also need error checking to only add them once
      this.selectedIndividuals = [];
    },
    clickTrash(individual) {
      this.individualToDelete = individual;
      this.deleteMessage = "Are you sure you want to delete " + individual.firstName + " " + individual.lastName + "?";
      this.displayDelConfirmation = true;
    },
    confirmCancel() {
      this.displayDelConfirmation = false;
    },
    confirmDelete() {
      this.clickDeleteIndividual(this.individualToDelete);
      this.displayDelConfirmation = false;
      this.individualToDelete = [];
    },
  },
};
</script>

<style scoped></style>
