import { request } from "@/functions";

export default class HouseholdApi {
  getHouseholdsList(successCallback) {
    request("get", "/households", null, successCallback);
  }

  getHousehold(id, successCallback) {
    request("get", "/households/" + id, null, successCallback);
  }

  updateHousehold(household, successCallback) {
    request("PUT", "/households/" + household.id, household, successCallback);
  }

  deleteHousehold(id, successCallback) {
    request("DELETE", "/households/" + id, null, successCallback);
  }

  addHousehold(household, successCallback) {
    request("POST", "/households/", household, successCallback);
  }
}
