import {differenceInCalendarYears, differenceInDays, format, parse} from "date-fns";
import {Vue} from "./main";

export function authenticated() {
  // TODO: validate token https://thomasstep.com/blog/a-guide-to-using-jwt-in-javascript
  return localStorage.getItem("vue-token") !== null;
}

export function getApiUrl(params) {
    let actualUrl = params.url
    let baseUrl = "";
    if (!actualUrl.startsWith("http")) {
        baseUrl = process.env.VUE_APP_API_HOST || "https://api.steeplesoft.com";
        if (actualUrl.charAt(0) !== "/") {
            actualUrl = "/" + actualUrl;
        }
    }
    return {actualUrl, baseUrl};
}

export function makeRequest(params) {
    let {actualUrl, baseUrl} = getApiUrl(params);

    let config = {
        headers: {"Content-type": params.contentType || "application/json"},
        credentials: "include",
        method: params.method || "GET"
    };

    if (params.payload) {
        config.body = params.payload;
    }
    console.log("Requesting " + baseUrl + actualUrl);
    fetch(baseUrl + actualUrl, config)
        .then((res) => {
                console.log("status = " + res.status);
                if (!res.ok) {
                    if (res.status === 401) {
                        Vue.$router.push("/logout");
                    } else {
                        console.log(res.error());
                    }
                }
                if (params.download === true) {
                    res.blob().then((blob) => {
                        window.open(window.URL.createObjectURL(blob), 'Checkin Sheet', 'width=1024,height=768')
                    })
                } else {
                    res.text().then((text) => {
                        if (text.length === 0) {
                            params.successCallback();
                        } else {
                            const type = res.headers.get("Content-Type");
                            if (type === "application/json") {
                                params.successCallback(JSON.parse(text));
                            } else {
                                params.successCallback(text);
                            }
                        }
                    });
                }
            }
        )
        .catch((err) => {
            Vue.$router.push("/");
            // window.location.reload();
        });
}

export function request(method, url, payload, successCallback, download) {
    let baseUrl = "";
    if (!url.startsWith("http")) {
        baseUrl = process.env.VUE_APP_API_HOST || "https://api.steeplesoft.com";
        if (url.charAt(0) !== "/") {
            url = "/" + url;
        }
    }

    let config = {
        headers: {"Content-type": "application/json"},
        method: method
    };

  const token = localStorage.getItem("vue-token");
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  if (payload) {
    config.body = JSON.stringify(payload);
  }
  console.log("Requesting " + baseUrl + url);
  fetch(baseUrl + url, config)
    .then((res) => {
      console.log("status = " + res.status);
      if (!res.ok) {
        if (res.status === 401) {
          Vue.$router.push("/logout");
        } else {
          console.log(res.error());
        }
      }
      if (download === true) {
        res.blob().then((blob) => {
          window.open(window.URL.createObjectURL(blob), "Checkin Sheet", "width=1024,height=768");
        });
      } else {
        res.text().then((text) => {
          if (text.length === 0) {
            successCallback();
          } else {
            const type = res.headers.get("Content-Type");
            if (type === "application/json") {
              successCallback(JSON.parse(text));
            } else {
              successCallback(text);
            }
          }
        });
      }
    })
    .catch((err) => {
      Vue.$router.push("/");
      // window.location.reload();
    });
}

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function stringToDate(date, format) {
    if (!date) {
        return new Date();
    }
    if (!format) {
        format = "yyyy-MM-dd";
    }
    return parse(date, format, new Date());
}

export function calculateAge(date) {
    if (date) {
        let index = date.indexOf("T");
        date = index > -1 ? date.substring(0, index) : date;
        const parsed = parse(date, "yyyy-MM-dd", new Date());
        let age = differenceInCalendarYears(new Date(), parsed);

        if (age > 0) {
            if (age === 1) {
                return `${age} year`;
            } else {
                return `${age} years`;
            }
        } else {
            age = Math.round(differenceInDays(new Date(), parsed) / 12);
            if (age === 1) {
                return `${age} month`;
            } else {
                return `${age} months`;
            }
        }
    }
}

export function stripTimeFromDate(date) {
    if (!date && date != "") {
        return;
    }
    if (!(date instanceof String)) {
        date = formatDate(date, "yyyy-MM-dd");
    }
    let index = date.indexOf("T");
    if (index > -1) {
        return date.substring(0, index);
    } else {
        return date;
    }
}

export function formatDate(date, dateFormat) {
    if (date) {
        if (!dateFormat) {
            dateFormat = "MM-dd-yyyy";
        }
        let _date = date;
        if (typeof date === "string") {
            let formatString = "yyyy-MM-dd";
            _date = date.replace("T", " ");

            let index = _date.indexOf(" ");
            if (index > -1) {
                _date = _date.substring(0, index);
                //formatString += " HH:mm:ss";
            }
            _date = parse(_date, formatString, new Date());
        }
        console.log("formatDate(" + _date + ", " + dateFormat + ")");
        return format(_date, dateFormat);
    }
}

export function formatTime(time) {
    if (time) {
        let pattern = "HH:mm:ss";
        console.log("formatTime(" + time + ", " + pattern + ")");
        const parsed = parse(time, pattern, new Date());
        console.log("parsed = " + parsed);
        return format(parsed, "h:mm a");
    }
}

export function debug(msg) {
    if (msg instanceof String) {
        alert(msg);
    } else {
        alert(JSON.stringify(msg));
    }
}

export function responseToOptions(data) {
    return data.map((item) => ({name: item.name, code: item.id}));
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

    return JSON.parse(jsonPayload);
}

export function formatRecurs(data) {
    if (data.recurrenceType === "WEEKLY") {
        return "Weekly on " + format(parse(data.startDate, "yyyy-MM-dd", new Date()), "cccc");
    } else if (data.recurrenceType === "YEARLY") {
        return "Yearly on " + format(parse(data.startDate, "yyyy-MM-dd", new Date()), "MM/dd");
    } else if (data.recurrenceType === "MONTHLY") {
        return "Monthly on the " + format(parse(data.startDate, "yyyy-MM-dd", new Date()), "co");
    } else if (data.recurrenceType === "NEVER") {
        return "Once on " + format(parse(data.startDate, "yyyy-MM-dd", new Date()), "MM/dd/yyyy");
    } else if (data.recurrenceType === "DAILY") {
        return "Daily";
    } else {
        return data.recurrenceType;
    }
}

export function formatIndividualName(data) {
    let obj = data.individual ? data.individual : data;
    if (obj) {
        let name = obj.firstName;
        if (obj.prefix) {
            name = obj.prefix + " " + name;
        }
        if (obj.middleName) {
            name += " " + obj.middleName;
        }
        if (obj.lastName) {
            name += " " + obj.lastName;
        } else {
            name += " " + obj.household.lastName;
        }
        console.log(name);
        return name;
    } else {
        return "";
    }
}
