export class ServiceModel {
  constructor() {
    this.locationId = null;
    this.location = { name: null };
    this.name = null;
    this.serviceTime = null;
    this.recurrenceType = null;
    this.startDate = null;
    this.endDate = null;
  }
}
