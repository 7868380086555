<template>
  <div>
    <h1>Steeplesoft</h1>
    You have successfully signed up for service with Steeplesoft. Click
    <a style="text-decoration: underline" @click="login">here</a> to log on.
  </div>
</template>

<script>


export default {
  name: "SignupSuccess",
  methods: {
    login() {
      this.$router.push("/login")
    },
  },
};
</script>

<style scoped></style>
