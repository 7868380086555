export class GroupMessageModel {
  constructor() {
    this.recipId = null;
    this.testEmail = null;
    this.testPhoneNumber = null;
    this.subject = null;
    this.body = null;
    this.type = "email";
    this.includeHouseholdHead = false;
  }
}
