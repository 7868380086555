<template>
  <Card style="padding: 1%; text-align: left">
    <template #title> Additional Information </template>
    <template #content>
      <div class="grid">
        <div v-if="value.email" class="col-12" style="padding: 2%">
          Email:
          <a :href="'mailto:' + value.email" style="font-weight: bold">{{ value.email }} </a>
        </div>
        <div v-if="value.gender" class="col-12" style="padding: 2%">
          <div v-if="value.gender === 'M'">Gender: <b>Male</b></div>
          <div v-if="value.gender === 'F'">Gender: <b>Female</b></div>
        </div>

        <div v-if="value.maritalStatus" class="col-12" style="padding: 2%">
          Marital Status: <b>{{ value.maritalStatus }}</b>
        </div>
        <div v-if="value.jobTitle" class="col-12" style="padding: 2%">
          Job Title: <b>{{ value.jobTitle }}</b>
        </div>
        <div v-if="value.status" class="col-12" style="padding: 2%">
          Membership Status: <b>{{ value.status }}</b>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
export default {
  name: "IndividualAdditionalInfoDisplay",
  props: ["value"],
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.p-card /deep/ .p-card-body {
  padding-bottom: 1px;
}

.p-card /deep/ .p-card-content {
  padding-bottom: 1rem;
  padding-top: 0px;
}
</style>
