<template>
  <span class="padded">
    <div class="p-fluid formgrid grid">
      <div class="col-6">
        <ChurchInfoForm v-model="signup" />
      </div>
      <div class="col-6">
        <BillingInfoForm v-model="signup" />
      </div>
    </div>
    <div class="p-fluid formgrid grid">
      <div class="col-offset-6 col-3">
        <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="clickCancel" />
      </div>
      <div class="col-3">
        <Button autofocus icon="fas fa-check" label="Save" @click="submitSignup()" />
      </div>
    </div>
      <Toast />
  </span>
</template>

<script>
import { ChurchSignupModel } from "@/models/ChurchSignupModel";
import SignupApi from "@/apis/SignupApi";
import { validate, validations } from "@/validation";

export default {
  name: "Signup",
  data() {
    return {
      signup: new ChurchSignupModel(),
    };
  },
  created() {
    this.signupApi = new SignupApi();
  },
  methods: {
    submitSignup() {
      console.log(JSON.stringify(this.signup));
      const errors = validate(this.signup, validations.signup);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
      } else {
        this.signupApi.submitSignup(this.signup, (data) => {
          this.$router.push("/signupSuccess");
        });
      }
    },
  },
};
</script>

<style scoped>
.padded {
  padding: 5px;
}
</style>
