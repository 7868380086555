import { request } from "@/functions";

export default class LocationsApi {
  getLocationsList(successCallback) {
    request("get", "/locations", null, successCallback);
  }

  getLocation(id, successCallback) {
    request("get", "/locations/" + id, null, successCallback);
  }

  addLocation(location, successCallback) {
    request("POST", "/locations/", location, successCallback);
  }

  updateLocation(location, successCallback) {
    request("PUT", "/locations/" + location.id, location, successCallback);
  }

  deleteLocation(id, successCallback) {
    request("DELETE", "/locations/" + id, null, successCallback);
  }
}
