<template>
  <Card style="padding: 1%; text-align: left">
    <template #title>
      Notes
      <div style="position: relative; float: right">
        <Button v-tooltip.left="'Add Note'" class="p-button-rounded p-button-text" icon="fas fa-plus" @click="addNoteClicked" />
      </div>
    </template>
    <template #content>
      <div class="grid">
        <div v-if="addNewNote">
          <InputText id="noteText" v-model="noteText" type="text" />
          &nbsp;
          <Button label="Add" @click="addNote" />
          &nbsp;
          <Button label="Cancel" @click="cancelAddNote" />
          <p></p>
        </div>
      </div>
      <ProgressSpinner v-if="!individualNotes" />
      <DataTable v-if="individualNotes" :value="individualNotes" sortField="date" sortOrder="-1">
        <template #empty>
          No notes found
        </template>
        <Column field="text" header="Note" headerStyle="text-align: left">
          <template #body="slotProps">
            {{ slotProps.data.text }}
          </template>
        </Column>

        <Column field="dateAdded" header="Date" headerStyle="text-align: left; width: 30%">
          <template #body="slotProps">
            {{ displayDate(slotProps.data.dateAdded) }}
          </template>
        </Column>
        <Column headerStyle="text-align: left; width: 10%">
          <template #body="slotProps">
          <Button
              v-tooltip.top="'Delete Note'"
              class="p-button-rounded p-button-text"
              icon="fas fa-trash"
              @click="clickTrash(slotProps.data.id)"
          />
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import {formatDate} from "@/functions";
import IndividualsApi from "@/apis/IndividualsApi";

export default {
  name: "IndividualNotes",
  props: ["value"],
  data() {
    return {
      addNewNote: false,
      noteText: "",
      newNote: {},
      individualsApi: null,
      individualNotes: null
    };
  },
  created () {
    this.individualsApi = new IndividualsApi();
    this.getNotes()
  },
  methods: {

    displayDate: function (date) {
      return formatDate(date);
    },
    addNoteClicked() {
      this.addNewNote = true;
    },
    cancelAddNote() {
      this.addNewNote = false;
    },
    addNote() {
      this.newNote.individualId = this.value.id
      this.newNote.noteText = this.noteText
      this.individualsApi.addIndividualNote(this.newNote, () => {
        this.displayProgressDialog = false;
        this.dialogHeader = null;
        this.noteText = ""
        this.addNewNote = false;
        this.getNotes()
      });
      //this.value.notes.push({ id: "4", note: this.noteText, date: new Date() });

    },
    getNotes() {
      this.individualsApi.getIndividualNotes(this.value.id, (data) => {
        this.individualNotes = data
      });
    },
    clickTrash(noteId) {
      this.individualsApi.deleteNote(this.value.id, noteId, () => {
        this.getNotes()
      })
    }
  },
};
</script>

<style scoped></style>
