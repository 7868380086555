<template>
  <span>
    <Dialog :closable="false" :header="header" :modal="false" :style="{ width: '250px', zOrder: 1000 }" :visible.sync="display">
      <div align="center">
        <ProgressSpinner />
      </div>
    </Dialog>
  </span>
</template>

<script>
export default {
  name: "ProgressDialog",
  props: ["display", "header"],
};
</script>
