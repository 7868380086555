export const recurrenceOptions = [
  { name: "Never", code: "NEVER" },
  { name: "Daily", code: "DAILY" },
  { name: "Weekly", code: "WEEKLY" },
  { name: "Monthly", code: "MONTHLY" },
  { name: "Yearly", code: "YEARLY" },
];

export const hourOptions = [
  { name: "12:00", code: "12:00" },
  { name: "12:15", code: "12:15" },
  { name: "12:30", code: "12:30" },
  { name: "12:45", code: "12:45" },
  { name: "1:00", code: "1:00" },
  { name: "1:15", code: "1:15" },
  { name: "1:30", code: "1:30" },
  { name: "1:45", code: "1:45" },
  { name: "2:00", code: "2:00" },
  { name: "2:15", code: "2:15" },
  { name: "2:30", code: "2:30" },
  { name: "2:45", code: "2:45" },
  { name: "3:00", code: "3:00" },
  { name: "3:15", code: "3:15" },
  { name: "3:30", code: "3:30" },
  { name: "3:45", code: "3:45" },
  { name: "4:00", code: "4:00" },
  { name: "4:15", code: "4:15" },
  { name: "4:30", code: "4:30" },
  { name: "4:45", code: "4:45" },
  { name: "5:00", code: "5:00" },
  { name: "5:15", code: "5:15" },
  { name: "5:30", code: "5:30" },
  { name: "5:45", code: "5:45" },
  { name: "6:00", code: "6:00" },
  { name: "6:15", code: "6:15" },
  { name: "6:30", code: "6:30" },
  { name: "6:45", code: "6:45" },
  { name: "7:00", code: "7:00" },
  { name: "7:15", code: "7:15" },
  { name: "7:30", code: "7:30" },
  { name: "7:45", code: "7:45" },
  { name: "8:00", code: "8:00" },
  { name: "8:15", code: "8:15" },
  { name: "8:30", code: "8:30" },
  { name: "8:45", code: "8:45" },
  { name: "9:00", code: "9:00" },
  { name: "9:15", code: "9:15" },
  { name: "9:30", code: "9:30" },
  { name: "9:45", code: "9:45" },
  { name: "10:00", code: "10:00" },
  { name: "10:15", code: "10:15" },
  { name: "10:30", code: "10:30" },
  { name: "10:45", code: "10:45" },
  { name: "11:00", code: "11:00" },
  { name: "11:15", code: "11:15" },
  { name: "11:30", code: "11:30" },
  { name: "11:45", code: "11:45" },
];

export const periodOptions = [
  { name: "AM", code: "AM" },
  { name: "PM", code: "PM" },
];

export const states = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export const genders = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];

export const maritalStatuses = [
  { label: "Single", value: "SINGLE" },
  { label: "Married", value: "MARRIED" },
  { label: "Divorced", value: "DIVORCED" },
  { label: "Separated", value: "SEPARATED" },
  { label: "Widowed", value: "WIDOWED" },
  { label: "Unknown", value: "UNKNOWN" },
];

export const membershipStatuses = [
  { label: "Child", value: "CHILD" },
  { label: "Prospect", value: "PROSPECT" },
  { label: "Member", value: "MEMBER" },
  { label: "Inactive", value: "INACTIVE" },
  { label: "Deceased", value: "DECEASED" },
];

export const phoneTypes = [
  { label: "Home", value: "HOME" },
  { label: "Mobile", value: "MOBILE" },
  { label: "Work", value: "WORK" },
  { label: "Other", value: "OTHER" },
];

export const personalPrefixes = [
  { label: "Mr.", value: "Mr." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Ms.", value: "Ms." },
];

export const socialMediaOptions = [
  { label: "Facebook", value: "Facebook" },
  { label: "Instagram", value: "Instagram" },
  { label: "Twitter", value: "Twitter" },
  { label: "Other", value: "Other" },
];

export const userRoles = [
  { label: "User", value: "USER" },
  { label: "Admin", value: "ADMIN" },
];
