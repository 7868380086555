export class UserModel {
  constructor() {
    this.id = null;
    this.emailAddress = null;
    this.firstName = null;
    this.lastName = null;
    this.password = null;
    this.role = null;
  }
}
