<template>
  <span>
    <Dialog
        :closable="false"
        :contentStyle="{ overflow: 'visible' }"
        :modal="true"
        :style="{ width: '30vw' }"
        :visible.sync="display"
    >
      <template #header>
        Add Selected Individuals to Group
      </template>
      <div class="grid p-fluid">
        <div class="col-12" style="padding: 0.5em">
          <custom-label v-bind:label="'Choose an Existing Group'" v-bind:required="true" />
          <Dropdown
              v-model="groupId"
              :options="groups"
              optionLabel="name"
              optionValue="id"
              placeholder="Select a Group"
              :showClear="true"
          />
        </div>
        <div class="col-12" style="padding: 0.5em">
          OR Create a new group
        </div>
        <div class="col-8" style="padding: 0.5em">
           <custom-label v-bind:label="'Group Name'" />
           <InputText id="name" v-model="groupName" type="text" />
        </div>
      </div>
      <template #footer>
        <div>
          <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="click('cancel')" />
          <Button autofocus icon="fas fa-check" label="Add" @click="click('add')" />
        </div>
      </template>
    </Dialog>
    <Toast />
  </span>
</template>

<script>
import {GroupModel} from "@/models/GroupModel";

export default {
  name: "AddToGroupDialog",
  props: ["display", "action", "message", "type", "groups"],
  emits: ["cancel-group", "add-group"],
  data() {
    return {
      groupId: null,
      groupName: null
    };
  },
  created() {
    this.groupId = null
  },
  updated() {
    this.group = new GroupModel();
  },
  methods: {
    click(operation) {
      if (operation == "save" || operation == "add") {
        this.group.id = this.groupId
        this.groupId = null
        // const errors = validate(this.group, validations.addToGroupDialog);
        // if (errors) {
        //   errors.forEach((element) => {
        //     this.$toast.add({
        //       severity: element.severity,
        //       summary: element.content,
        //       life: 3000,
        //     });
        //   });
        if ((!this.group.id && !this.groupName) || (this.group.id && this.groupName)) {
          this.$toast.add({
                   severity: "error",
                   summary: "You must select an existing group or create a new one.",
                   life: 3000,
                 });
          return;
        }
      }
      this.$emit(operation + "-group", {group:this.group, groupName: this.groupName});
      this.groupName = null
    },
  },

}
</script>

<style scoped>

</style>