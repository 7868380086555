<template>
  <Card style="padding: 1%; text-align: left">
    <template #title> Assignments </template>
    <template #content>
      <div style="padding: 1%">
        <ul v-if="modelValue.length !== 0">
          <li v-for="item in modelValue" :key="item.id">
            {{ item.activity.name }} -
            <router-link
              :to="{
                name: 'viewRoster',
                params: { rosterId: item.id, ministryId: item.activity.ministryId, activityId: item.activity.id },
              }"
              tag="button"
            >
              {{ item.name }}
            </router-link>
          </li>
        </ul>
        <p></p>
        <!--        <router-link :to="{ name: 'individualAttendance', params: { id: individual } }" tag="button">View Attendance</router-link>-->
        <router-link :to="{ path: '/individual/attendance', query: { id: individual } }" tag="button">View Attendance</router-link>
      </div>

      <div v-if="modelValue.length === 0" style="padding: 1%">No Assignments Found</div>
    </template>
  </Card>
</template>

<script>
export default {
  name: "AssignmentsList",
  props: ["modelValue", "individual"],
  methods: {},
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.p-card /deep/ .p-card-body {
  padding-bottom: 1px;
}

.p-card /deep/ .p-card-content {
  padding-bottom: 1rem;
  padding-top: 0px;
}
</style>
