export class PhoneNumberModel {
  constructor() {
    this.id = null;
    this.churchId = null;
    this.individualId = null;
    this.number = null;
    this.preferred = false;
    this.type = "MOBILE";
  }
}
