import { createStore } from "vuex";
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
  key: 'steeplesoft',
  storage: window.sessionStorage
})

export const store = createStore({
  strict: process.env.NODE_ENV !== "production",
  plugins: [vuexPersist.plugin],
  selectedState: {
    authenticated: true,
    nextRoute: null,
    selectedMinistry: null,
    currentNavNode: null
  },
  mutations: {
    authenticated(state, authenticated) {
      console.log("authenticated = " + authenticated);
      state.authenticated = authenticated;
    },
    currentNavNode(state, node) {
      state.currentNavNode = node;
    },
    nextRoute(state, to) {
      state.nextRoute = to;
    },
    selectMinistry(state, id) {
      state.selectedMinistry = id;
    },
  },
});
