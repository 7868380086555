<template>
  <div>
    <ProgressSpinner v-if="!households" />
    <DataTable
      v-if="households"
      v-model:filters="filters1"
      filterDisplay="menu"
      :paginator="true"
      :rows="10"
      :rowsPerPageOptions="[5, 10, 20]"
      :value="households"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      selectionMode="single"
      @row-select="onHouseholdRowSelect"
    >
      <template #header>
        <div class="p-fluid formgrid grid align-content-center align-items-end vertical-container">
          <div class="field col-12 sm:col-8">Households</div>
          <div class="field col-12 sm:col-4 align-content-center">
            <Button icon="fas fa-plus" label="Add New Household" @click="addNewHousehold" />
          </div>
        </div>
        <!-- <div class="p-clearfix" style="line-height:1.87em">Households</div> -->
      </template>
      <template #empty>
        No households found.
      </template>
      <template #loading>
        Loading households. Please wait.
      </template>

      <Column :sortable="true" field="lastName" filterMatchMode="contains" header="Last Name">

        <template #filter="{filterModel}">
          <InputText v-model="filterModel.value" class="p-column-filter" placeholder="Search by Last Name" type="text" />
        </template>
      </Column>
      <Column header="Address">
        <template #body="slotProps">
          {{ slotProps.data.address1 }}
          <div v-if="slotProps.data.address2">
            {{ slotProps.data.address2 }}
          </div>
        </template>
      </Column>
      <Column field="city" header="City" />
      <Column field="state" header="State" headerStyle="width: 10%; textAlign: left" />
      <Column field="zipcode" header="Zip" headerStyle="width: 10%; textAlign: left" />
      <Column header="Actions" headerStyle="width: 15%; textAlign: left">
        <template #body="slotProps">
          <Button
            v-tooltip.top="'View ' + slotProps.data.lastName + ' Details'"
            class="p-button-rounded p-button-text"
            icon="fas fa-list"
            @click="onHouseholdSelect(slotProps.data)"
          />
          <Button
            v-tooltip.top="'Edit ' + slotProps.data.lastName + ' Household'"
            class="p-button-rounded p-button-text"
            icon="fas fa-pencil"
            @click="clickEditHousehold(slotProps.data)"
          />
          <Button
            v-tooltip.top="'Delete ' + slotProps.data.lastName + ' Household'"
            class="p-button-rounded p-button-text"
            icon="fas fa-trash"
            @click="clickTrash(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <confirmation-dialog
      v-bind:display="displayDelConfirmation"
      v-bind:message="deleteMessage"
      v-bind:type="'Delete Household'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmDelete"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
  </div>
</template>

<script>
import HouseholdApi from "@/apis/HouseholdApi";
import {HouseholdModel} from "@/models/HouseholdModel";
import {FilterMatchMode, FilterOperator} from 'primevue/api';

export default {
  data() {
    return {
      filters: {},
      households: null,
      householdToDelete: [],
      displayDelConfirmation: false,
      household: new HouseholdModel(),
      displayProgressDialog: false,
      dialogHeader: null,
      deleteMessage: null,
      filters1: null
    };
  },
  householdsApi: null,
  created() {
    this.householdsApi = new HouseholdApi();
    this.initFilters1();
  },
  mounted() {
    this.householdsApi.getHouseholdsList((data) => {
      this.households = data;
    });
  },
  methods: {
    initFilters1() {
      this.filters1 = {
        'lastName': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
      }
    },
    onHouseholdSelect(household) {
      this.$router.push("/household?id=" + household.id);
    },
    onHouseholdRowSelect(event) {
      this.$router.push("/household?id=" + event.data.id);
    },
    clickTrash(household) {
      this.householdToDelete = household;
      this.deleteMessage = "Are you sure you want to delete the " + household.lastName + " Household?";
      this.displayDelConfirmation = true;
    },
    addNewHousehold() {
      this.$router.push("/household/add");
    },
    confirmCancel() {
      this.displayDelConfirmation = false;
    },
    confirmDelete() {
      this.clickDeleteHousehold(this.householdToDelete);
      this.displayDelConfirmation = false;
      this.householdToDelete = [];
    },
    clickDeleteHousehold(household) {
      this.dialogHeader = "Deleting Household";
      this.displayProgressDialog = true;
      this.householdsApi.deleteHousehold(household.id, () => {
        this.households = this.households.slice().filter((n) => n.id !== household.id);
        this.displayProgressDialog = false;
        this.dialogHeader = null;
      });
    },
    clickEditHousehold(data) {
      this.$router.push("/household/edit?id=" + data.id);
    },
    displayAddress(data) {
      return data.address1;
    },
  },
};
</script>

<style scoped></style>
