<template>
  <div>
    <ProgressSpinner v-if="!ministryInfo" />
    <span v-if="ministryInfo">
      <Card style="width: 35rem; margin-bottom: 2em">
        <template #title>
          <div v-if="!activityId" v-once>Add New Activity</div>
          <div v-else v-once>Edit {{ activity.name }}</div>
        </template>
        <template #content>
          <div class="grid p-fluid">
            <div class="col-12" style="padding: 0.5em">
              <custom-label v-bind:label="'Ministry'" v-bind:required="true" />
              <Dropdown
                id="ministryId"
                v-model="activity.ministryId"
                :contentStyle="{ overflow: 'visible' }"
                :options="ministryInfo"
                defaultValue="NEVER"
                optionLabel="name"
                optionValue="id"
                @keyup.enter="clickSave(activity)"
              />
            </div>

            <div class="col-12" style="padding: 0.5em">
              <custom-label v-bind:label="'Activity Name'" v-bind:required="true" />
              <InputText id="name" v-model="activity.name" type="text" @keyup.enter="clickSave(activity)"/>
            </div>
            <div class="col-12" style="padding: 0.5em">
              <custom-label v-bind:label="'Description'" />
              <InputText id="description" v-model="activity.description" type="text" @keyup.enter="clickSave(activity)"/>
            </div>
            <div class="col-6" style="padding: 0.5em">
              <custom-label v-bind:label="'Recurs'" v-bind:required="true" />
              <Dropdown
                id="recurs"
                v-model="activity.recurrenceType"
                :contentStyle="{ overflow: 'visible' }"
                :options="recurs"
                defaultValue="NEVER"
                optionLabel="name"
                optionValue="code"
                @keyup.enter="clickSave(activity)"
              />
            </div>
            <div class="p-col-6" style="padding: 0.5em; bottom: 0;left: 0;">
              <custom-label v-bind:label="'Supports Promotion'"  />
              <Checkbox v-model="activity.supportsPromotion" :binary="true" />
            </div>
            <div class="col-3" style="padding: 0.5em">
              <custom-label v-bind:label="'Start Date'" v-bind:required="true" />
              <Calendar
                :key="activity.id"
                v-model="activity.startDate"
                dateFormat="yy-mm-dd"
                format="yy-mm-dd"
                input-class="p-inputtext p-component p-filled"
                @keyup.enter="clickSave(activity)"
              >
                <template #button-cancel>
                  <Button class="p-button-text" icon="fas fa-times" label="Cancel" />
                </template>
                <template #button-confirm>
                  <Button autofocus icon="fas fa-check" label="OK" />
                </template>
              </Calendar>
            </div>
            <div class="col-3" style="padding: 0.5em">
              <custom-label v-bind:label="'End Date'" />
              <Calendar
                :key="activity.id"
                v-model="activity.endDate"
                format="yy-mm-dd"
                input-class="p-inputtext p-component p-filled"
                @keyup.enter="clickSave(activity)"
              >
                <template #button-cancel>
                  <Button class="p-button-text" icon="fas fa-times" label="Cancel" />
                </template>
                <template #button-confirm>
                  <Button autofocus icon="fas fa-check" label="OK" />
                </template>
              </Calendar>
            </div>
            <div class="col-6" style="padding: 0.5em">
              <custom-label required="true" v-bind:label="'Activity Time'" />
              <TimeSelector id="activityTime" v-model="activity.activityTime" />
              <!--                                @update:modelValue="activity.activityTime = $event"/>-->
            </div>

          </div>
        </template>
        <template #footer>
          <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="clickCancel" />
          <Button autofocus icon="fas fa-check" label="Save" @click="clickSave(activity)" />
        </template>
      </Card>
      <Toast />

      <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
    </span>
  </div>
</template>

<script>
import ActivitiesApi from "@/apis/ActivitiesApi";
import MinistriesApi from "@/apis/MinistriesApi";
import {validate, validations} from "@/validation";
import {ActivityModel} from "@/models/ActivityModel";
import {recurrenceOptions} from "@/constants";

export default {
  name: "ManageActivity",
  data() {
    return {
      activity: null,
      activityId: null,
      ministryId: null,
      activitiesApi: null,
      dialogHeader: null,
      displayProgressDialog: false,
      editMode: true,
      recurs: recurrenceOptions,
      ministryInfo: null,
    };
  },
  created() {

    this.activitiesApi = new ActivitiesApi();
    this.ministriesApi = new MinistriesApi();
    this.activity = new ActivityModel();
    this.ministriesApi.getFullMinistryInfo((data) => {
      this.ministryInfo = data;
      //this.ministryId = this.$route.query.ministryId;
    });
    this.activityId = parseInt(this.$route.query.activityId);
    this.ministryId = parseInt(this.$route.query.ministryId);
    if (!this.activityId) {
      this.editMode = false;
      this.activity = new ActivityModel();
      this.activity.ministryId = this.ministryId;
      console.log("activity.ministryId=" + this.activity.ministryId)
    } else {
      this.loadActivity(this.activityId);
    }
  },
  mounted() {
    // this.ministriesApi.getFullMinistryInfo((data) => {
    //   this.ministryInfo = data;
    //   this.ministryId = this.$route.query.ministryId;
    // });
  },
  methods: {
    loadActivity: function (activityId) {
      this.activitiesApi.getActivity(activityId, (activity) => {
        this.activity = activity;
      });
    },

    clickCancel() {
      this.$router.push("/activities?ministryId=" + this.ministryId);
    },
    clickSave(activity) {
      if (!this.editMode) {
        activity.ministryId = this.ministryId;
        this.dialogHeader = "Adding Activity";
      } else {
        this.dialogHeader = "Updating Activity";
      }
      const errors = validate(activity, validations.activity);
      // activity.activityTime = formatTime(activity.activityTime);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.displayProgressDialog = true;

      if (!this.editMode) {
        this.activitiesApi.addActivity(activity, (data) => {
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/activities?ministryId=" + this.ministryId);
        });
      } else {
        //activity.ministryId = this.ministryId;
        this.activitiesApi.updateActivity(activity, () => {
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/activities?ministryId=" + activity.ministryId);
        });
      }
    },
  },
};
</script>
