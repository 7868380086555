<template>
  <Card style="width: 40rem; margin-bottom: 2em">
    <template #title> Login </template>
    <template #content>
      <div class="grid">
        <div class="col-12" style="padding: 0.5em">
          <custom-label v-bind:label="'Email Address'" v-bind:required="true" />
          <InputText id="emailAddress" v-model="email" class="p-inputtext-lg" size="50" type="email" @keyup.enter="login" />
        </div>
        <div class="col-12" style="padding: 0.5em">
          <custom-label v-bind:label="'Password'" v-bind:required="true" />
          <InputText id="password" v-model="password" class="p-inputtext-lg" size="50" type="password" @keyup.enter="login" />
        </div>
      </div>
    </template>
    <template #footer>
      <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="cancel" />
      <Button autofocus icon="fas fa-check" label="OK" @click="login" />
    </template>
  </Card>
</template>

<script>
import AuthenticationApi from "@/apis/AuthenticationApi";
import {store} from "@/store";

export default {
  name: "Login",
  data() {
    return {
      email: "angela@steeplesoft.com",
      password: "password",
      error: false,
    };
  },
  authApi: null,
  created() {
    this.authApi = new AuthenticationApi();
    this.authApi.logout();
  },
  methods: {
    login() {
      this.authApi.login(this.email, this.password, (token) => {
          let next = store.state.nextRoute
          // let target = this.$route.query.target;
          this.$router.push(next ? next : "/");
      });
    },
    cancel() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped></style>
