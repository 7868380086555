export class RosterMessageModel {
  constructor() {
    this.recipId = 0;
    this.testEmail = null;
    this.testPhoneNumber = null;
    this.subject = null;
    this.body = null;
    this.type = "sms";
    this.includeHouseholdHead = true;
  }
}
