<template>
  <span>
    <Dialog
        :closable="false"
        :contentStyle="{ overflow: 'visible' }"
        :modal="true"
        :style="{ width: '50vw' }"
        :visible.sync="display"
    >
      <template #header>
        <b>{{ message.subject }} sent on {{ fixDate(message.date)}}
        </b>
      </template>
      <div class="grid p-fluid">
        <div class="col-12" style="padding: 0.5em">
            {{ message.body }}
        </div>
      </div>
      <template #footer>
        <div>
          <Button class="p-button-text" icon="fas fa-times" label="Close" @click="click('cancel')" />
        </div>
      </template>
    </Dialog>
  </span>
</template>

<script>
import {formatDate} from "@/functions";

export default {
  name: "DisplayMessageDialog",
  props: ["display", "modelValue"],
  emits: ["close-message-dialog"],
  data() {
    return {
      action: null,
      locationsApi: null,
      service: null,
    };
  },
  updated() {
    this.message = this.modelValue;
  },
  created() {},
  mounted() {},
  methods: {
    click(operation) {
      // if (operation == "save" || operation == "add") {
      //   const errors = validate(this.service, validations.serviceAttendance);
      //   if (errors) {
      //     errors.forEach((element) => {
      //       this.$toast.add({
      //         severity: element.severity,
      //         summary: element.content,
      //         life: 3000,
      //       });
      //     });
      //     return;
      //   }
      // }
      this.$emit("close-message-dialog", this.message);
    },
    fixDate(date) {
      return formatDate(date);
    },
  },
};
</script>