<template>
  <span v-if="household">
    <ProgressSpinner v-if="!household" />
    <div>
      <h1 v-if="!householdId" v-once>Add New Household</h1>
      <h1 v-else v-once>Edit {{ this.household.lastName }} Household</h1>
    </div>

    <div class="grid p-fluid">
      <div class="field col-9">
    <div class="grid p-fluid">
          <div class="col-12" style="padding: 0.5em">
        <custom-label v-bind:label="'Last Name'" v-bind:required="true" />
        <InputText id="name" v-model="household.lastName" type="text" />
      </div>
      <div class="col-12" style="padding: 0.5em">
        <custom-label v-bind:label="'Address 1'" v-bind:required="true" />
        <InputText id="address1" v-model="household.address1" type="text" />
      </div>
      <div class="col-12" style="padding: 0.5em">
        <custom-label v-bind:label="'Address 2'" v-bind:required="false" />
        <InputText id="address2" v-model="household.address2" type="text" />
      </div>
      <div class="col-5" style="padding: 0.5em">
        <custom-label v-bind:label="'City'" v-bind:required="true" />
        <InputText id="city" v-model="household.city" type="text" />
      </div>
      <div class="col-4" style="padding: 0.5em">
        <custom-label v-bind:label="'State'" v-bind:required="true" />
        <Dropdown v-model="household.state" :options="states" optionLabel="label" optionValue="value"> </Dropdown>
      </div>
      <div class="col-3" style="padding: 0.5em">
        <custom-label v-bind:label="'Zip'" v-bind:required="true" />
        <InputText id="zip" v-model="household.zipcode" type="text" />
      </div>
    </div>
      </div>
    </div>
    <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="clickCancel" />
    <Button autofocus icon="fas fa-check" label="Save" @click="clickSave(household)" />
    <Toast />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
  </span>
</template>

<script>
import HouseholdApi from "@/apis/HouseholdApi";
import { validate, validations } from "@/validation";
import { HouseholdModel } from "@/models/HouseholdModel";
import { states } from "@/constants";

export default {
  name: "ManageHousehold",
  data() {
    return {
      household: null,
      householdId: null,
      householdsApi: null,
      dialogHeader: null,
      displayProgressDialog: false,
      editMode: true,
      states: states,
    };
  },
  created() {
    this.householdId = this.$route.query.id;
    this.householdsApi = new HouseholdApi();
    if (!this.householdId) {
      this.editMode = false;
      this.household = new HouseholdModel();
    } else {
      this.loadHousehold(this.householdId);
    }
  },
  mounted() {},
  methods: {
    loadHousehold: function (householdId) {
      this.householdsApi.getHousehold(householdId, (household) => {
        this.household = household;
      });
    },

    clickCancel() {
      if (this.editMode) {
        this.$router.push("/household?id=" + this.household.id);
      } else {
        this.$router.push("/households/");
      }
    },
    clickSave(household) {
      if (!this.editMode) {
        this.dialogHeader = "Adding Household";
      } else {
        this.dialogHeader = "Updating Household";
      }
      const errors = validate(household, validations.household);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.displayProgressDialog = true;

      if (!this.editMode) {
        this.householdsApi.addHousehold(household, (data) => {
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/household?id=" + data.id);
        });
      } else {
        this.householdsApi.updateHousehold(household, () => {
          this.displayProgressDialog = false;
          this.dialogHeader = null;
          this.$router.push("/household?id=" + household.id);
        });
      }
    },
  },
};
</script>
