<template>
  <span>
    <div v-if="roster" class="grid p-fluid">
      <div class="col-4" style="padding: 0.5em">
        <b>Send Message to Roster - {{ roster.name }} </b>
      </div>
      <div class="col-12" style="padding: 0.5em">
        <custom-label v-bind:label="'Subject'" v-bind:required="true" />
        <InputText id="name" v-model="rosterMessage.subject" type="text" />
      </div>
      <div class="col-12" style="padding: 0.5em">
        <Editor v-model="rosterMessage.body" editorStyle="height: 320px" />
      </div>
      <div class="col-12" style="padding: 0.5em">
        <div class="grid p-fluid">
          <div class="col-4" style="">
            <custom-label v-bind:label="'Send Test Email to:'" v-bind:required="false" />
            <InputText id="testEmail" v-model="rosterMessage.testEmail" type="text" />
          </div>
          <div class="col-3" style="display: flex">
            <Button icon="fas fa-envelope" label="Send Test Email" style="padding: 0.5em" @click="sendTestEmail" />
          </div>
          <div class="col-3" style="display: flex"></div>
          <div class="col-2" style="display: flex">
            <Button icon="fas fa-envelope" label="Send Email" style="padding: 0.5em" @click="sendEmail" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <ProgressSpinner />
    </div>

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
    <Toast />
  </span>
</template>

<script>
import { GroupModel } from "@/models/GroupModel";
import { RosterMessageModel } from "@/models/RosterMessageModel";
import { GroupMessageModel } from "@/models/GroupMessageModel";
import RostersApi from "@/apis/RostersApi";
//import ConfirmationDialog from "@/components/general/ConfirmationDialog";
import { validate, validations } from "@/validation";

export default {
  name: "SendRosterMessage",

  data() {
    return {
      groups: null,
      group: new GroupModel(),
      groupEmail: new GroupMessageModel(),
      rosterMessage: new RosterMessageModel(),
      groupId: null,
      groupOptions: null,
      subject: null,
      body: null,
      displayProgressDialog: false,
      dialogHeader: null,
      roster: null,
    };
  },
  created() {
    this.rostersApi = new RostersApi();
    if (this.$route.query.rosterId) {
      this.rosterId = parseInt(this.$route.query.rosterId);
    }
    this.rostersApi.getRoster(this.rosterId, (roster) => {
      this.roster = roster;
    });
  },
  mounted() {
    // this.getGroups();
  },

  methods: {
    // getGroups() {
    //   this.groupsApi.getGroups((data) => {
    //     this.groupOptions = responseToOptions(data);
    //   });
    // },
    sendEmail() {
      this.rosterMessage.recipId = 0;
      const errors = validate(this.rosterMessage, validations.rosterMessage);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.dialogHeader = "Sending Email";
      this.displayProgressDialog = true;
      this.rosterMessage.type = "sms";
      this.rostersApi.sendRosterMessage(this.rosterId, this.rosterMessage, () => {
        this.rosterMessage = new RosterMessageModel();
        this.groupId = null;
        this.$toast.add({
          severity: "success",
          summary: "Message Successfully Sent",
          life: 3000,
        });
        this.displayProgressDialog = false;
        this.dialogHeader = null;
        //this.$router.push("groupList")
      });
    },
    sendTestEmail() {
      //this.groupEmail.testEmail = this.testEmail;
      const errors = validate(this.rosterMessage, validations.testEmail);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.dialogHeader = "Sending Test Email";
      this.displayProgressDialog = true;
      this.groupsApi.sendTestEmail(this.rosterMessage, () => {
        this.$toast.add({
          severity: "success",
          summary: "Test Message Successfully Sent",
          life: 3000,
        });
        this.rosterMessage.testEmail = null;
        this.displayProgressDialog = false;
        this.dialogHeader = null;
      });
    },
  },
};
</script>

<style scoped></style>
