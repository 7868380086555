<template>
  <span>
  <DataTable :value="displayMessages" emptyMessage="No Messages Found" style="width: 75%" selectionMode="single" @row-select="onMessageSelect">
    <template #empty> No Messages Found </template>
    <template #header>
      <div class="p-fluid formgrid grid align-items-end vertical-container">
        <div class="field col-12 md:col-12">View Sent Messages</div>
        <div class="field col-12 md:col-3">
        <custom-label v-bind:label="'Group'" v-bind:required="true" />
        <Dropdown v-model="groupId" :options="groupOptions" optionLabel="name" optionValue="code" placeholder="Select a Group" />
        </div>
        <div class="field col-12 md:col-3">
          <custom-label v-bind:label="'Start Date'" v-bind:required="false" />
          <Calendar v-model="startDate" dateFormat="yy-mm-dd" input-class="p-inputtext p-component p-filled">
            <template #button-cancel>
              <Button class="p-button-text" icon="fas fa-times" label="Cancel" />
            </template>
            <template #button-confirm>
              <Button autofocus icon="fas fa-check" label="OK" />
            </template>
          </Calendar>
        </div>
        <div class="field col-12 md:col-3">
          <custom-label v-bind:label="'End Date'" v-bind:required="false" />
          <Calendar v-model="endDate" dateFormat="yy-mm-dd" input-class="p-inputtext p-component p-filled">
            <template #button-cancel>
              <Button class="p-button-text" icon="fas fa-times" label="Cancel" />
            </template>
            <template #button-confirm>
              <Button autofocus icon="fas fa-check" label="OK" />
            </template>
          </Calendar>
        </div>
        <div class="field col-12 md:col-3">
          <Button label="Search" @click="searchMessages" />
        </div>
      </div>
    </template>

    <Column :sortable="true" field="subject" header="Subject" headerStyle="text-align: left" />
    <Column :sortable="true" header="Date Sent" headerStyle="text-align: left">
      <template #body="slotProps">
        {{ fixDate(slotProps.data.date) }}
      </template>
    </Column>
    <Column :sortable="true" header="Message Type" headerStyle="text-align: left">
      <template #body="slotProps">
        {{ fixType(slotProps.data.type) }}
      </template>
    </Column>
<!--    <Column :sortable="true" header="Attendance" field="totalAttendance" headerStyle="text-align: left" />-->
<!--    <Column header="Actions" headerStyle="width: 8em; text-align: left" style="text-align: center">-->
<!--      <template #body="slotProps">-->
<!--        <Button-->
<!--            v-tooltip.top="'Edit Attendance'"-->
<!--            class="p-button-rounded p-button-text"-->
<!--            icon="pi pi-pencil"-->
<!--            @click="editAttendance(slotProps.data)"-->
<!--        />-->
<!--        &lt;!&ndash;   <Button icon="pi pi-check" @click="clickComplete(slotProps.data)"-->
<!--              class="p-button-rounded p-button-text" v-tooltip="'Mark Complete'"/>   &ndash;&gt;-->
<!--      </template>-->
<!--    </Column>-->
  </DataTable>
    <Toast />

    <display-message-dialog
        v-model="message"
        v-bind:display="displayMessageDialog"
        @close-message-dialog="clickCloseMessage"
    />
  </span>
</template>

<script>
import GroupsApi from "@/apis/GroupsApi";
import {formatDate, responseToOptions} from "@/functions";
import {validate, validations} from "@/validation";

export default {
  name: "ViewSentMessages",
  data() {
    return {
      groupOptions: null,
      startDate: null,
      endDate: null,
      groupId: null,
      allMessages: null,
      displayMessages: [],
      searchValues: [],
      displayMessageDialog: false,
    };
  },
  created() {
    this.groupsApi = new GroupsApi();
    if (this.$route.query.groupId) {
      this.groupId = parseInt(this.$route.query.groupId);
      this.searchMessages()
    }
  },
  mounted() {
    this.getGroups();
  },

  methods: {
    getGroups() {
      this.groupsApi.getGroups((data) => {
        this.groupOptions = responseToOptions(data);
      });
    },
    searchMessages() {
      this.searchValues.startDate = this.startDate;
      this.searchValues.endDate = this.endDate;
      this.searchValues.groupId = this.groupId;

      const errors = validate(this.searchValues, validations.searchGroupMessages);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.groupsApi.getSentMessages(this.groupId, this.startDate, this.endDate, (data) => {
        //this.allMessages = data;
        this.displayMessages = data;
        //console.log(this.displayMessages)
      });
    },
    fixDate(date) {
      return formatDate(date);
    },
    onMessageSelect(event) {
      this.message = event.data;
      this.displayMessageDialog = true;
    },
    clickCloseMessage() {
      this.displayMessageDialog = false;
    },
    fixType(type) {
      if (type == "email") {
        return "Email"
      }
      else {
        return "Text"
      }
    }
  }
}
</script>

<style scoped>

</style>