<template>
  <span>
    <ProgressSpinner v-if="!groups" />

    <DataTable v-if="groups" :value="groups" emptyMessage="No Services Found" style="width: 75%">
      <template #empty> No Groups Found </template>
      <template #header>
        <div class="p-fluid formgrid grid align-content-center">
          <div class="field col-12 sm:col-6">Groups</div>
          <div class="field col-12 sm:col-6 align-content-center">
            <Button icon="fas fa-plus" label="Add Group" @click="addNewGroup" />
          </div>
        </div>
      </template>

      <Column field="name" header="Group Name" headerStyle="text-align: left" />
      <Column header="Actions" headerStyle="width: 10em; text-align: left" style="text-align: center">
        <template #body="slotProps">
          <Button
            v-tooltip.top="'Edit Group'"
            class="p-button-rounded p-button-text"
            icon="fas fa-pencil"
            @click="editGroup(slotProps.data)"
          />
          <Button
            v-tooltip.top="'Delete Group'"
            class="p-button-rounded p-button-text"
            icon="fas fa-trash"
            @click="clickTrash(slotProps.data)"
          />
          <Button
            v-tooltip.top="'Send Email to Group'"
            class="p-button-rounded p-button-text"
            icon="fas fa-envelope"
            @click="sendMail(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <confirmation-dialog
      v-bind:display="displayDelConfirmation"
      v-bind:message="deleteMessage"
      v-bind:type="'Delete Group'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmDelete"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
    <Toast />
  </span>
</template>

<script>
import GroupsApi from "@/apis/GroupsApi";
import {GroupModel} from "@/models/GroupModel";

export default {
  name: "GroupList",
  data() {
    return {
      groups: null,
      displayDelConfirmation: false,
      displayProgressDialog: false,
      dialogHeader: null,
      displayGroupDialog: false,
      //displayGroupMembersDialog: false,
      group: null,
      groupToDelete: [],
      action: null,
      deleteMessage: null
    };
  },
  created() {
    this.groupsApi = new GroupsApi();
  },
  mounted() {
    this.getGroups();
    if (this.$route.query.success) {
      this.$toast.add({
        severity: "success",
        summary: "Message Sent Successfully",
        life: 3000
      });
    }
  },

  methods: {
    getGroups() {
      this.groupsApi.getGroups((data) => {
        this.groups = data;
      });
    },
    confirmCancel() {
      this.displayDelConfirmation = false;
    },
    confirmDelete() {
      this.clickDeleteGroup(this.groupToDelete);
      this.displayDelConfirmation = false;
      this.groupToDelete = [];
    },
    clickTrash(group) {
      this.groupToDelete = group;
      this.deleteMessage = "Are you sure you want to delete " + group.name + "?";
      this.displayDelConfirmation = true;
    },
    addNewGroup() {
      this.$router.push("/group/add");
    },

    clickDeleteGroup(group) {
      this.dialogHeader = "Deleting Group";
      this.displayProgressDialog = true;
      this.groupsApi.deleteGroup(group.id, () => {
        this.groups = this.groups.slice().filter((n) => n.id !== group.id);
        this.group = new GroupModel();
        this.displayGroupDialog = false;
        //this.displayGroupMembersDialog = false
        this.displayProgressDialog = false;
        this.dialogHeader = null;
      });
    },
    editGroup(data) {
      this.$router.push("/group/edit?groupId=" + data.id);
    },

    sendMail(data) {
      this.$router.push("/groupEmail?groupId=" + data.id);
    }
  }
};
</script>
