<template>
  <Card style="padding: 1%">
    <template #title> Social Media Accounts </template>
    <template #content>
      <ul v-if="value.length !== 0">
        <li v-for="item in value" :key="item.id">
          <b>{{ item.description }}:</b> &nbsp;
          <a :href="item.link">{{ item.link }}</a>
        </li>
      </ul>
      <div v-if="value.length === 0" style="padding: 2%">No Social Media Accounts Listed</div>
    </template>
  </Card>
</template>

<script>
export default {
  name: "SocialMediaList",
  props: ["value"],
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.p-card /deep/ .p-card-body {
  padding-bottom: 1px;
}

.p-card /deep/ .p-card-content {
  padding-bottom: 1rem;
  padding-top: 0px;
}
</style>
