<template>
  <div>
    <div>{{ value.address1 }}</div>
    <div>{{ value.address2 || "" }}</div>
    {{ value.city }},&nbsp;{{ value.state }}&nbsp;
    {{ value.zipcode }}
  </div>
</template>

<script>
export default {
  name: "ViewAddress",
  props: ["value"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
