export class HouseholdModel {
  constructor() {
    this.id = null;
    this.churchId = null;
    this.lastName = null;
    this.address1 = null;
    this.address2 = null;
    this.city = null;
    this.state = null;
    this.zipCode = null;
    this.latitude = null;
    this.longitude = null;
    this.members = [];
  }
}
