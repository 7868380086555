<template>
  <LandingPage v-if="loggedIn" />
  <!--  <Signup v-else="!loggedIn"/>-->
  <div v-else>
    <div class="surface-section px-2 py-4 md:px-6 lg:px-8 text-center">
      <div class="mb-3 font-bold text-2xl">
        <span class="text-900">Welcome to Steeplesoft!</span>
      </div>
      <div>
        This is a dummy home page. This should probably have some marketing drivel and bunch of other stuff to fill up the page. At
        some point, we will tell the user to click <a style="text-decoration: underline" @click="login">here</a> to log on.
      </div>
      <div class="text-500 text-sm mb-6"></div>
      <div class="text-700 text-sm mb-6"></div>
      <div class="grid">
        <div class="col-offset-2 col-4">
          <Button class="p-3 shadow-2 mb-3 inline-block surface-card outline-none" style="border-radius: 10px">
            <a style="text-decoration: underline" @click="login">
              <i class="fas fa-sign-in text-4xl blue-fg" />
            </a>
          </Button>
          <div class="text-900 mb-3 font-medium">Sign In</div>
          <span class="text-700 text-sm line-height-3">Log in to an existing church account</span>
        </div>
        <div class="col-4">
          <Button class="p-3 shadow-2 mb-3 inline-block surface-card outline-none" style="border-radius: 10px">
            <router-link to="/signup">
              <i class="fas fa-user-plus text-4xl blue-fg"></i>
            </router-link>
          </Button>
          <div class="text-900 mb-3 font-medium">Sign Up</div>
          <span class="text-700 text-sm line-height-3">Create a new church account</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LandingPage from "@/components/LandingPage";
import Signup from "@/components/signup/Signup";
import { authenticated } from "@/functions";

export default {
  name: "Home",
  components: { Signup, LandingPage },
  data() {
    return {
      loggedIn: false,
    };
  },
  mounted() {
    this.loggedIn = authenticated();
  },
  methods: {
    login() {
        this.$router.push("/login");
    },
  },
};
</script>

<style scoped></style>
