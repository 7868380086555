import { request } from "@/functions";

export default class UsersApi {
  getUsers(successCallback) {
    request("get", "/users", null, successCallback);
  }

  getUser(id, successCallback) {
    request("get", "/users/" + id, null, successCallback);
  }

  addUser(user, successCallback) {
    request("POST", "/users", user, successCallback);
  }

  updateUser(user, successCallback) {
    request("PUT", "/users/" + user.id, user, successCallback);
  }

  deleteUser(id, successCallback) {
    request("DELETE", "/users/" + id, null, successCallback);
  }
}
