<template>
  <span>
    <ProgressSpinner v-if="!displayRosters" />
    <DataTable v-if="displayRosters" :value="displayRosters" emptyMessage="No Rosters Found" style="width: 75%">
      <template #empty> No Rosters Found </template>
      <template #header>
        <div v-if="ministryOptions" class="p-fluid formgrid grid align-items-end vertical-container">
          <div class="field col-12 md:col-12">Record Activity Attendance</div>
          <div class="field col-12 md:col-3">
            <custom-label v-bind:label="'Ministry'" v-bind:required="false" />
            <Dropdown
              v-if="ministryOptions"
              id="ministries"
              v-model="ministryId"
              :options="ministryOptions"
              optionLabel="name"
              optionValue="code"
              placeholder="Filter by Ministry"
              @change="ministryChanged"
            />
          </div>
          <div class="field col-12 md:col-3">
            <custom-label v-bind:label="'Activity'" v-bind:required="false" />
            <Dropdown
              id="activities"
              v-model="activityId"
              :options="activityOptions"
              optionLabel="name"
              optionValue="code"
              placeholder="Select an Activity"
            />
          </div>
          <div class="field col-12 md:col-3">
            <Button label="View All" @click="clickGetAllRosters" />
          </div>
        </div>
      </template>

      <Column :sortable="true" field="roster.name" header="Roster Name" headerStyle="text-align: left" />
      <Column :sortable="false" header="Date" headerStyle="text-align: left">
        <template #body="slotProps">
          {{ fixDate(slotProps.data.calendar.eventDate) }}
        </template>
      </Column>

      <Column header="Actions" headerStyle="width: 8em; text-align: left" style="text-align: center">
        <template #body="slotProps">
          <Button
            v-tooltip.top="'Add Attendance'"
            class="p-button-rounded p-button-text"
            icon="fas fa-plus"
            @click="addActivityAttendance(slotProps.data)"
          />
          <Button
            v-tooltip.top="'Mark Complete'"
            class="p-button-rounded p-button-text"
            icon="fas fa-check"
            @click="clickComplete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <activity-attendance-dialog
      v-bind:value="rosterAttendance"
      v-bind:display="displayAttendanceDialog"
      @save-attendance="clickAddAttendance"
      @cancel-attendance="clickCancelAttendance"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />

    <confirmation-dialog
      v-bind:display="displayConfirmation"
      v-bind:message="confirmationMessage"
      v-bind:type="'Empty Attendance'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmAdd"
    />
  </span>
</template>

<script>
//import ProgressSpinner from 'primevue/progressspinner';
import ActivitiesApi from "@/apis/ActivitiesApi";
import AttendanceApi from "@/apis/AttendanceApi";
import MinistriesApi from "@/apis/MinistriesApi";
import { AttendanceInputModel } from "@/models/AttendanceInputModel";
import { formatDate, responseToOptions } from "@/functions";

export default {
  data() {
    return {
      activityId: null,
      activityOptions: null,
      confirmationMessage: null,
      dialogHeader: null,
      displayAttendanceDialog: false,
      displayConfirmation: false,
      displayProgressDialog: false,
      displayRosters: null,
      ministryId: null,
      ministryOptions: null,
      rosterAttendance: null,
      sendAttendees: null,
      sendRosterAttendance: null,
    };
  },
  created() {
    this.attendanceApi = new AttendanceApi();
    this.ministriesApi = new MinistriesApi();
    this.activitiesApi = new ActivitiesApi();
    this.getRosters();
  },
  mounted() {
    this.ministriesApi.getMinistriesList((data) => {
      this.ministryOptions = responseToOptions(data);
    });
  },
  watch: {
    ministryId() {
      //   this.getRosters();
      //   if (this.ministryId) {
      //     this.activityId = null;
      //     this.activitiesApi.getActivitiesList(this.ministryId, (data) => (this.activityOptions = responseToOptions(data)));
      //   }
    },
    activityId() {
      this.getRosters();
    },
  },

  methods: {
    ministryChanged() {
      this.getRosters();
      if (this.ministryId) {
        this.activityId = null;
        this.activitiesApi.getActivitiesList(this.ministryId, (data) => (this.activityOptions = responseToOptions(data)));
      }
    },
    clickComplete(rosterAttendance) {
      this.dialogHeader = "Marking Attendance Complete";
      this.displayProgressDialog = true;

      let attendanceRecord = new AttendanceInputModel();
      attendanceRecord.attendanceId = rosterAttendance.id;
      attendanceRecord.completed = true;
      this.attendanceApi.updateAttendance(attendanceRecord, () => {
        this.getRosters();
        this.dialogHeader = null;
        this.displayProgressDialog = false;
      });
    },
    addAttendance(data) {
      this.rosterAttendance = { ...data };
      this.rosterAttendance.totalAttendance = null;
      this.displayAttendanceDialog = true;
    },
    addActivityAttendance(data) {
      this.rosterAttendance = { ...data };
      this.displayAttendanceDialog = true;
      // this.$router.push("/attendance/add?rosterId=" + data.roster.id + "&attendanceId=" + data.id);
    },
    clickAddAttendance(rosterAttendance, attendees) {
      if (attendees.length != 0) {
        this.sendAttendance(rosterAttendance, attendees);
      } else {
        this.confirmationMessage = "There are no members listed as present.  Do you wish to continue?";
        this.sendRosterAttendance = rosterAttendance;
        this.sendAttendees = attendees;
        this.displayConfirmation = true;
      }
    },
    confirmCancel() {
      this.displayConfirmation = false;
      this.sendRosterAttendance = null;
      this.sendAttendees = null;
    },
    confirmAdd() {
      this.sendAttendance(this.sendRosterAttendance, this.sendAttendees);
      this.displayConfirmation = false;
    },
    clickCancelAttendance() {
      this.displayAttendanceDialog = false;
    },
    fixDate(date) {
      return formatDate(date);
    },

    clickGetAllRosters() {
      this.ministryId = null;
      this.activityId = null;
      this.getRosters();
    },
    getRosters() {
      this.displayRosters = null;
      this.attendanceApi.getPendingRosters(this.ministryId, this.activityId, (data) => {
        // this.allRosters = data;
        this.allRosters = data.sort((a, b) => (a.calendar.eventDate > b.calendar.eventDate ? 1 : -1));
        this.displayRosters = this.allRosters;
        this.displayRosters = this.allRosters;
      });
    },
    sendAttendance(rosterAttendance, attendees) {
      this.dialogHeader = "Adding Attendance";
      this.displayProgressDialog = true;

      let attendanceRecord = new AttendanceInputModel();
      attendanceRecord.attendanceId = rosterAttendance.id;
      attendanceRecord.rosterId = rosterAttendance.roster.id;
      attendanceRecord.attendees = attendees.map((it) => it.individual.id);
      attendanceRecord.completed = true;
      this.rosterAttendance.completed = true;

      this.attendanceApi.updateAttendance(attendanceRecord, () => {
        //this.getRosters();
        let i = this.displayRosters.map((item) => item.id).indexOf(attendanceRecord.attendanceId); // find index of your object
        this.displayRosters.splice(i, 1); // remove it from array
        this.dialogHeader = null;
        this.displayProgressDialog = false;
        this.displayAttendanceDialog = false;
      });
    },
  },
};
</script>

<style scoped></style>
