<template>
  <div class="layout-top steeplesoft-bg" style="display: flex;">
    <div style="flex-grow: 1; text-align: left; padding-left: 2%">
      <h3>
        <router-link href="/" style="color: #ffffff; text-decoration: none" to="/" title="Home">
          <img v-bind:src="require('@/assets/logo_white.png')"
               alt="logo"
               width="35" />
          Steeplesoft
        </router-link>
      </h3>
    </div>
    <div class="col-12 md:col-2" style="flex-grow: 0; text-align: right">
      <div v-if="$store.state.authenticated" class="p-inputgroup flex">
        <InputText id="searchText" v-model="searchText" @keyup.enter="sendSearch" placeholder="People Search" />
        <Button class1="p-inputgroup-addon" icon="fas fa-search" type="submit" @click="sendSearch()" />
        <Divider layout="vertical" />
        <Button class1="p-inputgroup-addon" icon="fas fa-sign-out" type="submit" @click="logout()" title="Logout" />
      </div>
    </div>
  </div>
</template>

<script>
import AuthenticationApi from "@/apis/AuthenticationApi";

export default {
  name: "NavBar",
  data() {
    return {
      searchText: "",
      checked: false,
      title: "Check me"
    };
  },
  authApi: null,
  created() {
    this.authApi = new AuthenticationApi();
  },
  methods: {
    logout() {
      this.authApi.logout();
      this.$router.push("/logout");
    },
    sendSearch() {
      if (this.searchText) {
        let searchArray = this.searchText.split(" ");
        let firstName, lastName, searchParams;
        if (searchArray.length === 1) {
          searchParams = {
            lastName: this.searchText,
            firstName: this.searchText,
            address: this.searchText,
            city: this.searchText,
            zip: this.searchText,
            match: "any",
            hash: "#" + firstName,
            replace: true
          };
        } else {
          firstName = searchArray[0];
          lastName = searchArray[1];
          searchParams = {
            lastName: searchArray[1],
            firstName: searchArray[0],
            match: "all",
            hash: "#" + firstName,
            replace: true
          };
        }
        //this.$router.go()
        let currentRoute = this.$router.currentRoute.value.name;
        console.log("current route = " + currentRoute);
        if (currentRoute === "individuals") {
          // this.$router.go(-1);
        }
        this.$router.push({
          name: "individuals",
          query: searchParams
        });
        //this.$router.go()
        this.searchText = "";
      }
    }
  }
};
</script>

<style scoped>
h2 {
  font-size: 40px;
  color: mediumseagreen;
}

.steeplesoft-bg {
  background-color: #235784;
}
</style>
