<template>
  <span>
    <Dialog :closable="false" :header="type" :modal="true" :style="{ width: '500px' }" :visible.sync="display">
      <div class="confirmation-content">
        <i class="fas fa-exclamation-triangle p-mr-3" style="font-size: 2rem; color: red" />
        <span>{{ message }}</span>
      </div>
      <template #footer>
        <Button class="p-button-text" icon="fas fa-times" label="No" @click="click('no')" />
        <Button autofocus icon="fas fa-check" label="Yes" @click="click('yes')" />
      </template>
    </Dialog>
  </span>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: ["display", "action", "message", "type"],
  emits: ["confirm-yes", "confirm-no"],
  data() {
    return {};
  },
  methods: {
    click(operation) {
      this.$emit("confirm-" + operation);
    },
  },
};
</script>
