<template>
  <span>
    <ProgressSpinner v-if="!services" />
    <DataTable v-if="services" :value="services" emptyMessage="No Services Found" style="width: 90%">
      <template #empty> No Services Found </template>
      <template #header>
        <div class="p-fluid formgrid grid align-items-end vertical-container">
          <div class="field col-12 md:col-8">Services</div>
          <div class="field col-12 md:col-4 align-content-center">
            <Button icon="fas fa-plus" label="Add Service" @click="addNewService()" />
          </div>
        </div>
      </template>

      <Column header="Name" headerStyle="text-align: left">
        <template #body="slotProps">
          <span style="white-space: nowrap">{{ slotProps.data.name }}</span>
        </template>
      </Column>
      <Column header="Service Time" headerStyle="text-align: left">
        <template #body="slotProps">
          <span style="white-space: nowrap">{{ formattedTime(slotProps.data.serviceTime) }}</span>
        </template>
      </Column>
      <Column header="Recurs" headerStyle="text-align: left">
        <template #body="slotProps">
          {{ formattedRecurs(slotProps.data) }}
        </template>
      </Column>
      <Column header="Location" headerStyle="text-align: left">
        <template #body="slotProps">
          <div v-if="slotProps.data.location">
            {{ slotProps.data.location.name }}
          </div>
        </template>
      </Column>
      <Column header="Actions" headerStyle="width: 15%; text-align: center" style="text-align: left">
        <template #body="slotProps">
          <Button
            v-tooltip.top="'Edit ' + slotProps.data.name"
            class="p-button-rounded p-button-text"
            icon="fas fa-pencil"
            @click="onServiceSelect(slotProps.data)"
          />
          <Button
            v-tooltip.top="'Delete ' + slotProps.data.name"
            class="p-button-rounded p-button-text"
            icon="fas fa-trash"
            @click="clickTrash(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <confirmation-dialog
      v-bind:display="displayDelConfirmation"
      v-bind:message="deleteMessage"
      v-bind:type="'Delete Service'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmDelete"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
  </span>
</template>

<script>
import LocationsApi from "@/apis/LocationsApi";
import ServicesApi from "@/apis/ServicesApi";
import { ServiceModel } from "@/models/ServiceModel";
import { recurrenceOptions } from "@/constants";
import { formatRecurs, formatTime, responseToOptions } from "@/functions";

export default {
  data() {
    return {
      services: null,
      service: new ServiceModel(),
      recurs: recurrenceOptions,
      locations: null,
      locationOptions: null,
      displayDelConfirmation: false,
      serviceToDelete: [],
      dialogHeader: null,
      displayProgressDialog: false,
      servicesApi: null,
      locationsApi: null,
      deleteMessage: null,
    };
  },
  created() {
    this.servicesApi = new ServicesApi();
    this.locationsApi = new LocationsApi();
    this.ministryId = parseInt(this.$route.query.ministryId);
  },
  mounted() {
    this.servicesApi.getServicesList((data) => {
      this.services = data;
    });
    this.locationsApi.getLocationsList((data) => {
      this.locations = data;
      this.locationOptions = responseToOptions(data);
    });
  },

  methods: {
    formattedTime(data) {
      return formatTime(data);
    },
    formattedRecurs(data) {
      return formatRecurs(data);
    },
    onServiceSelect(data) {
      this.$router.push("/service/edit?serviceId=" + data.id);
    },
    addNewService() {
      this.$router.push("/service/add");
    },
    clickDeleteService(service) {
      this.dialogHeader = "Deleting Service";
      this.displayProgressDialog = true;
      this.servicesApi.deleteService(service.id, () => {
        this.services = this.services.slice().filter((n) => n.id !== service.id);
        this.service = new ServiceModel();
        this.displayServiceDialog = false;
        this.displayProgressDialog = false;
        this.dialogHeader = null;
      });
    },
    clickTrash(service) {
      this.serviceToDelete = service;
      this.deleteMessage = "Are you sure you want to delete " + service.name + "?";
      console.log(service);
      this.displayDelConfirmation = true;
    },
    confirmCancel() {
      this.displayDelConfirmation = false;
    },
    confirmDelete() {
      this.clickDeleteService(this.serviceToDelete);
      this.displayDelConfirmation = false;
      this.serviceToDelete = [];
    },
  },
};
</script>

<style scoped></style>
