<template>
  <span
    style="
      display: block;
      font-weight: normal;
      pointer-events: none;
      top: 50%;
      margin-top: -0.5em;
      color: #898989;
      line-height: 1.5;
    "
  >
    {{ label || "&nbsp;" }}
    <span v-if="required" style="color: #ff0000" v-tooltip.top="'Required'">&#9733;</span>
  </span>
</template>

<script>
export default {
  name: "CustomLabel",
  props: ["required", "label"],
};
</script>
