<template>
  <DataTable v-model:selection="selectedHouseholdMember" :value="value" selectionMode="single" @row-select="clickMember">
    <template #empty>
      <div v-if="!value && !value.length">
        <ProgressSpinner />
      </div>
      <div v-else>No Household Members found</div>
    </template>
    <Column header="Name" headerStyle="text-align: left">
      <template #body="slotProps">
        {{ firstName(slotProps.data) }}
      </template>
    </Column>

    <Column header="Birthdate" headerStyle="text-align: left; width: 30%">
      <template #body="slotProps">
        {{ displayDate(slotProps.data.dateOfBirth) }}
      </template>
    </Column>
    <Column header="Age" headerStyle="text-align: left; width: 30%">
      <template #body="slotProps">
        {{ calculateAge(slotProps.data.dateOfBirth) }}
      </template>
    </Column>
  </DataTable>
</template>

<script>
import {calculateAge, formatDate} from "@/functions";

export default {
  name: "HouseholdMemberList",
  props: ["value"],
  emits: ["click-member"],
  data() {
    return {
      selectedHouseholdMember: null,
      members: null,
    };
  },
  methods: {
    onHouseholdMemberSelect(event) {
      this.$router.push("/individual?id=" + event.data.id);
    },
    firstName: function (individual) {
      if (individual.lastName === individual.household.lastName) {
        return individual.firstName;
      } else {
        return individual.firstName + " " + individual.lastName;
      }
    },
    displayGender: function (gender) {
      if (gender === "M") {
        return "Male";
      } else {
        return "Female";
      }
    },
    displayDate: function (dateOfBirth) {
      return formatDate(dateOfBirth);
    },
    calculateAge: function (date) {
      return calculateAge(date);
    },
    clickMember(event) {
      this.$emit("click-member", event.data);
    },
  },
};
</script>

<style scoped></style>
