<template>
  <h1>You have been logged out.</h1>
  Click
  <!--  <router-link to="/login">here</router-link> -->
  <a style="text-decoration: underline" @click="login">here</a>
  to log back in.
</template>

<script>

export default {
  name: "Logout",
  methods: {
    login() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped></style>
