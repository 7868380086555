<template>
  <InputMask ref="phoneNumber" v-model="phoneNumber" mask="(999) 999-9999" placeholder="" @input="update()" />
</template>

<script>
export default {
  name: "PhoneNumber",
  props: ["value"],
  emits: ["input"],
  data() {
    return {
      phoneNumber: null,
    };
  },
  created() {
    this.phoneNumber = this.value;
  },
  methods: {
    update() {
      this.$emit("input", this.phoneNumber);
    },
  },
};
</script>

<style scoped></style>
