<template>
  <span>
    <ProgressSpinner v-if="!users" />

    <DataTable v-if="users" :value="users" style="width: 65%">
      <template #empty> No Users Found </template>
      <template #header>
        <div class="p-fluid formgrid grid align-items-end vertical-container">
          <div class="field col-12 md:col-8">Users</div>
          <div class="field col-12 md:col-4">
            <Button icon="fas fa-plus" label="Add User" @click="addNewUser" />
          </div>
        </div>
      </template>
      <Column header="Name" headerStyle="width: 12em; text-align: left">
        <template #body="slotProps"> {{ slotProps.data.firstName }} {{ slotProps.data.lastName }} </template>
      </Column>
      <Column field="emailAddress" header="Email Address" headerStyle="textAlign: left" />
      <Column field="role" header="Role" headerStyle="textAlign: left" />

      <Column header="Actions" headerStyle="width: 20%; textAlign: left">
        <template #body="slotProps">
          <Button
            v-tooltip.top="'Edit ' + slotProps.data.firstName + ' ' + slotProps.data.lastName"
            class="p-button-rounded p-button-text"
            icon="fas fa-pencil"
            @click="onUserSelect(slotProps.data)"
          />
          <Button
            v-tooltip.top="'Delete ' + slotProps.data.firstName + ' ' + slotProps.data.lastName"
            class="p-button-rounded p-button-text"
            icon="fas fa-trash"
            @click="clickTrash(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <user-dialog
      v-model="user"
      v-bind:action="action"
      v-bind:display="displayUserDialog"
      @save-user="clickSaveUser"
      @add-user="clickAddUser"
      @delete-user="clickDeleteUser"
      @cancel-user="clickCancelUser"
    />

    <confirmation-dialog
      v-bind:display="displayDelConfirmation"
      v-bind:message="deleteMessage"
      v-bind:type="'Delete User'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmDelete"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
  </span>
</template>

<script>
import UsersApi from "@/apis/UsersApi";
import {UserModel} from "@/models/UserModel";

export default {
  data() {
    return {
      users: null,
      action: null,
      displayUserDialog: false,
      user: new UserModel(),
      userToDelete: [],
      displayDelConfirmation: false,
      displayProgressDialog: false,
      dialogHeader: null,
      deleteMessage: null,
      usersApi: null,
    };
  },
  created() {
    this.usersApi = new UsersApi();
  },
  mounted() {
    this.usersApi.getUsers((data) => (this.users = data));
  },
  methods: {
    onUserSelect(data) {
      this.$router.push("/users/edit?userId=" + data.id);
      // this.action = "edit";
      // this.user = { ...data };
      // this.displayUserDialog = true;
    },
    addNewUser() {
      this.$router.push("/users/add");

      // this.action = "add";
      // this.user = new UserModel();
      // this.displayUserDialog = true;
    },
    clickCancelUser() {
      this.displayUserDialog = false;
      this.user = new UserModel();
    },
    clickAddUser(user) {
      this.dialogHeader = "Adding User";
      this.displayProgressDialog = true;
      this.usersApi.addUser(user, (data) => {
        this.users.push(data);
        this.user = new UserModel();
        this.displayUserDialog = false;
        this.displayProgressDialog = false;
        this.dialogHeader = null;
      });
    },
    clickSaveUser(user) {
      this.dialogHeader = "Updating User";
      this.displayProgressDialog = true;
      this.usersApi.updateUser(user, () => {
        let index = this.users.findIndex((a) => a.id === user.id);
        this.users[index] = user;
        this.displayUserDialog = false;
        this.user = new UserModel();
        this.displayProgressDialog = false;
        this.dialogHeader = null;
      });
    },
    clickDeleteUser(user) {
      this.dialogHeader = "Deleting User";
      this.displayProgressDialog = true;
      this.usersApi.deleteUser(user.id, () => {
        this.users = this.users.slice().filter((n) => n.id !== user.id);
        this.user = new UserModel();
        this.displayUserDialog = false;
        this.displayProgressDialog = false;
        this.dialogHeader = null;
      });
    },
    clickTrash(user) {
      this.userToDelete = user;
      this.deleteMessage = "Are you sure you want to delete " + user.firstName + " " + user.lastName + "?";
      this.displayDelConfirmation = true;
    },
    confirmCancel() {
      this.displayDelConfirmation = false;
    },
    confirmDelete() {
      this.clickDeleteUser(this.userToDelete);
      this.displayDelConfirmation = false;
      this.userToDelete = [];
    },
  },
};
</script>
