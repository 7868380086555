<template>
  <div>
    <ProgressSpinner v-if="!individual" />
    <Card v-if="individual">
      <template #title>
        <div class="grid">
          <div class="col-7" style="text-align: left">
            <IndividualNameDisplay :key="individual" :value="individual" />
          </div>
          <div class="col-5" style="text-align: right">
            <Button v-tooltip.top="'Edit ' + individual.firstName + ' ' + getLastName()" icon="fas fa-pencil" @click="edit" />
            &nbsp;&nbsp;
            <Button v-tooltip.top="'View ' + individual.household.lastName + ' Household'" icon="fas fa-home" @click="viewHousehold" />
            &nbsp;&nbsp;
            <Button v-tooltip.top="'Delete ' + individual.firstName + ' ' + getLastName()" icon="fas fa-trash" @click="clickTrash" />
          </div>
        </div>
      </template>
      <template #content>
        <div>
          <div class="grid">
            <div class="lg:col-7 md:col-12">
              <div class="grid">
                <div class="col-5" style="text-align: center">
                  <img :src="individual.photo" alt="profile pic" style="width: 100%" />
                  <div v-if="individual.dateOfBirth">
                    {{ formatDate(individual.dateOfBirth) }}
                    <span style="font-size: 20px">&nbsp;&nbsp;&#8226;</span>&nbsp;&nbsp;Age&nbsp;
                    {{ age() }}
                  </div>

                  <AddressList :key="individual" :value="individual.household" />

                  <br />
                  <PhoneNumberList
                      :key="individual"
                    :value="individual.phoneNumbers"
                    v-bind:preferred="preferredNumber"
                    v-bind:primary="primaryNumber"
                    v-bind:secondary="secondaryNumber"
                  />
                </div>
                <div class="col-7">
                  <IndividualAdditionalInfoDisplay :key="individual" :value="individual" />
                  <br />
                  <SocialMediaList :key="individual.id" :value="individual.socialMediaAccounts" />
                  <br />
                  <AssignmentsList :key="individual" v-model="individualAssignments" :individual="individual.id" />
                </div>
              </div>
            </div>
            <div class="lg:col-5 md:col-12">
              <Card style="padding: 1%">
                <template #title>
                  Household Members
                  <div style="position: relative; float: right">
                    <Button
                      v-tooltip.top="'Add Member'"
                      class="p-button-rounded p-button-text"
                      icon="fas fa-plus"
                      @click="addMemberClicked"
                    />
                  </div>
                </template>
                <template #content>
                  <div v-if="household">
                    <HouseholdMemberList :key="individual" v-if="household" :value="household.members" @click-member="clickHouseholdMember" />
                    <br />
                  </div>
                  <div v-else>
                    <ProgressSpinner />
                  </div>
                </template>
              </Card>
              <br />
              <IndividualNotes :key="individual" :value="individual" />
            </div>
          </div>
        </div>
      </template>
    </Card>

    <confirmation-dialog
      v-bind:display="displayDelConfirmation"
      v-bind:message="deleteMessage"
      v-bind:type="'Delete Individual'"
      @confirm-no="confirmCancel"
      @confirm-yes="confirmDelete"
    />

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />

    <!--    <household-dialog-->
    <!--        v-bind:display="displayHouseholdDialog"-->
    <!--        v-bind:action="action"-->
    <!--        :value="householdToEdit"-->
    <!--        v-bind:delButton="'false'"-->
    <!--        @save-household="clickSaveHousehold"-->
    <!--        @cancel-household="clickCancelHousehold"-->
    <!--    />-->
  </div>
</template>

<script>
//import HouseholdDialog from "@/components/households/HouseholdDialog";
import HouseholdApi from "@/apis/HouseholdApi";
import IndividualsApi from "@/apis/IndividualsApi";
import {HouseholdModel} from "@/models/HouseholdModel";
import {calculateAge, formatDate} from "@/functions";

export default {
  name: "Individual",
  data() {
    return {
      action: null,
      deleteMessage: null,
      dialogHeader: null,
      displayBlockScroll: false,
      displayDelConfirmation: false,
      displayProgressDialog: false,
      household: null,
      householdApi: null,
      householdToEdit: new HouseholdModel(),
      individual: null,
      individualAssignments: [],
      individualId: null,
      individualToDelete: [],
      individualsApi: null,
    };
  },
  created() {
    this.individualId = this.$route.query.id;
    this.individualsApi = new IndividualsApi();
    this.householdApi = new HouseholdApi();
  },
  mounted() {
    this.individualsApi.getIndividual(this.individualId, (data) => {
      this.individual = data;
      this.individual.notes = [];
      this.individual.notes.push({ id: "1", note: "Note 1", date: new Date() });
      this.individual.notes.push({ id: "2", note: "Note 2", date: new Date() });
      this.individual.notes.push({ id: "3", note: "Note 3", date: new Date() });
      
      this.loadHousehold(data.householdId);
    });
    this.individualsApi.getIndividualAssignments(this.individualId, (data) => {
      this.individualAssignments = data;
    });
  },
  methods: {
    loadHousehold: function (householdId) {
      this.householdApi.getHousehold(householdId, (household) => this.onHouseholdLoad(household));
    },
    onHouseholdLoad: function (household) {
      this.household = household;
    },
    primaryNumber: function (item) {
      return item.number;
    },
    preferredNumber: function (item) {
      return item.preferred;
    },
    secondaryNumber: function (item) {
      return item.type.toLowerCase().charAt(0).toUpperCase() + item.type.toLowerCase().slice(1);
    },
    getLastName: function () {
      return this.individual.lastName !== null ? this.individual.lastName : this.individual.household.lastName;
    },
    age() {
      return calculateAge(this.individual.dateOfBirth);
    },
    formatDate: function (date) {
      return formatDate(date);
    },
    addMemberClicked: function () {
      console.log({ householdId: this.individual.household.id });
      this.$router.push("/individual/add?householdId=" + this.individual.household.id);
    },
    viewHousehold: function () {
      this.$router.push("/household?id=" + this.individual.household.id);
    },
    confirmCancel() {
      this.displayDelConfirmation = false;
    },
    confirmDelete() {
      this.clickDeleteIndividual(this.individualToDelete);
      this.displayDelConfirmation = false;
      this.individualToDelete = [];
    },
    clickTrash() {
      this.individualToDelete = this.individual;
      this.deleteMessage = "Are you sure you want to delete " + this.individual.firstName + "?";
      this.displayDelConfirmation = true;
    },
    clickDeleteIndividual(individual) {
      this.dialogHeader = "Deleting Individual";
      this.displayProgressDialog = true;
      this.individualsApi.deleteIndividual(individual.id, () => {
        this.displayProgressDialog = false;
        this.dialogHeader = null;
        this.$router.push("/household?id=" + this.individual.household.id);
      });
    },
    clickHouseholdMember(member) {
      this.individualsApi.getIndividual(member.id, (data) => {
        this.individual = data;
        this.householdApi.getHousehold(data.householdId, (household) => this.onHouseholdLoad(household));
      });
    },
    edit() {
      this.$router.push("/individual/edit?id=" + this.individual.id);
    },
  },
};
</script>
