<template>
  <div>
    <ProgressSpinner v-if="!user" />
    <span v-if="user">
      <Card style="width: 45rem; margin-bottom: 2em">
        <template #title>
          <div v-if="!userId" v-once>Add User</div>
          <div v-else v-once>Edit {{ user.firstName }} {{ user.lastName }}</div>
        </template>
        <template #content>
          <div class="grid p-fluid">
            <div class="col-6" style="padding: 0.5em">
              <custom-label v-bind:label="'First Name'" v-bind:required="true" />
              <InputText id="firstname" v-model="user.firstName" type="text" />
            </div>
            <div class="col-5" style="padding: 0.5em">
              <custom-label v-bind:label="'Last Name'" v-bind:required="true" />
              <InputText id="lastname" v-model="user.lastName" type="text" />
            </div>
            <div class="col-6" style="padding: 0.5em">
              <custom-label v-bind:label="'Email Address'" v-bind:required="true" />
              <InputText id="email" v-model="user.emailAddress" type="text" />
            </div>
            <div class="col-4" style="padding: 0.5em">
              <custom-label v-bind:label="'Role'" v-bind:required="true" />
              <Dropdown v-model="user.role" :options="userRoles" optionLabel="label" optionValue="value" />
            </div>
            <div class="col-6" style="padding: 0.5em">
              <custom-label v-bind:label="'Password'" v-bind:required="action === 'add'" />
              <Password v-model="password" />
            </div>
            <div class="col-6" style="padding: 0.5em">
              <custom-label v-bind:label="'Re-Enter Password'" v-bind:required="action === 'add'" />
              <Password v-model="password2" />
            </div>
          </div>
        </template>
        <template #footer>
          <div v-if="!userId">
            <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="clickCancel" />
            <Button autofocus icon="fas fa-check" label="Add" @click="clickSave" />
          </div>
          <div v-else>
            <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="clickCancel" />
            <Button class="p-button-text" icon="fas fa-trash" label="Delete" @click="clickDelete" />
            <Button autofocus icon="fas fa-check" label="Save" @click="clickSave" />
          </div>
        </template>
      </Card>
      <Toast />

      <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
    </span>
  </div>
</template>

<script>
import { validate, validations } from "@/validation";
import { UserModel } from "@/models/UserModel";
import UsersApi from "@/apis/UsersApi";

export default {
  name: "ManageUser",
  data() {
    return {
      user: null,
      userId: null,
      usersApi: null,
      dialogHeader: null,
      password: null,
      password2: null,
      userRoles: [
        { value: "ADMIN", label: "Admin" },
        { value: "USER", label: "User" },
        { value: "CHECKIN_USER", label: "Checkin User" }
      ],
      displayProgressDialog: false,
      editMode: true
    };
  },
  created() {
    this.userId = this.$route.query.userId;
    this.usersApi = new UsersApi();
    if (!this.userId) {
      this.editMode = false;
      this.user = new UserModel();
    } else {
      this.loadUser(this.userId);
    }
  },
  methods: {
    loadUser: function(userId) {
      this.usersApi.getUser(userId, (user) => {
        this.user = user;
      });
    },

    clickCancel() {
      this.$router.push("/users/");
    },

    checkPassword(errors) {
      if (this.password !== this.password2) {
        errors.push({ severity: "error", content: "Passwords do not match" });
      }
    },

    clickSave() {
      if (!this.editMode) {
        this.dialogHeader = "Adding User";
      } else {
        this.dialogHeader = "Updating User";
      }
      console.log(JSON.stringify(this.user));
      const errors = validate(this.user, validations.user);
      this.checkPassword(errors);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000
          });
        });
        return;
      }
      this.displayProgressDialog = true;

      let callback = () => {
        this.displayProgressDialog = false;
        this.dialogHeader = null;
        this.$router.push("/users");
      };
      if (this.password != null) {
        this.user.password = this.password;
      }
      if (!this.editMode) {
        this.usersApi.addUser(this.user, callback);
      } else {
        this.usersApi.updateUser(this.user, callback);
      }
    }
  }
};
</script>

<style scoped>

</style>
