export class ChurchSignupModel {
  constructor() {
    this.name = null;
    this.address1 = null;
    this.address2 = null;
    this.city = null;
    this.state = null;
    this.zipcode = null;
    this.subscriptionType = null;
    this.adminUserFirstName = null;
    this.adminUserLastName = null;
    this.adminUserEmail = null;
    this.adminUserPassword = null;
    this.creditCardNumber = null;
    this.cvv2 = null;
    this.expirationDate = null;
    this.billingName = "Test Billing Name";
    this.billingAddress1 = null;
    this.billingAddress2 = null;
    this.billingCity = null;
    this.billingState = null;
    this.billingZipcode = null;
  }
}
