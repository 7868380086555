import {createRouter, createWebHistory} from "vue-router";
import ActivityAttendance from "@/components/activities/ActivityAttendance";
import EditActivityAttendance from "@/components/activities/EditActivityAttendance";
import ActivityList from "@/components/activities/ActivityList";
import GroupList from "@/components/groups/GroupList";
import Household from "@/components/households/Household";
import HouseholdList from "@/components/households/HouseholdList";
import Individual from "@/components/individuals/Individual";
import ManageIndividual from "@/components/individuals/ManageIndividual";
import IndividualAttendance from "@/components/individuals/IndividualAttendance";
import ManageGroup from "@/components/groups/ManageGroup";
import ManageHousehold from "@/components/households/ManageHousehold";
import ManageMinistry from "@/components/ministries/ManageMinistry";
import ManageActivity from "@/components/activities/ManageActivity";
import ManageLocation from "@/components/locations/ManageLocation";
import ManageService from "@/components/services/ManageService";
import ManageRoster from "@/components/rosters/ManageRoster";
import ManageRosterMembers from "@/components/rosters/ManageRosterMembers";
import IndividualSearch from "@/components/individuals/IndividualSearch";
import IndividualsList from "@/components/individuals/IndividualsList";
import LocationList from "@/components/locations/LocationList";
import Login from "@/components/Login";
import Logout from "@/components/Logout";
import MinistryList from "@/components/ministries/MinistryList";
import Roster from "@/components/rosters/Roster";
import RosterList from "@/components/rosters/RosterList";
import ServiceAttendance from "@/components/services/ServiceAttendance";
import EditServiceAttendance from "@/components/services/EditServiceAttendance";
import ServiceList from "@/components/services/ServiceList";
import GroupEmail from "@/components/groups/GroupEmail";
import ViewSentMessages from "@/components/groups/ViewSentMessages";
import SendRosterMessage from "@/components/rosters/SendRosterMessage";
import ServiceAttendanceReport from "@/components/reports/ServiceAttendanceReport";
import UsersList from "@/components/users/UsersList";
import Home from "./components/Home";
import {store} from "./store";
import {authenticated} from "@/functions";
import Signup from "@/components/signup/Signup";
import SignupSuccess from "@/components/signup/SignupSuccess";
import ManagePromotionOrder from "@/components/activities/ManagePromotionOrder";
import { Vue } from "@/main";
import ManageUser from "@/components/users/ManageUser";
import UpcomingEventsReport from "@/components/reports/UpcomingEventsReport";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    // Unauthenticated routes
    { path: "/", name: "home", component: Home, meta: { requiresAuth: false } },
    { path: "/login", name: "login", component: Login, meta: { requiresAuth: false } },
    { path: "/logout", name: "logout", component: Logout, meta: { requiresAuth: false } },
    { path: "/signup", name: "signup", component: Signup, meta: { requiresAuth: false } },
    { path: "/signupSuccess", name: "signupSuccess", component: SignupSuccess, meta: { requiresAuth: false } },

        // Authenticated routes
        {path: "/activities", name: "activities", component: ActivityList},
        {path: "/activity/edit", name: "editActivity", component: ManageActivity},
        {path: "/activity/add", name: "addActivity", component: ManageActivity},
        {path: "/activity/promotionChain", name: "managePromitionOrder", component: ManagePromotionOrder},
        {path: "/activityAttendance", name: "activityAttendance", component: ActivityAttendance},
        {path: "/editActivityAttendance", name: "editActivityAttendance", component: EditActivityAttendance},
        {path: "/editServiceAttendance", name: "editServiceAttendance", component: EditServiceAttendance},
        {path: "/groupEmail", name: "groupEmail", component: GroupEmail},
        {path: "/viewSentMessages", name: "viewSentMessages", component: ViewSentMessages},
        {path: "/groupList", name: "groupList", component: GroupList},
        {path: "/household", name: "household", component: Household},
        {path: "/household/edit", name: "editHousehold", component: ManageHousehold},
        {path: "/household/add", name: "addHousehold", component: ManageHousehold},
        {path: "/households", name: "households", component: HouseholdList},
        {path: "/individual", name: "individual", component: Individual},
        {path: "/individual/edit", name: "editIndividual", component: ManageIndividual},
        {path: "/individual/attendance", name: "individualAttendance", component: IndividualAttendance},
        {path: "/individual/add", name: "addIndividual", component: ManageIndividual},
        {path: "/individuals", name: "individuals", component: IndividualsList},
        {path: "/individuals/search", name: "individualsSearch", component: IndividualSearch},
        {path: "/location/edit", name: "editLocation", component: ManageLocation},
        {path: "/location/add", name: "addLocation", component: ManageLocation},
        {path: "/locations", name: "locations", component: LocationList},
        {path: "/ministries", name: "ministries", component: MinistryList},
        {path: "/ministry/edit", name: "editMinistry", component: ManageMinistry},
        {path: "/ministry/add", name: "addMinistry", component: ManageMinistry},
        {path: "/group/add", name: "addGroup", component: ManageGroup},
        {path: "/group/edit", name: "editGroup", component: ManageGroup},
        {path: "/reports/events", name: "upcomingEventsReport", component: UpcomingEventsReport},
        {path: "/reports/services", name: "serviceAttendanceReport", component: ServiceAttendanceReport},
        {path: "/roster", name: "roster", component: Roster},
        {path: "/roster/add", name: "addRoster", component: ManageRoster},
        {path: "/roster/edit", name: "editRoster", component: ManageRoster},
        {path: "/rostermembers/manage", name: "manageRosterMembers", component: ManageRosterMembers},
        {path: "/sendrostermessage", name: "sendRosterMessage", component: SendRosterMessage},

        //{ path: "/rostermembers/edit", name: "editRoster", component: ManageRoster },
        {path: "/roster/view", name: "viewRoster", component: Roster},
        {path: "/rosters", name: "rosters", component: RosterList},
        {path: "/rosters/view", name: "viewRosters", component: RosterList},
        {path: "/service/edit", name: "editService", component: ManageService},
        {path: "/service/add", name: "addService", component: ManageService},
        {path: "/serviceAttendance", name: "serviceAttendance", component: ServiceAttendance},
        {path: "/services", name: "services", component: ServiceList},
        {path: "/users", name: "users", component: UsersList},
        {path: "/users/add", name: "userAdd", component: ManageUser},
        {path: "/users/edit", name: "userEdit", component: ManageUser},

        // Catch all
        {path: "/:catchAll(.*)", redirect: "/"},
    ],
});

router.beforeEach((to, from, next) => {
    console.log("To:" + JSON.stringify(to));

    if (to.meta && to.meta.requiresAuth === false) {
        next();
    } else {
        if (authenticated()) {
            store.commit("nextRoute", null);
            next();
        } else {
            console.log("Secured route. Need auth:" + JSON.stringify(to));
            store.commit("nextRoute", to.fullPath);
            Vue.$router.push("/login");
            next(false);
        }
    }
});

export default router;
