<template>
  <Card style="padding: 1%; text-align: left">
    <template #title> Phone Numbers </template>
    <template #content>
      <ul v-if="value.length !== 0">
        <li v-for="item in value" :key="item.id">
          {{ primary(item) }} &nbsp;
          {{ secondary(item) }}
          <i v-if="preferred(item)" class="fas fa-star" />
        </li>
      </ul>
      <div v-if="value.length === 0" style="padding: 2%">No Phone Numbers Listed</div>
    </template>
  </Card>
</template>

<script>
export default {
  name: "PhoneNumberList",
  props: ["primary", "secondary", "preferred", "value"],
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.p-card /deep/ .p-card-body {
  padding-bottom: 1px;
}

.p-card /deep/ .p-card-content {
  padding-bottom: 1rem;
  padding-top: 0px;
}
</style>
