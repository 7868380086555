<template>
  <span>
    <div v-if="groupOptions" class="grid p-fluid">
      <div class="col-4" style="padding: 0.5em">
        <custom-label v-bind:label="'Group'" v-bind:required="true" />
        <Dropdown v-model="groupId" :options="groupOptions" optionLabel="name" optionValue="code" placeholder="Select a Group" />
      </div>
      <div class="col-12" style="">
          Send message as:
          <RadioButton name="type" value="email" v-model="groupMessage.type" /> Email
          <RadioButton name="type" value="sms" v-model="groupMessage.type" /> Text
      </div>
      <div class="col-12" v-if="groupMessage.type === 'email'" style="padding: 0.5em">
        <custom-label v-bind:label="'Subject'" v-bind:required="true" />
        <InputText id="name" v-model="groupMessage.subject" type="text" />
      </div>
      <div class="col-12" style="padding: 0.5em">
        <Editor v-model="groupMessage.body" editorStyle="height: 320px" />
      </div>
      <div class="col-12" style="padding: 0.5em">
        <div class="grid p-fluid">
          <div class="col-8" style="">
            Send message as:
            <RadioButton name="type" value="email" v-model="groupMessage.type" /> Email
            <RadioButton name="type" value="sms" v-model="groupMessage.type" /> Text
          </div>
          <div class="col-4" style="">
             <Button icon="pi pi-envelope" label="Send Message" style="padding: 0.5em" @click="clickSendMessage" />
          </div>
        </div>
      </div>
      <div class="col-12" style="padding: 0.5em">
        <div class="grid p-fluid">
          <div class="col-4" style="">
            <custom-label v-bind:label="'Send Test Email to:'" v-bind:required="false" />
            <InputText id="testEmail" v-model="groupMessage.testEmail" type="text" />
          </div>
          <div class="col-3" style="display: flex">
            <Button icon="fas fa-envelope" label="Send Test Email" style="padding: 0.5em" @click="sendTestEmail" />
          </div>
          <div class="col-5"></div>
          <div class="col-4" style="">
            <custom-label v-bind:label="'Send Test Text to:'" v-bind:required="false" />
            <InputText id="testPhoneNumber" v-model="groupMessage.testPhoneNumber" type="text" />
          </div>
          <div class="col-3" style="display: flex">
            <Button icon="fas fa-envelope" label="Send Test Text" style="padding: 0.5em" @click="sendTestText" />
          </div>
          <div class="col-3" style="display: flex"></div>
          <div class="col-2" style="display: flex; vertical-align: top">
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <ProgressSpinner />
    </div>

    <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
    <Toast />
  </span>
</template>

<script>
import GroupsApi from "@/apis/GroupsApi";
import {GroupModel} from "@/models/GroupModel";
import {GroupMessageModel} from "@/models/GroupMessageModel"; //import ConfirmationDialog from "@/components/general/ConfirmationDialog";
import {responseToOptions} from "@/functions";
import {validate, validations} from "@/validation";

export default {
  data() {
    return {
      groups: null,
      group: new GroupModel(),
      groupMessage: new GroupMessageModel(),
      groupId: null,
      groupOptions: null,
      subject: null,
      body: null,
      displayProgressDialog: false,
      dialogHeader: null,
    };
  },
  created() {
    this.groupsApi = new GroupsApi();
    if (this.$route.query.groupId) {
      this.groupId = parseInt(this.$route.query.groupId);
    }
  },
  mounted() {
    this.getGroups();
  },

  methods: {
    getGroups() {
      this.groupsApi.getGroups((data) => {
        this.groupOptions = responseToOptions(data);
      });
    },
    clickSendMessage() {
      this.groupMessage.groupId = this.groupId;
      if (this.groupMessage.type == "email") {

      const errors = validate(this.groupMessage, validations.groupEmailMessage);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }

      } else {
        const errors = validate(this.groupMessage, validations.groupTextMessage);
        if (errors) {
          errors.forEach((element) => {
            this.$toast.add({
              severity: element.severity,
              summary: element.content,
              life: 3000,
            });
          });
          return;
        }
      }
      this.sendMessage();
    },

    sendTestText() {
      this.groupMessage.type = "sms"
      this.groupMessage.recipId = this.groupId
      const errors = validate(this.groupMessage, validations.testText);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.sendTest()
    },
    sendTestEmail () {
      this.groupMessage.type = "email"
      this.groupMessage.recipId = this.groupId
      const errors = validate(this.groupMessage, validations.testEmail);
      if (errors) {
        errors.forEach((element) => {
          this.$toast.add({
            severity: element.severity,
            summary: element.content,
            life: 3000,
          });
        });
        return;
      }
      this.sendTest()
    },
    sendMessage() {
        this.dialogHeader = "Sending Email";
        this.displayProgressDialog = true;
        this.groupMessage.recipId = this.groupId;
        this.groupsApi.sendGroupMessage(this.groupMessage, () => {
        this.groupMessage = new GroupMessageModel();
        this.$toast.add({
            severity: "success",
            summary: "Message Successfully Sent",
            life: 3000,
        });
        this.displayProgressDialog = false;
        this.dialogHeader = null;
        let url = "viewSentMessages?groupId="+this.groupId
        this.$router.push(url)
        });
    },

    sendTest() {
      this.dialogHeader = "Sending Test Message";
      this.displayProgressDialog = true;
      this.groupsApi.sendTestMessage(this.groupMessage, () => {
        
        this.$toast.add({
          severity: "success",
          summary: "Test Message Successfully Sent",
          life: 3000,
        });
        this.groupMessage.testEmail = null
        this.groupMessage.testPhoneNumber = null
        this.displayProgressDialog = false;
        this.dialogHeader = null;
      });
    },
  },
};
</script>

<style scoped></style>
