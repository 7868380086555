<template>
  <span>
    {{ formatPhone() }}
  </span>
</template>

<script>
export default {
  name: "IndividualPhoneDisplay",
  props: ["value"],
  methods: {
    formatPhone: function () {
      let obj = this.value.individual ? this.value.individual : this.value;
      if (obj) {
        let numbers = obj.phoneNumbers;
        if (numbers.length != 0) {
          let number = numbers.find((n) => n.preferred === true).number;
          //this.activityOptions = this.ministryInfo.find((n) => n.id == this.ministryId).children;
          console.log(number);
          return number;
        }
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped></style>
