import { request } from "@/functions";

export default class ActivitiesApi {
  getActivitiesList(ministryId, successCallback) {
    request("get", "/ministries/" + ministryId + "/activities", null, successCallback);
  }

  getActivity(id, successCallback) {
    request("get", "/activities/" + id, null, successCallback);
  }

  addActivity(activity, successCallback) {
    request("POST", "/activities/", activity, successCallback);
  }

  updateActivity(activity, successCallback) {
    request("PUT", "/activities/" + activity.id, activity, successCallback);
  }

  deleteActivity(id, successCallback) {
    request("DELETE", "/activities/" + id, null, successCallback);
  }

  getRosters(id, successCallback) {
    request("GET", "/activities/" + id + "/rosters", null, successCallback );
  }

  getPromotionChain(id, successCallback) {
    request("GET", "/activities/" + id + "/rosters/chain", null, successCallback );
  }

  promoteRosters(id, successCallback) {
    request("POST", "/activities/" + id + "/rosters/promote", null, successCallback);
  }
}
