<template>
  <div>
    <ProgressSpinner v-if="!roster" />
    <span v-if="roster">
      <Card style="width1: 40rem; margin-bottom: 2em">
        <template #title> Manage {{ roster.name }} Members</template>
        <template #content>
          <PickList v-model="picklistValues">
            <template #sourceheader>
              <div class="p-fluid  p-inputgroup" style="text-align: right">
                <InputText v-model="searchText" placeholder="People Search" @keyup.enter="sendSearch" />
                <Button class="p-inputgroup-addon" icon="fas fa-search" type="submit" @click="sendSearch()" />
              </div>
            </template>
            <template #targetheader>
              <div v-once>{{ roster.name }}</div>
            </template>

            <template #item="slotProps">
              <div>
                <div>
                  <span>
                    <IndividualNameDisplay :value="slotProps.item" />
                  </span>
                </div>
              </div>
            </template>
          </PickList>
          <p></p>
        </template>
        <template #footer>
          <Button class="p-button-text" icon="fas fa-times" label="Cancel" @click="clickCancel" />
          <Button autofocus icon="fas fa-check" label="Save" @click="clickSave(roster)" />
        </template>
      </Card>
      <Toast />

      <progress-dialog v-bind:display="displayProgressDialog" v-bind:header="dialogHeader" />
    </span>
  </div>
</template>

<script>
import RostersApi from "@/apis/RostersApi";
import { request } from "@/functions";

export default {
  name: "ManageRosterMembers",
  data() {
    return {
      searchText: null,
      roster: null,
      rosterMembers: null,
      action: null,
      rosterId: null,
      rosterApi: null,
      individualApi: null,
      dialogHeader: null,
      picklistValues: [[], []],
      displayProgressDialog: false,
      editMode: true,
      searchString: null,
      newRosterMembers: null,
    };
  },
  created() {
    this.rosterApi = new RostersApi();
    //this.individualApi = new IndividualsApi();
    this.rosterId = this.$route.query.rosterId;
    // if (!this.groupId) {
    //   this.editMode = false;
    //   //this.roster = new RosterModel();
    // } else {
    this.loadRoster(this.rosterId);
    // }
  },
  methods: {
    loadRoster: function (rosterId) {
      this.rosterApi.getRoster(rosterId, (roster) => {
        this.roster = roster;
      });
      this.rosterApi.getRosterMembers(rosterId, (rosterMembers) => {
        this.rosterMembers = rosterMembers;
        this.picklistValues = [[], this.rosterMembers];
      });
    },

    clickCancel: function () {
      this.$router.push(
        "/roster/view?rosterId=" +
          this.rosterId +
          "&ministryId=" +
          this.roster.activity.ministryId +
          "&activityId=" +
          this.roster.activity.id
      );
    },
    clickSave: function (roster) {
      this.dialogHeader = "Updating Roster";
      this.displayProgressDialog = true;
      this.newRosterMembers = this.picklistValues[1].map((m) => m.individualId);
      //const errors = validate(this.roster, validations.roster);
      // if (errors) {
      //   errors.forEach((element) => {
      //     this.$toast.add({
      //       severity: element.severity,
      //       summary: element.content,
      //       life: 3000,
      //     });
      //   });
      //   return;
      // }

      // if (!this.editMode) {
      //   this.groupApi.addGroup(group, (data) => {
      //     this.group = new GroupModel();
      //     this.displayProgressDialog = false;
      //     this.dialogHeader = null;
      //     this.$router.push("/groupList");
      //   });
      // this.groupApi.addGroup(
      //   {
      //     name: group.name,
      //     members: group.members.map((it) => it.individualId),
      //     private: group.private
      //   },
      //   (data) => {
      //     this.group = new GroupModel();
      //     this.displayProgressDialog = false;
      //     this.dialogHeader = null;
      //     this.$router.push("/groupList");
      //   }
      // );
      // } else {
      this.rosterApi.updateRosterMembers(this.rosterId, this.picklistValues[1], () => {
        this.displayProgressDialog = false;
        this.dialogHeader = null;
        this.$router.push(
          "/roster/view?rosterId=" +
            this.rosterId +
            "&ministryId=" +
            this.roster.activity.ministryId +
            "&activityId=" +
            this.roster.activity.id
        );
      });
      // }
    },
    keyPressed: function (e) {
      if (e.key === "Enter") {
        this.sendSearch();
      }
    },
    updateAvailable: function (list) {

      this.picklistValues = [list, this.picklistValues[1]];
    },
    sendSearch: function () {
      let s = this.searchText;
      let searchString;
      if (this.searchText) {
        let searchArray = this.searchText.split(" ");
        if (searchArray.length === 1) {
          searchString = "firstName=" + this.searchText + "&lastName=" + this.searchText + "&match=any";
        } else {
          searchString = "firstName=" + searchArray[0] + "&lastName=" + searchArray[1];
        }
        this.searchText = "";
      }
      request("get", "/individuals?" + searchString, null, (data) => {
        let newAvailable = [];
        let available = data.map((i) => {

          let exists = this.picklistValues[1].find((element) => {
            if (element.individual.id === i.id) {
              return true;
            }
          });
          if (!exists) {
            let newIndividual = {
              individual: i,
              participant: true,
              active: true,
            };
            newAvailable.push(newIndividual)

          }
        });
        this.updateAvailable(newAvailable);
        //this.localRoster = [available, this.localRoster[1]];
      });
    },
    // sendSearch() {
    //   this.individualApi.getIndividuals(this.searchString, (data) => {
    //     this.updateAvailable(data);
    //   });
    // },
    moveIndividual(event) {
      event.items.forEach((element) => {
        element.individualId = element.id;
        delete element.id;
      });
    },
    moveGroupMember(event) {
      event.items.forEach((element) => {
        element.id = element.individualId;
        delete element.individualId;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.p-picklist-buttons {
  p-picklist-source-controls {
    display: none;
  }

  .p-picklist-target-controls {
    display: none;
  }
}
</style>
